<template>
  <div class="help-overlay__container" ref="container">
    <transition name="fade">
      <div
          class="help-overlay__modal"
          :style="{top: currentStepTop, left: currentStepLeft}"
          v-if="isVisible"
      >
        <bubble :key="step" v-if="currentStep">
          <template #header>
            <h2>{{ currentStep.header }}</h2>
            <div class="pagination">{{ pagination }}</div>
          </template>
          <template #body>{{ currentStep.body }}</template>
          <template #footer>
            <c-button v-if="currentStep.footer !== '{arrow_right}'" @click="nextStep()">{{currentStep.footer}}</c-button>
            <c-next-button v-else :post="false" @click="nextStep()"></c-next-button>
          </template>
        </bubble>
      </div>
    </transition>
  </div>
</template>

<script>
import Bubble from "@/components/Base/Bubble";
import CButton from "@/components/Base/Button";
import {mapGetters, mapMutations} from "vuex";
import CNextButton from "@/components/Exercise/Components/NextButton.vue";

// TODO z-index abomination
// TODO figure out better system

export default {
  name: "HelpOverlay",
  components: {CNextButton, CButton, Bubble},
  mounted() {
    this.backgroundify()

    if (this.shouldClose()) {
      this.close()
    }

    document.addEventListener('click', (event) => {
      const container = document.querySelector('.faded')
      const button = document.querySelector('.help-overlay__button__image')

      if (!event.composedPath().includes(container) && !event.composedPath().includes(button)) {
        this.close()
      }
    })
  },
  methods: {
    ...mapMutations({
      SET_HELP_OVERLAY_VISIBILITY: "helpOverlay/SET_HELP_OVERLAY_VISIBILITY",
      SET_STEP: "helpOverlay/SET_STEP",
      SET_TARGET: "helpOverlay/SET_TARGET",
    }),
    nextStep() {
      this.backgroundify()

      this.SET_STEP(this.step + 1)

      if (!this.shouldClose()) {
        if (this.currentStep.allow && !this.currentStep.allow()) {
          return this.nextStep()
        }

        this.foregroundify()
      }
    },
    shouldClose() {
      if (this.currentStep === null) {
        this.close()
        return true
      }

      return false;
    },
    backgroundify() {
      this.currentTutorial(this.$route.name)?.steps.forEach((e) => {
        const target = document.querySelectorAll(e?.target)
        target.forEach((e) => {
          e.classList.remove('help-overlay__active-item')
        })
      })
    },
    foregroundify() {
      let target = []
      if (this.currentStep?.target?.[0] === "#") {
        const idTarget = document.getElementById(this.currentStep?.target.substring(1));
        if (idTarget) {
          target[0] = idTarget
        }
      } else {
        target = document.querySelectorAll(this.currentStep?.target)
      }

      target.forEach((e) => {
        e.classList.add('help-overlay__active-item')
      })
    },
    close(setValue = true) {
      if (setValue) {
        this.SET_HELP_OVERLAY_VISIBILITY(false)
      }
      this.backgroundify()
      this.SET_STEP(0)
      this.SET_TARGET(0)
    },
  },
  computed: {
    ...mapGetters({
      isInitializing: "isInitializing",
      currentTutorial: "helpOverlay/currentTutorial",
      currentStepGetter: "helpOverlay/currentStep",
      pagination: "helpOverlay/pagination",
    }),
    currentStep() {
      return this.currentStepGetter(this.$route.name)
    },
    currentStepLeft() {
      return this.currentStepGetter(this.$route.name).left
    },
    currentStepTop() {
      return this.currentStepGetter(this.$route.name).top
    },
    isVisible() {
      return this.$store.state.helpOverlay.isVisible // undefined if directly from mapState
    },
    step() {
      return this.$store.state.helpOverlay.step // undefined if directly from mapState
    },
    target() {
      return this.$store.state.helpOverlay.target // undefined if directly from mapState
    },
  },
  watch: {
    $route() {
      this.close()
    },
    isVisible(newValue) {
      if (!newValue) {
        this.close(false)
        this.backgroundify()
      } else {
        this.foregroundify()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.help-overlay__container {
  max-height: 968px;
  height: calc(100vh - 80px);
  aspect-ratio: 180/121;


  //width: 1440px;
  //height: 900px;
  z-index: 105;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  pointer-events: none;
  border: 1px red;
  overflow: hidden;

  * {
    pointer-events: all;
  }


  .help-overlay__modal {
    min-width: 400px;
    user-select: none;
    z-index: 104;
    transition: all .5s;
    position: absolute;
    transform: translate(-50%, 0);
    width: fit-content;
    height: fit-content;
    transform-origin: top;

    :deep(.bubble_body) {
      white-space: normal;
    }
  }
}

.pagination {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>

<!--suppress CssUnusedSymbol -->
<style lang="scss">
.help-overlay__active-item {
  z-index: 100 !important;
  pointer-events: none;
  transition: all;
}
</style>