import { Model } from "@/models/Model";
import Exercise from "@/models/exercises/Exercise";

export default class Step extends Model {
  constructor({
    anchor_word,
    can_start_test,
    description,
    dl,
    highest_difficulty_completed,
    hint,
    id,
    is_repeatable,
    label,
    media_1,
    media_2,
    media_3,
    media_4,
    notes,
    phase_id,
    position,
    required_step_id,
    status,
    status_label,
    test_complete,
    test_id,
    test_required,
    tip,
    exercises,
    anchors,
    words,
  }) {
    super();
    this._anchor_word = anchor_word;
    this._can_start_test = can_start_test;
    this._description = description;
    this._dl = dl;
    this._highest_difficulty_completed = highest_difficulty_completed;
    this._hint = hint;
    this._id = id;
    this._is_repeatable = is_repeatable;
    this._label = label;
    this._media_1 = media_1;
    this._media_2 = media_2;
    this._media_3 = media_3;
    this._media_4 = media_4;
    this._notes = notes;
    this._phase_id = phase_id;
    this._position = position;
    this._required_step_id = required_step_id;
    this._status = status;
    this._status_label = status_label;
    this._test_complete = test_complete;
    this._test_id = test_id;
    this._test_required = test_required;
    this._tip = tip;
    this._exercises = exercises
      ? exercises.map((exercise) => new Exercise(exercise))
      : [];
    this._anchors = anchors;
    this._words = words;
  }

  static factory(data) {
    if (data instanceof Array) {
      return data.map((world) => {
        return world.map((step) => new this(step));
      });
    }

    return new this(data);
  }

  get exercises() {
    return this._exercises;
  }

  get anchor_word() {
    return this._anchor_word;
  }

  get can_start_test() {
    return this._can_start_test;
  }

  get description() {
    return this._description;
  }

  get dl() {
    return this._dl;
  }

  get highest_difficulty_completed() {
    return this._highest_difficulty_completed;
  }

  get hint() {
    return this._hint;
  }

  get id() {
    return this._id;
  }

  get is_repeatable() {
    return this._is_repeatable;
  }

  get label() {
    return this._label;
  }

  get media_1() {
    return this._media_1;
  }

  get media_2() {
    return this._media_2;
  }

  get media_3() {
    return this._media_3;
  }

  get media_4() {
    return this._media_4;
  }

  get notes() {
    return this._notes;
  }

  get phase_id() {
    return this._phase_id;
  }

  get position() {
    return this._position;
  }

  get required_step_id() {
    return this._required_step_id;
  }

  get status() {
    return this._status;
  }

  get status_label() {
    return this._status_label;
  }

  get test_complete() {
    return this._test_complete;
  }

  get test_id() {
    return this._test_id;
  }

  get test_required() {
    return this._test_required;
  }

  get tip() {
    return this._tip;
  }

  get anchors() {
    return this._anchors;
  }

  get words() {
    return this._words;
  }
}
