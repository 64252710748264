<template>
  <c-content-modal
    class="debug-report-modal"
    v-model="visibility"
    @close="close()"
  >
    <template #header>
      <h2>Fout rapporteren</h2>
    </template>
    <template #body>
      <v-form
        ref="form"
        class="bug-report-form"
        @submit.prevent="onSubmit($event)"
      >
        <div class="category-dropdown-wrapper">
          <p class="form-label">Wat voor fout is het?</p>
          <v-select
            :items="categories"
            v-model="userReport.category"
            :rules="[(v) => !!v || 'Selecteer een categorie']"
            required
            rounded
            menu-props="rounded"
            background-color="#fff"
            outlined
          />
        </div>
        <div class="title-input-wrapper">
          <p class="form-label">Geef een titel</p>

          <v-text-field
            v-model="userReport.title"
            type="text"
            :rules="[(v) => !!v || 'Voer een korte titel in']"
            required
            rounded
            background-color="#fff"
            outlined
          ></v-text-field>
        </div>
        <div class="description-input-wrapper">
          <p class="form-label">Beschrijf het probleem</p>

          <v-textarea
            v-model="userReport.description"
            :rules="[(v) => !!v || 'Voer een beschrijving in']"
            required
            rounded
            rows="15"
            no-resize
            background-color="#fff"
            outlined
            type="text"
          />
        </div>

        <div class="form-submit-button-wrapper">
          <c-button :disabled="disabled" type="submit">Verzenden</c-button>
        </div>
      </v-form>
    </template>
  </c-content-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CContentModal from "@/components/Modals/ContentModal.vue";
import CButton from "@/components/Base/Button.vue";
import { UserReport } from "@/models/debug/UserReport";

export default {
  name: "CBugReportModal",
  components: { CButton, CContentModal },
  data: () => ({
    bugCategories: {},
    categories: [
      {
        text: "Fout met een woord",
        value: "bug_word",
      },
      {
        text: "Fout met een ankerwoord",
        value: "bug_anchor",
      },
      {
        text: "Fout met een oefening",
        value: "bug_exercise",
      },
      {
        text: "Andere soort fout",
        value: "bug_other",
      },
    ],
    userReport: {
      category: null,
      title: null,
      description: null,
    },
  }),
  methods: {
    ...mapActions({
      sendUserReport: "debug/sendUserReport",
      addNotification: "notification/add",
    }),
    ...mapMutations({
      close: "debug/HIDE_DEBUG_REPORT_MODAL",
    }),
    resetInput() {
      this.userReport.category = null;
      this.userReport.title = null;
      this.userReport.description = null;
    },
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.sendUserReport(
          new UserReport({
            category: this.userReport.category,
            title: this.userReport.title,
            description: this.userReport.description,
          })
        ).then(() => {
          let notification = {
            type: "success",
            message: "Rapport is verzonden!",
          };
          this.addNotification(notification)
          this.close()
          this.resetInput();
        })
      }
    },
  },
  computed: {
    ...mapGetters({
      visibility: "debug/debugReportModalVisibility",
    }),
    disabled() {
      return Object.values(this.userReport).some((e) => !e || e === '');
    },
  },
};
</script>

<style scoped lang="scss">
.bug-report-form {
  width: 100%;

  .form-submit-button-wrapper {
    padding: 0 0 10px;
  }
}

.debug-report-modal {
  h2 {
    font-size: 30px;
    line-height: 30px;
  }
  p, textarea {
    font-size: 24px;
    line-height: 24px;
  }
}

:deep(.content-modal) {
  --height: 930px;
  --width: 700px;
  width: var(--width);
  min-width: var(--width);
  max-width: var(--width);

  height: var(--height);
  //min-height: var(--height);
  max-height: 80%;

  padding: 24px 48px;

  .content-modal-body-wrapper {
    padding: 50px 0 0;
  }

  .content-modal-body {
    padding: 0 5px;
  }
}

:deep(.v-input) {
  width: 100%;

  &.v-textarea {
    textarea {
      line-height: 27px;
    }

    max-height: 450px;
  }

  .v-select__selections {
    height: 100%;
  }

  .v-select__selection {
    max-width: 100%;
  }

  .v-text-field .v-input__slot, &.v-text-field--rounded {
    border-radius: 25px !important;
  }

  .v-input__append-inner {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 !important;
  }
}
</style>
