import { Model } from "@/models/Model";
import Cookies from "js-cookie";
import store from "@/store/index";

export class UserReport extends Model {
  constructor({ category, title, description }) {
    super();
    this._category = category;
    this._title = title;
    this._description = description;
    this._data = this.generateData();
  }

  generateData = () => ({
    sent_at: Date.now(),
    url: window.location.href,
    errors: store.getters["debug/getLastFiveErrors"],
    system: {
      resolution: {
        height: window.screen.height,
        width: window.screen.width,
      },
      userAgent: navigator.userAgent,
    },
    application: {
      username: Cookies.get("user"),
      world_id: store.getters["world/getActiveWorld"],
      exercise_id: store.getters["exercise/getExercise"]?.id,
      current_word: store.getters["exercise/getWordIndex"],
      word: store.getters["exercise/getWord"],
      step_id: store.getters["lessonPage/getStep"]?.id,
      difficulty: store.getters["lessonPage/getDifficulty"],
      expected_input: store.getters["debug/getExpectedInput"],
      current_input: store.getters["debug/getCurrentInput"],
    },
  });

  get category() {
    return this._category;
  }

  set category(value) {
    this._category = value;
  }

  get title() {
    return this._title;
  }

  set title(value) {
    this._title = value;
  }

  get description() {
    return this._description;
  }

  set description(value) {
    this._description = value;
  }

  get data() {
    return JSON.stringify(this._data);
  }

  get raw_data() {
    return this._data;
  }
}
