<template>
<!--  <transition name="fade" mode="out-in" >-->
    <h4 :key="filteredSentence" class="exercise__filtered-sentence" v-if="filteredSentence && hasParameter('sentence')">{{ filteredSentence }}</h4>
<!--  </transition>-->
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'FilteredSentence',
  computed: {
    ...mapGetters({
      filteredSentence: "exercise/getFilteredSentence",
      hasParameter: "template/hasParameter",
    })
  }
}
</script>
