<template>
  <div class="content">
    <div class="content-wrapper">
      <TreehouseRepetitionModal :shown="showRepetition" @close="modal.repetition = false"/>

      <WordProgressModal :shown="showWords" :progress-words="progressWords" @close="modal.words = false"/>

      <ProgressModal :shown="showProgress" @close="modal.progress = false"/>

      <div class="item-container">
        <img
            :src='"https://cdn.spellit.nl/buddies/" + profile.student.buddy_id + "/bag.png"'
            class="treehouse-buddy"
            alt="buddy"
        />

        <img
            src="../assets/treehouse-stats.png"
            class="treehouse-stats"
            @click="modal.progress = true"
            alt="stats"
        />

        <img
            src="../assets/jungle-board.png"
            class="treehouse-board"
            @click="$router.push({ name: 'world_overview' })"
            alt="jungle"
        />

        <img src="../assets/logboek.png" class="treehouse-book" @click="$router.push({ name: 'logboek' })" alt="book"/>

        <img
            src="../assets/treehouse-badge.png"
            class="treehouse-badge"
            alt="badge"
            @click="$router.push('/mijn-badges')"
        />

        <img
            src="../assets/naslag.png"
            class="treehouse-naslag"
            @click="$router.push({ name: 'boekenplank' })"
            alt="book-shelf"
        />

        <img src="../assets/broom.png" class="treehouse-broom" @click="modal.repetition = true" alt="broom"/>

        <kiko-flying class="kiko-flying" />

      <help-overlay-button class="help"/>

      </div>
      <div class="afval">
        <img
            v-if="repeatableLength > 0 && repeatableLength <= 3"
            class="rep-leaves"
            src="../assets/bladeren_low.png"
            alt="leaves-low"
        />
        <img
            v-if="repeatableLength >= 4 && repeatableLength <= 5"
            class="rep-leaves"
            src="../assets/bladeren_medium.png"
            alt="leaves-medium"
        />
        <img v-if="repeatableLength >= 6" class="rep-leaves" src="../assets/bladeren_high.png" alt="leaves-high"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProgressModal from "@/components/Treehouse/ProgressModal";
import KikoFlying from "@/components/Animations/KikoFlying";
import HelpOverlayButton from "@/components/Base/HelpOverlayButton";
import TreehouseRepetitionModal from "@/components/Treehouse/TreehouseRepetitionModal";
import WordProgressModal from "@/components/Treehouse/WordProgressModal";

export default {
  name: "TreeHouse",
  components: {
    WordProgressModal,
    TreehouseRepetitionModal, HelpOverlayButton, KikoFlying, ProgressModal
  },
  data: () => ({
    modal: {
      repetition: false,
      progress: false,
      words: false
    },
    progressWords: [],
  }),
  computed: {
    ...mapGetters({
      profile: "user/getProfile",
      progress: "user/getProgress",
      repeatables: "exercise/getRepeatables",
    }),
    repeatableLength() {
      // reactivity problems??
      return this.repeatables.filter(f => f.status !== 4).reduce((acc) => acc + 1, 0);
    },
    showRepetition() {
      return this.modal.repetition;
    },
    showProgress() {
      return this.modal.progress;
    },
    showWords() {
      return this.modal.words;
    }
  },
}
</script>

<style scoped lang="scss">
.content {
  top: 80px;
  width: 100vw;
  height: calc(100vh - 80px);
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;

  .content-wrapper {
    background: url("../assets/boomhut.jpg") no-repeat;
    background-size: contain;
    width: 100vw;
    height: 62.5vw;
    max-width: calc(160vh - 128px); // 80px (topmenu) * the ratio (1.6 or 16/10)
    max-height: calc(100vh - 80px);
    margin: 0 auto;
    position: absolute;
    top: 0;
  }
}

.kiko-flying {
  position: absolute;
  top: 18%;
  left: 15%;
  width: 15%;
  display: flex;
}

.treehouse-stats {
  position: absolute;
  left: -2%;
  height: auto;
  top: 20px;
  width: 15%;
  cursor: pointer;

  &:hover {
    animation: book 1s;
    transform-origin: top center;
  }
}

.voortgang-bar {
  border-radius: 50px;
}

.treehouse-board {
  position: absolute;
  top: 3%;
  left: 23%;
  height: auto;
  width: 14%;
  cursor: pointer;

  &:hover {
    animation: swing 1s;
    transform-origin: top center;
  }
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes swing-inverse {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.treehouse-book {
  position: absolute;
  left: 40%;
  width: 13%;
  top: 62%;
  cursor: pointer;
}

.treehouse-book:hover {
  animation: book 1s;
}

@keyframes book {
  50% {
    transform: scale(1.1);
  }
}

.treehouse-badge {
  position: absolute;
  left: 87.5%;
  width: 12.5%;
  top: 41%;
  cursor: pointer;

  &:hover {
    animation: badge 1s;
  }
}



@keyframes badge {
  50% {
    transform: rotate(5deg);
  }
}

.treehouse-naslag {
  position: absolute;
  width: 9%;
  left: 56%;
  top: 59%;
  cursor: pointer;

  &:hover {
    animation: naslag 1s;
  }
}

@keyframes naslag {
  50% {
    transform: scale(1.2);
  }
}

.treehouse-buddy {
  height: auto;
  width: 28%;
  position: absolute;
  top: 35%;
  left: 1%;
}

.treehouse-broom {
  height: 62%;
  position: absolute;
  right: 17.4%;
  top: 17.4%;
  cursor: pointer;
}

.treehouse-broom:hover {
  /* animation: bounce .5s; 
  -webkit-animation: bounce .5s; 
  -moz-animation: bounce .5s; 
  -o-animation: bounce .5s;  */
  animation: broom 1.5s;
  animation-iteration-count: infinite;
  transform-origin: top center;
}

@-webkit-keyframes broom {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(3deg);
  }
  0% {
    -webkit-transform: rotate(0deg);
  }
}

@-moz-keyframes broom {
  0% {
    -moz-transform: rotate(0deg);
  }
  50% {
    -moz-transform: rotate(3deg);
  }
  0% {
    -moz-transform: rotate(0deg);
  }
}

@-o-keyframes broom {
  0% {
    -o-transform: rotate(0deg);
  }
  50% {
    -o-transform: rotate(3deg);
  }
  0% {
    -o-transform: rotate(0deg);
  }
}

@keyframes broom {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(3deg);
  }
  0% {
    transform: rotate(0deg);
  }
}

.rep-leaves {
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
}

p,
.v-data-table {
  margin-top: 20px;
  font-family: "Quicksand", serif !important;
  font-weight: bold;
  color: #70706F !important;
}

th {
  color: #016D47 !important;
}

th, td {
  font-size: 16px !important;
}

tr:nth-child(odd) {
  background: #F8F4E0;
}

tr {
  height: 70px;
}

.herhaling-table td:nth-child(1),
.herhaling-table td:nth-child(2) {
  width: 90px;
  color: #026C49;
  font-weight: bold;
  font-size: 18px !important;
}

.herhaling-table td:nth-child(3) {
  font-size: 18px !important;
}

td:last-child {
  text-align: right;
}

select {
  border: 2px solid #53B045;
  color: #53B045;
  border-radius: 5px;
  padding: 5px 25px;
  width: 100%;
  appearance: auto;
}

.help {
}
</style>