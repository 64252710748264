export class Helper {
  static getPng(name, path = "") {
    if (name) {
      let images = require.context("@/assets/", true, /\.png$/);
      return images("./img/" + path + name);
    }

    return null;
  }

  //
  // static objectMap(object, callback) {
  //   return Object.keys(object).map(callback);
  // }

  static addPrototypes() {
    String.prototype.forEachChar = function (callback) {
      [...String(this)].forEach(callback);
    };

    String.prototype.canBeLongVowel = function () {
      if (this.length > 1) return false;
      return ["a", "e", "o", "u"].indexOf(this.toLowerCase()) !== -1;
    };

    String.randomChar = function () {
      const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      return alphabet[Math.floor(Math.random() * alphabet.length)];
    };

    Math.randomMinMax = function (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    Array.prototype.shuffle = function () {
      var i = this.length,
        j,
        temp;
      if (i == 0) return this;
      while (--i) {
        j = Math.floor(Math.random() * (i + 1));
        temp = this[i];
        this[i] = this[j];
        this[j] = temp;
      }
      return this;
    };

    Array.prototype.equals = function (array) {
      if (!array) return false;

      if (this.length !== array.length) return false;

      for (let i = 0, l = this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
          // recurse into the nested arrays
          if (!this[i].equals(array[i])) return false;
        } else if (this[i] !== array[i]) {
          // Warning - two different object instances will never be equal: {x:20} != {x:20}
          return false;
        }
      }
      return true;
    };

    Array.range = (size, startAt = 0) => {
      return [...Array(size).keys()].map((i) => i + startAt);
    };

    Object.defineProperty(Object.prototype, "objectMap", {
      value: function (callback) {
        Object.keys(this).map(callback);
      },
      writable: true,
      configurable: true,
      enumerable: false,
    });

    Object.defineProperty(Object.prototype, "isEmpty", {
      value: function () {
        return (
          this &&
          Object.keys(this).length === 0 &&
          Object.getPrototypeOf(this) === Object.prototype
        );
      },
      writable: true,
      configurable: true,
      enumerable: false,
    });
  }
}
