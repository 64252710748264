const state = {
  seconds: 0,
  start: null,
  timerId: null,
};

const mutations = {
  SET_SECONDS(state, payload) {
    state.seconds = payload;
  },
  SET_START_TIME(state, payload) {
    state.start = payload;
  },
  SET_TIMER_ID(state, payload) {
    state.timerId = payload;
  },
};

const actions = {
  startTimer({ commit, state, rootGetters }) {
    const currentStudentExercise =
      rootGetters["exercise/getCurrentStudentExercise"];
    commit("SET_START_TIME", currentStudentExercise?.started_at ?? Date.now);
    commit(
      "SET_TIMER_ID",
      setInterval(() => commit("SET_SECONDS", state.seconds + 1), 1000)
    );
  },
  killTimer({ state }) {
    clearInterval(state.timerId);
  },
};

const getters = {
  getSeconds(state) {
    return state.seconds;
  },
  getFormattedTimer(state) {
    let date = new Date(null);
    date.setSeconds(state.seconds);
    return date.toISOString().substr(11, 8);
  },
  getFormattedTime(state) {
    if (!state.start) return null;
    const date = new Date(state.start * 1000);
    let hours = date.getHours();
    let minutes = "0" + date.getMinutes();
    let seconds = "0" + date.getSeconds();

    return hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
  },
  getStartTime(state) {
    return state.start;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
