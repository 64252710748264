import { Model } from "@/models/Model";

export default class Test extends Model {
  constructor({
    current_step,
    current_word,
    id,
    is_complete,
    label,
    phase_id,
    words,
  }) {
    super();
    this._current_step = current_step;
    this._current_word = current_word;
    this._id = id;
    this._is_complete = is_complete;
    this._label = label;
    this._phase_id = phase_id;
    this._words = words;
  }

  get words() {
    return this._words;
  }

  get current_step() {
    return this._current_step;
  }

  get current_word() {
    return this._current_word;
  }

  get id() {
    return this._id;
  }

  get is_complete() {
    return this._is_complete;
  }

  get label() {
    return this._label;
  }

  get phase_id() {
    return this._phase_id;
  }
}
