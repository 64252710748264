<template>
  <div
      class="dynamic-coin-counter flexbox column justify-content-center align-center position-absolute"
      v-if="this.currentCoins >= 0"
  >
    <p class="student__coins plus transparent position-absolute" id="dcc"></p>
    <p class="student__coins coin">
      {{ currentCoins }}
    </p>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'DynamicCoinCounter',
  mounted() {
    this.currentCoins = this.profile?.student?.points
    this.bind({
      type: "complete_exercise",
      callback: ({activity}) => {
        if (activity?.points && activity && this.counterElement) {
          this.playEffect('complete_exercise')
          this.animatePlusPoints(activity)
        }
      }
    })
  },
  data: () => ({
    currentCoins: null,
    animationDuration: 1000,
    frameDuration: 1000 / 60,
  }),
  methods: {
    ...mapActions({
      bind: "pusher/bind",
      playEffect: "audio/playEffect",
    }),
    async animatePlusPoints(activity) {
      const target = this.counterElement // ref doesnt work
      target.innerHTML = activity.points - this.currentCoins

      // repeat object to sustain animation?
      // TODO @keyframes has percentage to sustain animations, see if JS API has similar?

      target.animate([
        {opacity: 0, transform: 'translateY(0)'},
        {opacity: 1, transform: 'translateY(-100%)'},
        {opacity: 1, transform: 'translateY(-100%)'},
        {opacity: 1, transform: 'translateY(-100%)'},
        {opacity: 1, transform: 'translateY(-100%)'},
        {opacity: 1, transform: 'translateY(-100%)'},
        {opacity: 1, transform: 'translateY(-100%)'},
        {opacity: 1, transform: 'translateY(-100%)'},
        {opacity: 1, transform: 'translateY(-100%)'},
        {opacity: 0, transform: 'translateY(0)'},
      ], 3000)

      setTimeout(async () => {
        this.animateCountUp(this.currentCoins, activity.points)
      }, 2550)
    },
    easeOut: t => t * (2 - t),
    animateCountUp(from, to) {
      let playedOnce = false
      let frame = 0;
      const counter = setInterval(() => {
        frame++;
        const progress = this.easeOut(frame / this.totalFrames);

        const currentCount = Math.round(to * progress);

        if (!playedOnce && currentCount >= from) {
          playedOnce = true
          this.playEffect('coins')
        }

        if (currentCount > from) {
          this.currentCoins = currentCount;
        }

        if (frame === this.totalFrames) {
          this.profile.student.points = to
          clearInterval(counter);
        }
      }, this.frameDuration);
    }
  },
  computed: {
    ...mapGetters({
      profile: "user/getProfile"
    }),
    totalFrames() {
      return Math.round(this.animationDuration / this.frameDuration);
    },
    counterElement() {
      return document.getElementById('dcc');
    }
  },
  // watch: {
  //   newValue(newValue, oldValue) {
  //     if (newValue > oldValue) {
  //       this.animateCountUp(oldValue, newValue)
  //     }
  //   }
  // }
}
</script>

<style lang="scss">
.dynamic-coin-counter {
  z-index: 1000;
  user-select: none;
}
</style>
