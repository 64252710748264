import {Student} from "@/models/Student";
import {Model} from "@/models/Model";
import store from "@/store/index";

export class Profile extends Model {
    constructor({
                    active_subscription,
                    created_at,
                    email,
                    id,
                    last_login_at,
                    name,
                    organizations,
                    profile,
                    student,
                    subscriptions,
                    updated_at,
                    username,
                    channel_id,
                }) {
        super();
        this._student = new Student(student);
        this._active_subscription = active_subscription;
        this._created_at = created_at;
        this._email = email;
        this._id = id;
        this._last_login_at = last_login_at;
        this._name = name;
        this._organizations = organizations;
        this._profile = profile;
        this._student = student;
        this._subscriptions = subscriptions;
        this._updated_at = updated_at;
        this._username = username;
        this._channel_id = channel_id;

        store.dispatch("pusher/subscribe", channel_id)
    }

    get active_subscription() {
        return this._active_subscription;
    }

    set active_subscription(value) {
        this._active_subscription = value;
    }

    get created_at() {
        return this._created_at;
    }

    set created_at(value) {
        this._created_at = value;
    }

    get email() {
        return this._email;
    }

    set email(value) {
        this._email = value;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get last_login_at() {
        return this._last_login_at;
    }

    set last_login_at(value) {
        this._last_login_at = value;
    }

    get name() {
        return this._name;
    }

    set name(value) {
        this._name = value;
    }

    get organizations() {
        return this._organizations;
    }

    set organizations(value) {
        this._organizations = value;
    }

    get profile() {
        return this._profile;
    }

    set profile(value) {
        this._profile = value;
    }

    get student() {
        return this._student;
    }

    set student(value) {
        this._student = value;
    }

    get subscriptions() {
        return this._subscriptions;
    }

    set subscriptions(value) {
        this._subscriptions = value;
    }

    get updated_at() {
        return this._updated_at;
    }

    set updated_at(value) {
        this._updated_at = value;
    }

    get username() {
        return this._username;
    }

    set username(value) {
        this._username = value;
    }

    get channel_id() {
        return this._channel_id;
    }
}
