<template>
  <div class="exercise-container" ref="container">
    <swiper
        :items="exercises"
        :options="{
          slidesPerView: 5,
          initialSlide: sliderIndex
        }"
        @change="setIndex"
    >
      <template #slide="{ item }">
        <div class="exercise-slide-wrapper">
          <div class="exercise-icon-wrapper" :class="{'current-exercise': isCurrentExercise(item)}">
            <div class="exercise-icon">
              <img
                  v-if="exerciseProgress(item)"
                  :src="exerciseProgress(item)"
                  alt="exercise-progress"
                  class="exercise-progress"
              />
              <img
                  class="exercise-category"
                  alt="exercise-icon"
                  :src="exerciseIcon(item)"
                  @click.stop.once="goToExercise(item)"
              />
            </div>
          </div>

          <div class="exercise-name">
            <h3>{{ exerciseName(item) }}</h3>
          </div>
        </div>
      </template>
    </swiper>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Swiper from "@/components/Lespagina/Swiper.vue";

export default {
  name: "CExerciseSlider",
  components: {Swiper},
  data: () => ({
    hasWords: false,
    /** @var {Exercise[]}*/
    exercises: [],
    currentExercise: {id: null},
    containerWidth: 0,
    slides: [],
    /** @var {Swiper} */
    swiper: null
  }),
  mounted() {
    this.sortExercises();
  },
  methods: {
    ...mapActions({
      previousExerciseSlide: "lessonPage/previousExerciseSlide",
      nextExerciseSlide: "lessonPage/nextExerciseSlide",
      queueSlide: "lessonPage/queueSlide",
      slide: "lessonPage/slide",
    }),
    ...mapMutations({
      setSlider: "lessonPage/SET_SLIDER_INDEX_BY_STEP"
    }),
    setIndex(event) {
      this.setSlider({
        stepID: this.step.id,
        sliderIndex: event,
      })
    },
    isCurrentExercise(exercise) {
      return exercise && this.currentExercise && exercise.id === this.currentExercise.id
    },
    exerciseName(exercise) {
      const name = this.step.exercises
          .filter((e) => {
            return e.id === exercise.id;
          })
          .map((e) => {
            return e.name ? e.name : e.exercise_type.name;
          });

      return name[0];
    },
    exerciseIcon(exercise) {
      if (exercise.isShoe()) return require("@/assets/icon-exercise.png");
      if (exercise.isBinocular()) return require("@/assets/isoleren-btn.png");
      if (exercise.isCompass()) return require("@/assets/compass-icon.png");

      return null;
    },
    exerciseProgress(exercise) {
      if (exercise.isDone()) return require("@/assets/img/exercises/exercise_done_small.png");
      if (exercise.isPaused()) return require("@/assets/img/exercises/exercise_paused_small.png");
      if (exercise.isFailed()) return require("@/assets/img/exercises/exercise_repeat_small.svg");

      return null;
    },
    goToExercise(exercise) {
      // this.setExerciseSliderIndex(this.step.exercises.findIndex((e) => e.id === exercise.id));

      this.$router.push({
        name: 'exercise',
        params: {exercise_id: exercise.id},
      })
    },
    sortExercises() {
      this.exercises = this.step.exercises;
      this.currentExercise = this.exercises.find((e) => (e.isOpen() || e.isFailed() || e.isPaused()));
      this.slides = this.exercises.reduce((acc, currentValue, currentIndex) => {
        const slideNumber = Math.floor(currentIndex / this.numberOfSlides);
        if (typeof acc[slideNumber] === 'undefined') {
          acc[slideNumber] = []
        }

        acc[slideNumber].push(currentValue)
        return acc;
      }, {})
    },
  },
  computed: {
    ...mapState("user", ["loggedIn", "user"]),
    ...mapGetters({
      step: "lessonPage/getStep",
      studentExercise: "exercise/getCurrentStudentExercise",
      profile: "user/getProfile",
      difficulty: "lessonPage/getDifficulty",
      sliderIndex: "lessonPage/getSliderIndex",
    }),
    numberOfSlides() {
      return 4;
      // return Math.floor((this.containerWidth - 80) / 240)
    }
  },
  watch: {
    difficulty() {
      this.sortExercises()
    }
  }
};
</script>

<!--suppress CssInvalidPropertyValue -->
<style scoped lang="scss">

.exercise-container {
  height: 50%;
  position: relative;
}

.exercise-icon {
  height: 100%;
}

.exercise-slide-wrapper {
  width: 100%;
  height: 70%;
  color: #5e5e5d;
  background: transparent;
  position: relative;

  .exercise-name {
    user-select: none;
    background: url("../../assets/titelbalk.png") no-repeat;
    background-size: cover;
    bottom: 10%;
    left: 5%;
    right: 5%;
    position: absolute;
    translate: 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3em;

    h3 {
      font-size: 1em;
      padding: 0 0.5em;
    }
  }
}

.exercise-progress {
  position: absolute;
  width: 25%;
  aspect-ratio: 1 / 1;
  z-index: 999;
  pointer-events: none;
  user-select: none;
}

.exercise-category {
  cursor: pointer;
  transition: transform .2s;
  height: 100%;
}

.exercise-category:not([disabled]):hover {
  transform: scale(1.1);
}

.has-words {
  word-break: word-break;
  width: 100%;
  padding-top: 100px;
  text-align: center;
  font-weight: bold;
  color: #016d47 !important;
}
.exercise-icon {
  margin: 0 auto;
  height: 100%;
}

.exercise-icon-wrapper {
  height: 65%;
  top: 0;
  left: 0;
  position: absolute;
  right: 0;

  &.current-exercise {
    .exercise-icon {
      position: relative;

      &:after {
        border-radius: 50%;
        left: 50%;
        transform: translateX(-50%) scale(0.9);
        height: 100%;
        z-index: -1;
        aspect-ratio: 1 / 1;
        position: absolute;
        content: "";

        animation: flashing-exercise ease-in-out infinite 3s;
        pointer-events: none;
      }

      img {
      }
    }
  }
}

.exercise-category {
  cursor: pointer;
  transition: transform .2s;
  height: 100%;
}

.exercise-category:not([disabled]):hover {
  transform: scale(1.1);
}

.exercise-container {
  .swiper {
    height: 100%;
  }
}
</style>
