<template>
  <div class="full-world-container">
    <div class="full-world-overview" v-if="!isUAMobile || true">
      <div
          v-for="world in Array.range(4, 1)"
          :key="world"
          :class="{
          disabled: isDisabled(world),
          active: isActive(world),
           [`world_wrapper_${world}`]: true,
           [`world_${world}_background`]: true
        }"
          class="world-wrapper"
      >
        <div class="locked-world" v-if="isDisabled(world) && false">
          <img src="../assets/lock.png" alt="locked_world"/>
        </div>
        <transition name="fade">
          <div class="completed-world" v-if="isCompleted(world) && !isActive(world)">
            <div class="completed-world__diamond-background">
              <img :src="getDiamond(world)" alt="completed-world"/>
            </div>
          </div>
        </transition>
        <c-world :number="world"></c-world>
      </div>

      <c-world-legend/>
    </div>

    <CWorldSlider v-if="isUAMobile && false" />


    <Modal
        v-model="showModal"
        @input="goToProfile"
    >
      <template v-slot:body>
        <img class="flex-center introduction-video" src="../assets/img/world/video-wip.gif">
        <p>Wij werken aan een introductievideo</p>
        <!--        Video!-->
        <!--        <youtube :video-id="videoId" class="first-video"></youtube>-->
      </template>
    </Modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import Modal from "@/components/Modal";
import CWorld from "@/components/World/World";
import CWorldLegend from "@/components/World/Legend";
import HelpOverlayButton from "@/components/Base/HelpOverlayButton";
import CWorldSlider from "@/components/World/WorldSlider";

export default {
  components: {
    CWorldSlider,
    HelpOverlayButton,
    CWorldLegend,
    CWorld,
    Modal,
  },
  name: "Home",
  data: () => ({
    videoId: "dQw4w9WgXcQ",
    opening: false,
    showModal: false
  }),
  mounted() {
    if (this.$route.params.world_id) {
      this.setWorld(this.$route.params.world_id)
    }

    if (this.student && !this.isIntroductionCompleted) {
      this.showModal = true;
    }
  },
  methods: {
    ...mapMutations({
      setWorld: "world/SET_WORLD",
    }),
    ...mapActions({
      fetchSteps: "world/fetchSteps",
    }),
    isDisabled(world) {
      return !this.isIntroductionCompleted || !this.isUnlocked(world)
    },
    getDiamond(world) {
      return require(`../assets/img/end-cave/diamond-${world}-slotted.png`)
    },
    goToProfile(open) {
      if (open === false) {
        this.$router.push('/profiel')
      }
    },
  },
  computed: {
    ...mapGetters({
      profile: "user/getProfile",
      badges: "user/getBadges",
      student: "user/getStudent",
      isIntroductionCompleted: "user/isIntroductionCompleted",
      isActive: "world/isActive",
      activeWorld: "world/getActiveWorld",
      isUnlocked: "world/isUnlocked",
      isCompleted: "world/isCompleted",
      isInitializing: "isInitializing",
    }),
  },
  watch: {
    $route(to) {
      if (to.name === "world_overview") {
        this.setWorld(null)
      }
    }
  }
};
</script>

<style scoped lang="scss">
.introduction-video {
  width: 100%;
  margin-top: -2px
}

.vhfix .full-world-container {
  height: calc(100vh - 157px);
  margin: 80px auto 0;
}

.full-world-container {
  display: flex;
  margin: 80px auto 0;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;

  height: calc(100vh - 80px);
  //max-height: 968px;
  aspect-ratio: 180 / 121;


  .full-world-overview {
    width: 100%;
    height: 100%;
    position: relative;
    //height: calc(100vh - 80px);
    //max-height: 968px;
    //aspect-ratio: 180 / 121;
    //overflow: hidden;
  }

  .world-wrapper {
    transition: all 1s;
    height: 50%;
    width: 50%;
    position: absolute;
    background-size: cover;
    z-index: 0;
    aspect-ratio: 180 / 121;
    overflow: hidden;


    //height: 484px;
    //width: 720px;
    //position: absolute;
    //background-size: cover;
    //z-index: 0;

    &.active {
      width: 100%;
      height: 100%;
      z-index: 97;
    }

    &.disabled {
      z-index: 0;
    }

    &:not(.active) {
      cursor: pointer;

      ::v-deep .end-cave {
        height: 200px;
        width: 200px;

        img {
          height: 200px;
          width: 200px;
        }
      }
    }

    &:hover:not(.disabled):not(.active) {
      transform: perspective(500px) translateZ(5px);
    }

    &.disabled:hover {
      filter: grayscale(30%);
    }
  }
}

.world-wrapper.active {
  .world {
    //width: 100%;
    //height: 100%;
  }
}

.world_wrapper_1 {
  left: 0;
  top: 0;
}

.world_wrapper_2 {
  right: 0;
  top: 0;

  &.active {
    right: 0;
  }
}

.world_wrapper_3 {
  left: 0;
  bottom: 0;
}

.world_wrapper_4 {
  right: 0;
  bottom: 0;
}

.disabled {
  filter: grayscale(80%);
}

.locked-world {
  width: 200px;
  z-index: 97;
}

.completed-world {
  .completed-world__diamond-background {
    background: url("../assets/img/world/diamond-background.png");
    background-size: cover;
    width: 100%;
    position: relative;
    aspect-ratio: 26 / 19;

    img {
      width: 20%;
      top: 38%;
      left: 49.5%;
      transform: translate(-50%, -50%);
      position: absolute;
    }
  }
}

.locked-world, .completed-world {
  position: absolute;
  width: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 97;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    transition: all 1s;
    width: 100%;
  }
}

.completed-world {
  pointer-events: none;
}

</style>
