<template>
  <div class="jungle-content with-padding jungle-aspect-ratio">
    <c-liana/>
    <p class="no-notes" v-if="this.notes.length === 0">Er zijn nog geen notities</p>
    <v-btn
        class="nieuwe-notitie square-button yellow"
        color="#FDDA00"
        type="button"
        @click="createModal = true"
    >
      <v-icon>
        mdi-plus-circle
      </v-icon>
      nieuwe notitie
    </v-btn>
    <carousel-3d
        :display="3"
        :perspective="0"
        :space="400"
        :height="null"
        :key="this.notes.length"
        :controls-visible="false"
        @before-slide-change="(index) => activeSlide = index"
        width="800px"
    >
      <slide
          v-for="(note, id) in notes"
          :key="id"
          :index="id"
          class="opdracht-slide"
      >
        <p class="opdracht-datum">{{ displayDate }}</p>
        <h3>{{ note.title }}</h3>
        <p class="opdracht-content" v-html="lf2br(note.text)"/>

        <v-btn
            class="opdracht-edit delete"
            color="#EA5F56"
            height="50px"
            width="50px"
            min-width="50px"
            fab
            @click="deleteNote(note.id)"
            v-if="id === activeSlide"
            style="min-width: 50px !important;"
        >
          <v-icon color="#ffffff">
            mdi-trash-can-outline
          </v-icon>
        </v-btn>

        <v-btn
            class="opdracht-edit"
            height="50px"
            width="50px"
            min-width="50px"
            color="#016C49"
            fab
            @click="showEditModal(note)"
            v-if="id === activeSlide"
            style="min-width: 50px !important;"
        >
          <v-icon color="#ffffff">
            mdi-pencil
          </v-icon>
        </v-btn>
      </slide>
    </carousel-3d>

    <modal v-model="createModal" class="new-note">
      <template v-slot:body>
        <v-form
            ref="form"
            @submit.prevent="submitForm"
        >
          <v-row class="profile-row">
            <v-col class="column-form">
              <span class="form-label">Titel</span>
              <v-text-field
                  v-model="newTitle"
                  label="titel"
                  type="text"
                  background-color="#fff"
                  required
                  solo
                  flat
                  outlined
                  validate-on-blur
              ></v-text-field>

              <span class="form-label">Notitie</span>
              <v-textarea
                  v-model="newText"
                  label="notitie"
                  type="text"
                  background-color="#fff"
                  required
                  solo
                  flat
                  outlined
                  validate-on-blur
              ></v-textarea>

              <v-btn
                  class="no-margin btn-save-profile yellow"
                  height="50"
                  color="#FFD801"
                  tile
                  small
                  rounded
                  @click="addNote({title: newTitle, text: newText})"
              >
                Toevoegen
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </modal>

    <modal v-model="editModal">
      <template v-slot:body>
        <v-form
            ref="form"
            @submit.prevent="submitForm"
        >
          <v-row class="profile-row">
            <v-col class="column-form">
              <span class="form-label">Titel</span>
              <v-text-field
                  v-model="title"
                  :label="title"
                  type="text"
                  background-color="#fff"
                  required
                  solo
                  flat
                  outlined
                  validate-on-blur
              ></v-text-field>

              <span class="form-label">Notitie</span>
              <v-textarea
                  v-model="text"
                  :label="text"
                  type="text"
                  background-color="#fff"
                  required
                  solo
                  flat
                  outlined
                  validate-on-blur
              ></v-textarea>

              <v-btn
                  class="no-margin btn-save-profile yellow"
                  height="50"
                  color="#FFD801"
                  tile
                  small
                  rounded
                  @click="updateNote({id, title, text})"
              >
                Bijwerken
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </modal>
  </div>
</template>

<script>
import {Carousel3d, Slide} from 'vue-carousel-3d';
import Modal from '@/components/Modal'
import {mapActions, mapGetters} from "vuex";
import CLiana from "@/components/Liana";

export default {
  components: {
    CLiana,
    Carousel3d,
    Slide,
    Modal
  },
  data: () => ({
    createModal: false,
    editModal: false,
    newTitle: null,
    title: null,
    newText: null,
    text: null,
    id: null,
    displayDate: null,
    activeSlide: 0
  }),
  computed: {
    ...mapGetters({
      notes: "user/getNotes"
    })
  },
  methods: {
    ...mapActions({
      deleteNote: "user/deleteNote",
      updateNoteAction: "user/updateNote",
      addNoteAction: "user/addNote",
    }),
    lf2br(text) {
        return text.split('\n').join('<br>');
    },
    addNote(payload) {
      this.addNoteAction(payload)
          .then(() => {
            this.newTitle = null
            this.newText = null
            this.createModal = false
          })
    },
    updateNote(payload) {
      this.updateNoteAction(payload).then(() => this.editModal = false)
    },
    showEditModal(note) {
      this.id = note.id
      this.title = note.title
      this.text = note.text
      this.editModal = true;
    },
  },
}
</script>

<style scoped lang="scss">

h1 {
  z-index: 9999;
}

.no-notes {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
}

.nieuwe-notitie {
  position: absolute;
  right: 50px;
  top: 50px;
  z-index: 1000;
}

.v-btn.nieuwe-notitie {
  border: 2px solid white !important;
}

.carousel-3d-container {
  z-index: 999;
  margin: 70px;
  width: calc(100% - 120px);
  height: calc(100% - 140px);
}
::v-deep .carousel-3d-slider {
  height: calc(100% - 15px);
}
.opdracht-slide {
  color: #5E5E5D;
  padding: 40px 30px;
  background: white;
  box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 30%);
  border: none;
  border-radius: 4px;
  height: calc(100% - 15px) !important;
}

.opdracht-slide.current {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
  margin-top: 15px;
}

.opdracht-slide:not(.current) .opdracht-edit {
  background-color: #5E5E5D !important;
}

.opdracht-edit {
  bottom: 30px;
  right: 30px;
  position: absolute;
}

.opdracht-edit.delete {
  right: 85px;
}

.opdracht-datum {
  font-size: 12px;
}

h3 {
  color: #016D47;
}

.profile-row {
  height: 100%;
  box-shadow: none !important;
}

.opdracht-content {
  font-weight: bold;
  margin-top: 25px;
}

.new-note {
  :deep(input), :deep(textarea) {
    font-size: 25px !important;
  }
}
</style>