<template>
  <div class="sentence-input-container">
    <FilteredSentence />

    <c-restricted-input class="sentence-input" v-model="currentInput" v-if="restricted"/>
    <c-input class="sentence-input" v-model="currentInput" v-else/>

    <c-next-button :disabled="!currentInput"/>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import CRestrictedInput from "@/components/Exercise/Components/RestrictedInput";
import CInput from "@/components/Exercise/Components/Input";
import CNextButton from "@/components/Exercise/Components/NextButton";
import FilteredSentence from "@/components/Exercise/Content/FilteredSentence";

export default {
  name: "c-sentence-template",
  components: {FilteredSentence, CNextButton, CInput, CRestrictedInput},
  methods: {
    ...mapMutations({
      setCurrentInput: "exercise/SET_CURRENT_INPUT",
    }),
    ...mapActions({
      postData: "exercise/postData",
      incrementErrors: "exercise/incrementErrors",
    }),
    checkWord(isAllowed, input) {
      this.typed = input
      if (isAllowed) {
        this.setCurrentInput({word: this.typed})
      } else {
        this.setCurrentInput({word: this.typed})
        this.incrementErrors().then(() => this.postData());
      }
    },
    input(input) {
      this.typed = input
      this.setCurrentInput({word: this.typed})
    },
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      getCurrentInput: "exercise/getCurrentInput",
      progress: "exercise/getProgress",
      isTest: "router/isTest",
      template: "template/getCurrentTemplate",
      wordData: "exercise/getWordData",
      restricted: "template/hasRestricted",
    }),
    progressStep() {
      return this.progress.step;
    },
    currentInput: {
      get() {
        return this.getCurrentInput.word;
      },
      set(value) {
        if (!value) return;
        // if (value === this.currentInput) {
        //   this.postData();
        // }
        this.setCurrentInput({word: value});
      }
    },
    sentence() {
      return this.wordData?.sentence;
    }
  }
};
</script>

<style scoped lang="scss">
.sentence-input-container {
  height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.restricted-input-container {
  height: 15%;
  width: 50%;
}

.sentence-input {
  margin: 0 auto;
}

.sentence {
  font-size: 40px;
  color: #000;
}
</style>