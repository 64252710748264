import Vue from "vue";

const defaultState = () => ({
  manualParameters: {
    template: null,
    currentInput: null,
    expectedInput: null,
  },
  errors: [],
  debugReportModalVisibility: false,
});

const state = defaultState;

const mutations = {
  SET_MANUAL_TEMPLATE(state, payload) {
    state.manualParameters.template = payload;
  },
  SET_CURRENT_INPUT(state, payload) {
    state.manualParameters.currentInput = payload;
  },
  SET_EXPECTED_INPUT(state, payload) {
    state.manualParameters.expectedInput = payload;
  },
  SHOW_DEBUG_REPORT_MODAL(state) {
    state.debugReportModalVisibility = true;
  },
  HIDE_DEBUG_REPORT_MODAL(state) {
    state.debugReportModalVisibility = false;
  },
  APPEND_ERRORS(state, payload) {
    state.errors.push(payload);
  },
};

const actions = {
  appendError({ commit }, payload) {
    console.log("Error appended");
    commit("APPEND_ERRORS", payload);
  },
  proxifyConsoleError({ dispatch }) {
    function proxy(context, method) {
      return function () {
        dispatch("appendError", arguments);
        method.apply(context, arguments);
      };
    }

    console.error = proxy(console, console.error);
  },
  /**
   * @param _obj
   * @param {UserReport} userReport
   */
  sendUserReport(_obj, userReport) {
    return Vue.prototype.$api.endpoints.userReport.post({
      title: userReport.title,
      category: userReport.category,
      description: userReport.description,
      data: userReport.data,
    });
  },
};

const getters = {
  getExpectedInput: (state, getters, rootState, rootGetters) => {
    return (
      state.manualParameters.expectedInput ||
      rootGetters["exercise/getExpectedInput"]
    );
  },
  debugReportModalVisibility: (state) => {
    return state.debugReportModalVisibility;
  },
  getLastFiveErrors: (state) => {
    return state.errors.splice(-5);
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
};
