<template>
  <bubble-modal :value="open" @change="$emit('change')">
    <ScoreboardContent/>
  </bubble-modal>
</template>

<script>
import ScoreboardContent from "@/components/TopMenu/ScoreboardContent";
import BubbleModal from "@/components/Modals/BubbleModal";

export default {
  name: "ScoreboardModal",
  components: {BubbleModal, ScoreboardContent},
  props: {
    open: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .scoreboard {
    border-radius: 30px;
    padding: 30px;
    height: 100%;
  }

  .scoreboard-area:not(.scoreboard-badges) {
    height: 43%;
  }
}
</style>