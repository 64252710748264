<template>
  <div class="word-list-container" >
    <h3 class="description" v-html="exercise.after_complete_text" >
    </h3>

    <div class="word-list">
      <p
          v-for="(detail, id) in results"
          :key="id"
      >
        {{ detail.word }}

        <span v-if="detail.cheats_used">
              <font-awesome-icon
                  class="side-icon"
                  icon="eye"
                  color="grey"
              />
        </span>
        <span v-if="detail.hints_used">
              <font-awesome-icon
                  class="side-icon"
                  icon="question"
                  color="grey"
              />
        </span>
      </p>
    </div>

    <COutroButtonContainer />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import COutroButtonContainer from "@/components/Exercise/Outro/OutroButtonContainer";

export default {
  name: "c-word-list",
  components: {COutroButtonContainer},
  methods: {
    ...mapActions({
      resetExercise: "exercise/resetExercise",
    })
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      results: "exercise/getCorrectResults",
      linkToLessonOverview: "router/linkToLessonOverview",
    }),
  }
};
</script>

<style scoped lang="scss">
.word-list-container {
  height: 100%;
  text-align: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.word-list {
  display: flex;
  justify-content: center;
  max-width: 700px;
  margin: 0 auto !important;
  flex-wrap: wrap;
  min-width: 50%;
  //padding: 20px;

  p {
    font-size: 18px !important;
    color: #016d47 !important;
    margin: 10px !important;

    span {
      margin: 0 3px;
    }
  }
}

.word-list-button-container {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
}
</style>