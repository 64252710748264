<template>
  <div class="story-input-container" :class="{'has-word': isTemplate('Zinnen1')}">
    <h3 class="description">{{ description }}</h3>
    <h2 class="description">Luister hier naar je gekozen woorden: </h2>
    <div class="chosen-words-container">
      <div v-for="(word, index) in getTwoWordStoryWords" :key="index" class="chosen-word">
        <c-speaker  :class="{found: isFound(word)}" :word="word"
                    v-if="!isFound(word)"></c-speaker>

        <font-awesome-icon
            class="side-icon"
            icon="check"
            color="#51AE4B"
            v-if="isFound(word)"
        />
      </div>
    </div>

    <c-text-area class="story-input" v-model="currentInput"/>
    <c-next-button v-model="currentInput" :disabled="disabled"/>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import CNextButton from "@/components/Exercise/Components/NextButton";
import CTextArea from "@/components/Exercise/Components/TextArea";
import CSpeaker from "@/components/Base/c-speaker";

export default {
  name: "c-sentence-template",
  components: {CSpeaker, CTextArea, CNextButton},
  methods: {
    ...mapMutations({
      setCurrentInput: "exercise/SET_CURRENT_INPUT",
      setWordVisibility: "exercise/SET_WORD_VISIBILITY",
    }),
    isFound(word) {
      return this.currentInput && this.currentInput.toLowerCase().includes(word.toLowerCase())
    },
  },
  computed: {
    ...mapGetters({
      word: "exercise/getWord",
      getCurrentInput: "exercise/getCurrentInput",
      description: "template/getDescription",
      hasHidden: "template/hasHidden",
      visible: "exercise/isWordVisible",
      isTemplate: "template/isTemplate",
      currentInputKey: "template/currentInputKey",
      getTwoWordStoryWords: "exercise/getTwoWordStoryWords",
    }),
    disabled() {
      return this.getTwoWordStoryWords.some(e => !this.currentInput || !this.currentInput.toLowerCase().includes(e.toLowerCase()))
    },
    currentInput: {
      get() {
        return this.getCurrentInput.story;
      },
      set(value) {
        this.setCurrentInput({story: value, words: this.getTwoWordStoryWords});
      }
    },
  }
};
</script>

<style scoped lang="scss">
.story-input-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.has-word {
  margin-top: 0;
}

.visible {
  opacity: 1 !important;
}

.hide-button {
  margin: 40px 0 0;
}

.description {
}

.word {
  font-size: 30px;
  color: black;
  opacity: 0;
  transition: opacity .3s;
}

.chosen-words-container {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: 0 auto;
  transition: all .3s;
  max-height: 35px;

  .chosen-word {
    svg {
      height: 35px;
      width: 35px;
    }
  }

  p {
    padding: 10px;
    margin: 15px !important;
    border-radius: 30px;
  }

  p.found {
    color: #146B4B;
    outline: 3px green solid;
    box-shadow: 7px 9px 15px 1px #747474;
  }
}
</style>