const state = () => ({
  notifications: [],
});

let nextId = 1;

const mutations = {
  PUSH(state, notification) {
    state.notifications.push(notification);
  },
  REMOVE_BY_ID(state, id) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== id
    );
  },
  REMOVE_BY_TYPE(state, type) {
    state.notifications = state.notifications.filter(
      (notification) => notification.type !== type
    );
  },
};
const actions = {
  add({ commit }, notification) {
    notification.id = ++nextId;

    commit("REMOVE_BY_ID", notification.id);
    commit("REMOVE_BY_TYPE", notification.type);
    commit("PUSH", notification);

    setTimeout(() => commit("REMOVE_BY_ID", notification.id), 4000);
  },
  removeById({ commit }, id) {
    commit("REMOVE_BY_ID", id);
  },
  removeByType({ commit }, type) {
    commit("REMOVE_BY_TYPE", type);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
