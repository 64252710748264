<template>
  <div class="progress-bar">
<!--    current:  {{ currentProgress }}<br>-->
<!--    total:  {{ totalProgress }}<br>-->
<!--    remainingProgress:  {{ remainingProgress }}<br>-->
<!--    remainigPercent:  {{remainingPercentage}}<br>-->
<!--    increment:  {{increment}}<br>-->
<!--    totalIncrements: {{increment * remainingProgress}}-->
    <v-progress-linear
        :value="percentage"
        v-if="hasProgressBar"
        height="30"
        color="#54B045"
        rounded
        background-color="#5E3824"
    ></v-progress-linear>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

// I make mistake on 10 out of 100
// total goes to 102, because wants to repeat the same words 2 more times
// progress is still 10
// total is now 102, so bar goes backwards
// what i need to do is reduce the increments
// add percentage of remaining progress
// in this case remaining progress is 92
// percentage is still at 10
// remaining percentage is 90
// every progress increment 1/92th of remaining percentage
// (90 * 0.92) * (1 / 92)

export default {
  name: 'c-exercise-progress-bar',
  data: () => ({
    percentage: 0,
    initialTotalProgress: 0
  }),
  created() {
    if (this.currentProgress > 0) {
      this.percentage = this.currentProgress / this.totalProgress * 100
    }
    this.initialTotalProgress = this.totalProgress;
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      progress: "exercise/getProgress",
      isTest: "router/isTest",
      testProgress: "exercise/getTestProgress",
      hasProgressBar: "exercise/hasProgressBar",
    }),
    currentProgress() {
      if (this.isTest) {
        return this.testProgress.current
      }

      return this.progress.current
    },
    totalProgress() {
      if (this.isTest) {
        return this.testProgress.total
      }

      return this.progress.total
    },
    remainingProgress() {
      return this.totalProgress - (this.currentProgress)
    },
    remainingPercentage() {
      return (100 - this.percentage)
    },
    increment() {
      return (this.remainingPercentage) * (1 / (this.remainingProgress + 1));
    }
  },
  watch: {
    currentProgress() {
      this.percentage += this.increment
    },
  }
}
</script>

<style scoped>
.progress-bar {
  width: 500px;
  display: block;
  margin: 0 auto;
}

.v-progress-linear {
  border-radius: 40px;
  border: 3px solid #3f1d05;
  z-index: 97;
  background: #3f1d05;
  display: block;
  margin: 0 auto;
}
</style>