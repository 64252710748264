<template>
  <div class="jungle-content with-padding jungle-aspect-ratio">
      <div class="shop-header">
        <span class="total-coins" type="button"> {{ this.profile.student.points }} </span>
        <v-btn
            class="square-button"
            color="#FDDA00"
            @click.stop="$router.push({ name: 'mijn badges' })"
        >
          Bekijk jouw badges
        </v-btn>
      </div>

      <div class="jungle-badges-list" v-if="unboughtAndBuyableBadges.length > 0">
        <c-badge-card
            :shop="true"
            v-for="badge in unboughtAndBuyableBadges"
            :key="badge.id"
            :badge="badge"
            @buy="openConfirmationModal"
        />
      </div>

      <div class="jungle-badges-list" v-else>
        <h3>Er zijn op dit moment geen badges die je kunt kopen.</h3>
      </div>

    <confirmation-modal v-show="isConfirmationModalVisible">
      <template v-slot:body>
        <h2>Weet je het zeker?</h2>
<!--        TODO -->
        <v-btn
            @click="buyBadge"
            color="green"
        >Ja</v-btn>
        <v-btn
            @click="closeConfirmationModal"
            color="red"
        >Nee</v-btn>
      </template>
    </confirmation-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CBadgeCard from "@/components/Badges/BadgeCard";
import ConfirmationModal from "@/components/ConfirmationModal";

export default {
  components: {
    ConfirmationModal,
    CBadgeCard,
  },
  data: () => ({
    isConfirmationModalVisible: false,
    badgeToBuy: null
  }),
  methods: {
    ...mapActions({
      buyBadgeAction: "user/buyBadge"
    }),
    openConfirmationModal(id) {
      this.isConfirmationModalVisible = true;
      this.badgeToBuy = id
    },
    closeConfirmationModal() {
      this.isConfirmationModalVisible = false;
    },
    buyBadge() {
      this.buyBadgeAction(this.badgeToBuy).then(() => {
        this.badgeToBuy = null;
        this.isConfirmationModalVisible = false;
      })
    },
  },
  computed: {
    ...mapGetters({
      profile: "user/getProfile",
      unboughtBadges: "user/getUnboughtBadges",
    }),
    unboughtAndBuyableBadges()   {
      return this.unboughtBadges.filter(e => e.is_buyable)
    }
  },
}
</script>

<style scoped>
.shop-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 25px 0;
}

.total-coins {
  position: relative;
  color: #016D47 !important;
  text-transform: none;
  font-weight: bold;
  font-size: 20px;
  z-index: 9989;
  margin-right: 20px;
}

.total-coins:before {
  background-image: url('../assets/xp.png');
  background-size: 18px 18px;
  display: inline-block;
  width: 18px;
  height: 18px;
  content: "";
  vertical-align: baseline;
  z-index: 2;
}
</style>