<template>
  <div class="sound-group-container">
    <h3 class="sound-group-description">{{ description }}</h3>
    <FilteredSentence/>

    <c-block-input :value="exerciseValue" @input="setInput"/>

    <c-next-button/>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import CBlockInput from "@/components/Exercise/Components/BlockInput";
import CNextButton from "@/components/Exercise/Components/NextButton";
import FilteredSentence from "@/components/Exercise/Content/FilteredSentence";

// TODO conform currentInput computed

export default {
  name: "c-block-typing",
  components: {FilteredSentence, CNextButton, CBlockInput},
  methods: {
    ...mapMutations({
      setCurrentInput: "exercise/SET_CURRENT_INPUT",
    }),
    setInput(input) {
      this.setCurrentInput(this.dataValue(input))
    },
    dataValue(input) {
      if (this.isTemplate("LetterBlokken")) {
        return {word: input};
      }

      if (this.isTemplate("TypKlankgroepen1") || this.isTemplate("TypKlankgroepen2")) {
        return {
          soundGroups: input.replace(",", "-")
        }
      }

      return {};
    },
  },
  computed: {
    ...mapGetters({
      template: "template/getCurrentTemplate",
      word: "exercise/getWord",
      wordData: "exercise/getWordData",
      description: "template/getDescription",
      isTemplate: "template/isTemplate",
    }),
    exerciseValue() {
      if (this.isTemplate("LetterBlokken")) {
        return this.word;
      }

      if (this.isTemplate("TypKlankgroepen1")) {
        return this.wordData?.sound_groups;
      }

      if (this.isTemplate("TypKlankgroepen2")) {
        return this.wordData?.sound_groups.replaceAll(",","-");
      }

      return [];
    },
  }
};
</script>

<style scoped lang="scss">
.block-container {
  display: flex;
  justify-content: center;
}

.sound-group-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.sound-group-description {
  font-size: 30px;
  color: #016d47;
}

p {
  margin: 15px 0;
  font-family: "Quicksand", serif !important;
  line-height: 1.5em;
  color: #70706f;
  font-weight: bold;
  font-size: 15px;
}
</style>