<template>
  <div class="progress_filter_wrapper" :class="{open: filter.isOpen}">
    <div class="progress_filter_button" @click="filter.isOpen = !filter.isOpen">Filter op wereld en moeilijkheid
      <font-awesome-icon
          v-if="filter.isOpen"
          class="side-icon"
          icon="chevron-down"
      />
      <font-awesome-icon
          v-if="!filter.isOpen"
          class="side-icon"
          icon="chevron-up"
      />
    </div>
    <div class="progress_filter">
<!--      <p class="filter-text">Filter op wereld en moeilijkheid</p>-->
      <div class="world_filter">
        <div class="world_button_wrapper" :key="world" v-for="world in Array.range(4, 1)">
          <div
              :class="{[`world_${world}_background`]: true, active: isActive('worlds', world)}"
              class="world_button"
              @click="toggleFilter('worlds', world)"
          />
        </div>
      </div>

      <div class="difficulty_filter">
        <div
            class="difficulty_button_wrapper"
            :key="difficulty"
            v-for="difficulty in Array.range(3, 1)"
            @click="toggleFilter('difficulty', difficulty)"
        >
          <img
              :class="{active: isActive('difficulty', difficulty)}"
              class="difficulty_button"
              :src="difficultyIcon(difficulty)"
              :alt="`difficuly_${difficulty}`"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'c-progress-filter',
  props: {
    value: {
      required: true,
      type: Array
    },
  },
  data: () => ({
    filter: {
      worlds: [1],
      difficulty: [1],
      isOpen: false,
    },
  }),
  mounted() {
    this.loadProgress()
  },
  methods: {
    isActive(type, index) {
      return !!this.filter[type]?.includes(index)
    },
    toggleFilter(type, payload) {
      const indexOf = this.filter[type]?.indexOf(payload);
      if (indexOf >= 0) {
        this.filter[type]?.splice(indexOf, 1);
      } else {
        this.filter[type]?.push(payload);
      }
      this.loadProgress();
    },
    loadProgress() {
      const progress = this.progress.filter(e => {
        const world = this.filter.worlds.includes(e.phase_id)
        const difficulty = this.filter.difficulty.includes(e.difficulty)

        return world && difficulty;
      })
      progress.map((e, index) => e.id = index + 1);
      this.$emit('input', progress)
    },
  },
  computed: {
    ...mapGetters({
      progress: "user/getProgress",
      exerciseIcon: "world/getExerciseIconSource",
      difficultyIcon: "exercise/getDifficultyIcon",
    }),
  },
  watch: {
    progress(newValue) {
      if (newValue.length > 0) {
        this.loadProgress()
      }
    }
  },
}
</script>

<style scoped lang="scss">
.progress_filter_wrapper {
  position: absolute;
  bottom: -340px;
  left: 20px;
  right: 20px;
  overflow: hidden;
  height: 400px;
  transition: .5s;

  &.open {
    bottom: -20px;
  }
}

.progress_filter {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  bottom: -20px;
  background: #006c49;
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 10px;
  overflow: hidden;
  height: 320px;
}

.progress_filter_button {
  display: block;
  background: #006c49;
  //background: red;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: white;
  font-weight: bold;
  padding: 10px 20px 10px 20px;
  align-items: center;
  width: 320px;
  text-align: center;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  pointer-events: all;
  user-select: none;
  cursor: pointer;
  bottom: -20px;
}

.world_filter, .difficulty_filter {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin: 20px 0;
}

.world_button_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  aspect-ratio: 180/121;
}

.world_button {
  border-radius: 50px;
  height: 100%;
  width: 100%;
}

.difficulty_button {
  border-radius: 50px;
  background: #016D47;
  padding: 10px;
}

.difficulty_button:hover, .world_button:hover {
  cursor: pointer;
}

.active {
  outline: #FFD901 4px solid;
  transform: scale(1.02);
}

h1 {
  margin: -40px 0 -20px 10px;
  padding: 15px 5px 6px 5px;
  width: 65%;
  transform: rotate(-2deg);
  text-align: center;
  background: #FFD900;
  background: url('../../assets/titelbalk.png') no-repeat;
  background-size: cover;
  color: #016D47;
  z-index: 9999;
  image-rendering: auto;
}

.filter-text {
  margin: auto;
  display: block;
  color: white;
}

.world_1_background,
.world_2_background,
.world_3_background,
.world_4_background,
{
  background-size: contain;
  background-repeat: no-repeat;
}
</style>