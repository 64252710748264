<template>
  <div class="story-list-container">
    <h3 class="description" v-html="exercise.after_complete_text">
    </h3>

    <h4>{{ listHeader }}</h4>
    <div class="story-list">
      <h4 v-for="(detail, id) in results" :key="id">
        {{ getStory(detail) }}

        <span v-if="detail.cheats_used">
              <font-awesome-icon
                  class="side-icon"
                  icon="eye"
                  color="grey"
              />
        </span>
        <span v-if="detail.hints_used">
              <font-awesome-icon
                  class="side-icon"
                  icon="question"
                  color="grey"
              />
        </span>
      </h4>
    </div>

    <COutroButtonContainer/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import COutroButtonContainer from "@/components/Exercise/Outro/OutroButtonContainer";

export default {
  name: "c-word-list",
  components: {COutroButtonContainer},
  methods: {
    getStory(detail) {
      if (this.isTemplate("Zinnen2") || this.isTemplate("Zinnen1")) {
        return detail.last_answer?.sentence
      }
      if (this.isTemplate("Verhaal")) {
        return detail.last_answer?.story
      }

      return null;
    },
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      getResults: "exercise/getCorrectResults",
      template: "template/getCurrentTemplate",
      isTemplate: "template/isTemplate",
    }),
    listHeader() {
      if (this.isTemplate("Zinnen2") || this.isTemplate("Zinnen1")) {
        return "Je hebt de volgende zinnetjes geschreven:"
      }
      if (this.isTemplate("Verhaal")) {
        return "Je hebt het volgend verhaal geschreven:"
      }

      return null;
    },
    results() {
      if (this.isTemplate("Verhaal")) {
        return [this.getResults[0]]
      }

      return this.getResults
    }
  }
};
</script>

<style scoped lang="scss">
.story-list-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.description {
  margin: -20px 0 20px;
}

.story-list {
  overflow: auto;
  max-height: 330px;
  padding: 0 15px;

  h4 {
    margin: 15px 0;
    font-family: Quicksand, serif !important;
    line-height: 1.5em;
    color: #70706f;
    font-weight: 700;
  }
}

</style>