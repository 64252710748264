import { Model } from "@/models/Model";

export default class ExerciseType extends Model {
  constructor({
    name,
    id,
    description,
    category,
    category_label,
    after_complete_text,
    can_be_repeated,
    num_test_words,
    dictation_num_repeated_words,
    num_words,
    is_dictation,
    template,
  }) {
    super();
    this._name = name;
    this._id = id;
    this._description = description;
    this._category = category;
    this._category_label = category_label;
    this._after_complete_text = after_complete_text;
    this._can_be_repeated = can_be_repeated;
    this._num_test_words = num_test_words;
    this._dictation_num_repeated_words = dictation_num_repeated_words;
    this._num_words = num_words;
    this._is_dictation = is_dictation;
    this._template = template;
  }

  get name() {
    return this._name;
  }

  get id() {
    return this._id;
  }

  get description() {
    return this._description;
  }

  get category() {
    return this._category;
  }

  get category_label() {
    return this._category_label;
  }

  get after_complete_text() {
    return this._after_complete_text;
  }

  get can_be_repeated() {
    return this._can_be_repeated;
  }

  get num_test_words() {
    return this._num_test_words;
  }

  get dictation_num_repeated_words() {
    return this._dictation_num_repeated_words;
  }

  get num_words() {
    return this._num_words;
  }

  get is_dictation() {
    return this._is_dictation;
  }

  get template() {
    return this._template;
  }
}
