<template>
  <div class="coinsbackdrop" v-if="this.showCoinsPopup">
    <div class="coinspopup">
      <img
          alt="sluiten"
          src="../assets/close-modal.png"
          @click="closeCoinsPopup"
          aria-label="Close modal"
          class="btn-coins-close"
      />
      <h3 v-if="this.chosenSentence !== null">
        {{ this.chosenSentence }}
      </h3>

      <img
          v-if="this.chosenImgNumber === 0"
          src="../assets/coinspopup-0.png"
          alt="coinspopup-0"
      />
      <img
          v-if="this.chosenImgNumber === 1"
          src="../assets/coinspopup-1.png"
          alt="coinspopup-1"
      />
      <img
          v-if="this.chosenImgNumber === 2"
          src="../assets/coinspopup-2.png"
          alt="coinspopup-2"
      />
      <img
          v-if="this.chosenImgNumber === 3"
          src="../assets/coinspopup-3.png"
          alt="coinspopup-3"
      />
      <img
          v-if="this.chosenImgNumber === 4"
          src="../assets/coinspopup-4.png"
          alt="coinspopup-4"
      />

      <h3 class="coinsPoints">{{ profile.student.points }}</h3>
      <v-btn
          @click="$router.push({ name: 'shop' })"
          color="green"
          class="coinsBtn"
      >Ga naar de shop
      </v-btn>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'c-coins-backdrop',
  data: () => ({
    showCoinsPopup: false,
    coinsSentences: [
      "Heb jij al voldoende punten voor een volgende badge?",
      "Wat kun je met je behaalde punten doen?",
    ],
    chosenSentence: "",
    chosenImgNumber: "",
  }),
  mounted() {
    if (localStorage.getItem("coinsPopup")) {
      this.showCoinsPopup = true;
      setTimeout(() => {
        localStorage.removeItem("coinsPopup");
        this.showCoinsPopup = false;
      }, 5000);
    }
    this.pickSentence();
    this.pickImage();
  },
  computed: {
    ...mapGetters({
      profile: "user/getProfile"
    })
  },
  methods: {
    closeCoinsPopup() {
      this.showCoinsPopup = false;
      localStorage.removeItem("coinsPopup", false);
    },
    pickSentence() {
      const chosenNumber = Math.floor(
          Math.random() * this.coinsSentences.length
      );
      this.chosenSentence = this.coinsSentences[chosenNumber];
    },
    pickImage() {
      this.chosenImgNumber = Math.floor(Math.random() * 5);
    },
  }
}
</script>

<style scoped lang="scss">
.coinsbackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.coinspopup {
  overflow-x: auto;
  z-index: 99;
  background: #fff;
  border-radius: 25px;
  border: 2px solid green;
  width: 450px;
  height: 500px;
  right: 0;
  bottom: 0;
  padding: 50px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  align-items: center;
}

.coinspopup img {
  max-height: 200px;
  margin: 20px 0 20px 0;
}

h3.coinsPoints:before {
  background-image: url("../assets/xp.png");
  background-size: 23px 23px;
  display: inline-block;
  width: 23px;
  height: 23px;
  content: "";
  vertical-align: baseline;
}

.coinsBtn {
  position: absolute;
  bottom: 50px;
  margin: 0 auto;
}

.btn-coins-close {
  width: 50px;
  border: 50px;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
  z-index: 999;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0 !important;
}
</style>