<template>
  <component :is="getOutro"/>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'c-exercise-outro',
  computed: {
    ...mapGetters({
      getOutro: "template/getOutro",
    })
  }
}
</script>