<template>
  <div class="swiper" ref="swiper">
    <div class="swiper-wrapper">
      <div
          v-for="(item, slideIndex) in items"
          :key="slideIndex"
          class="swiper-slide"
      >
        <slot name="slide" :item="item"></slot>
      </div>
    </div>
    <slot name="prev">
      <div class="swiper-button-prev" ref="prev" />
    </slot>
    <slot name="next">
      <div class="swiper-button-next" ref="next" />
    </slot>
  </div>
</template>
<script>
import Swiper, {Navigation} from "swiper";
import 'swiper/swiper-bundle.css';

export default {
  name: 'Swiper',
  data: () => ({
    /** @var {Swiper} */
    swiper: null,
    id: null
  }),
  props: {
    items: {
      required: true
    },
    options: {
      type: Object,
      required: false,
    }
  },
  mounted() {
    this.initializeSwiper()
  },
  methods: {
    initializeSwiper() {
      this.swiper = new Swiper(this.$refs.swiper, {
        modules: [Navigation],
        navigation: {
          nextEl: this.$refs.next,
          prevEl: this.$refs.prev,
        },
        ...this.options,
      })

      this.registerSwiperEvents()
    },
    registerSwiperEvents() {
      this.swiper.on('slideChange', (e) => {
        this.$emit('change', e.activeIndex)
      });
    },
  },
  watch: {
    items() {
      this.swiper.destroy()
      this.initializeSwiper()
    }
  }
}
</script>

<style scoped lang="scss">
.swiper {
  background: white;
  border-radius: 25px;
  padding: 0 5%;
  user-select: none;
  height: 100%;

  .swiper-slide {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 0;
    height: 100%;
    width: 5%;
    margin: 0;
    right: 0;
    color: #70706f !important;
    opacity: 1;
    background: white;
    transition: background .2s;

    &:hover {
      background: rgb(236, 236, 236);
    }

    &.swiper-button-disabled:after {
      opacity: .35;
    }

    &:after {
      font-size: 2vw;
    }
  }
}
</style>