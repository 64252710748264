<template>
  <div class="anchor-image-container">
    <swiper
        :items="getImages"
        :options="{
          slidesPerView: 1,
        }"
    >
      <template #slide="{ item }">
        <img :src="item" class="anchor-image" alt="anchor-image"/>
      </template>
    </swiper>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Swiper from "@/components/Lespagina/Swiper.vue";

export default {
  name: "CAnchorImageSlider",
  components: {Swiper},
  props: {
    items: {
      type: Array,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      anchorImages: "lessonPage/getAnchorImages",
    }),
    getImages() {
      if (this.items?.length > 0) return this.items

      return [1,2].map(() => "http://www.placekitten.com/300/300")
    }
  }
};
</script>

<style scoped lang="scss">
.anchor-image {
  height: 90%;
}
</style>
