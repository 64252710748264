<template>
  <transition name="passed-modal-fade">
    <div class="passed-modal-backdrop">
      <div class="passed-modal"
        role="dialog"
        aria-labelledby="passed-modalTitle"
        aria-describedby="passed-modalDescription"
      >
        <img src="../assets/close-modal.png" @click="close" aria-label="Close modal" class="btn-passed-close" />
        <header
          class="passed-modal-header"
          id="passed-modalTitle"
        >
          <slot name="header">
            <h1>Stap gehaald!</h1>
          </slot>
        </header>

        <section
          class="passed-modal-body"
          id="passed-modalDescription"
        >
          <slot name="body">
            <div class="toets-eind" v-if="profile">
              <div class="toets-eind-left">
                  <img 
                    :src="
                    'https://cdn.spellit.nl/buddies/' + 
                    profile.student.buddy_id +
                    '/celebration.gif'" style="max-height: 600px" />
                  <!-- <img src="../assets/buddy-passed-1.gif" /> -->
                </div>
                <div class="toets-eind-right">
                  <h3>Goed gedaan!</h3>
                  <p>Je hebt een stap goed afgerond!</p>
                  <p>Begin met je volgende stap in de jungle!</p>
              </div>     
            </div>     
          </slot>
        </section>

        <footer class="passed-modal-footer">
          <slot name="footer">
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";

  export default {
    name: 'PassedModal',
    methods: {
      close() {
        this.$emit('close');
      },
    },
    computed: {
      ...mapGetters({
        profile: "user/getProfile",
      }),
    }
  };
</script>

<style scoped>
  .passed-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .passed-modal {
    position: initial;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    z-index: 99;
    background: #F8F4E0;
    border-radius: 25px;
    border: 2px solid green;
    margin-bottom: 0px;
    width: 1240px;
    height: 630px;
  }

  h1 {
    position: absolute;
    margin: -40px 0 -20px 10px;
    padding: 15px 5px 6px 5px;
    width: 65%;
    transform: rotate(-2deg);
    text-align: center;
    background: #FFD900;
    background: url('../assets/titelbalk.png') no-repeat;
    background-size: cover;
    color: #016D47;
    z-index: 9999;
  }

  .passed-modal-header,
  .passed-modal-footer {
    display: flex;
    z-index: 999;
  }

  .passed-modal-header {
    position: absolute;
    width: 600px;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .passed-modal-body {
    z-index: 999;
    width: 1040px;
    height: auto;
    position: relative;
    padding: 0px;
    margin: 70px auto;
    overflow: hidden;
  }

  .passed-modal-footer {
    width: 600px;
    margin: 0 auto;
    flex-direction: column;
  }

  .btn-passed-close {
    margin-left: 1175px;
    margin-top: 2px;
    width: 62px;
    border: 62px;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
    z-index: 999;
    position: absolute;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border-radius: 2px;
  }

  .passed-modal-fade-enter,
  .passed-modal-fade-leave-to {
    opacity: 0;
  }

  .passed-modal-fade-enter-active,
  .passed-modal-fade-leave-active {
    transition: opacity .5s ease;
  }

.toets {
  display: flex;
  width: 70%;
  margin: 0 auto;
  height: 300px;
}

.toets-left {
  width: 66%;
  background: url("../assets/test-bg.png") no-repeat;
  background-size: contain;
  background-position: center;
  height: 390px;
}

.toets-eind {
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-height: 500px;
  overflow: hidden;
}

.toets-eind-left {
  width: 40%;
}

.toets-eind-right {
  width: 60%;
  background: url("../assets/Tekstkader.png") no-repeat;
  background-size: contain;
  background-position: center;
  height: 390px;
}

  .toets-eind-right p,
  .toets-eind-right h3 {
    margin: 30px 0 0 0;
    padding: 0px 100px 0px 120px;
    color: #016d47;
  }

  .toets-eind-right h3  {
    margin-top: 50px;
  }
</style>