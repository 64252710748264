<template>
  <div class="test-outro" v-if="loaded">
    <div style="display: flex;" v-if="isStepEndOfWorld">
      <div class="test-outro-message-container">
        <h2>Fantastisch!</h2>
        <p>Je hebt alle stappen in dit level gehaald.
          Vlieg met Kiko mee naar het volgende level!</p>
        <v-btn v-if="isPassed && !studentExercise.isForcedRepeat()" class="green test-button" tile @click.stop="goToNextStep">
          Ga door naar het volgende level
        </v-btn>
        <v-btn v-else class="green test-button" tile @click.stop="resetExercise()">
          Herhaal de toets
        </v-btn>
      </div>

      <div style="width: 50%;">
        <img
            style="max-width: 100%;"
            src="@/assets/kiki-flying2.gif"
            alt="kiko Flying"
        />
      </div>
    </div>
    <div v-else>
      <div class="test-outro-message-container">
        <h2>{{ messageHeader }}</h2>
        <p>{{ message }}</p>
        <v-btn v-if="isPassed && !studentExercise.isForcedRepeat()" class="green test-button" tile @click.stop="goToNextStep">
          Ga naar volgende stap
        </v-btn>
        <v-btn v-else class="green test-button" tile @click.stop="resetExercise()">
          Herhaal de toets
        </v-btn>
      </div>

      <div class="test-outro-kiko-container">
        <img
            src="@/assets/kiko.png"
            alt="kiko"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'c-step-test-outro',
  data: () => ({
    loaded: false,
    isStepEndOfWorld: false,
  }),
  methods: {
    ...mapActions({
      nextStep: "exercise/nextStep",
      fetchSteps: "world/fetchSteps",
      resetExercise: "exercise/resetExercise",
    }),
    goToNextStep() {
      if (this.getStep?.is_repeatable) {
        this.$router.push({name: "treehouse"})
        return;
      }

      this.fetchSteps().then(() => {
        // get steps by world
        const currentWorldSteps = this.steps(this.activeWorld);

        // get index of current step
        const currentStepIndex = currentWorldSteps.findIndex(e => {
          return e.id === this.getStepID
        })

        if (currentStepIndex === -1) {
          console.error("Failed to find current step")

          return this.$router.push({
            name: "world", params: {
              world_id: this.activeWorld,
            }
          })
        }

        const nextStepIndex = currentStepIndex + 1;

        // if step is at end of world
        if (nextStepIndex === currentWorldSteps.length) {
          if (this.activeWorld === 4) {
            // if on last world go to end cave
            return this.$router.push({name: "eindgrot"})
          } else {
            // go to next world
            return this.$router.push({
              name: "world", params: {
                world_id: this.activeWorld + 1,
              }
            })
          }
        }

        // if there is a next step
        const nextStep = currentWorldSteps[nextStepIndex];
        if (nextStep) {
          // if next index is test, push to test page
          const nextIsTest = nextStep.status === 7

          if (nextIsTest) {
            return this.$router.push({
              name: "test", params: {
                test_id: nextStep.test_id,
                stap_id: nextStep.id
              }
            })
          }

          // else push to lesson page
          return this.$router.push({
            name: "lesson_page", params: {
              id: this.$route.params.id,
              stap_id: nextStep.id
            }
          })
        }

        console.error("Failed to find navigate")

        return this.$router.push({
          name: "world", params: {
            world_id: this.activeWorld,
          }
        })
      })
    }
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      studentExercise: "exercise/getCurrentStudentExercise",
      test: "exercise/getTest",
      profile: "user/getProfile",
      screenName: "user/getScreenName",
      createBuddyImage: "user/createBuddyImage",
      linkToWorld: "router/linkToWorld",
      steps: "world/getSteps",
      steps2: "world/getSteps2",
      getStepID: "router/getStepID",
      step: "lessonPage/getStep",
      activeWorld: "world/getActiveWorld",
      getStep: "lessonPage/getStep",
    }),
    isPassed() {
      if (!this.studentExercise || !this.exercise) return false;
      console.log('studentExercise.percentage_score', this.studentExercise.percentage_score);
      console.log('studentExercise.difficulty', this.studentExercise.difficulty);
      console.log('min_success_score_difficulty_' + this.studentExercise.difficulty, this.exercise['min_success_score_difficulty_' + this.studentExercise.difficulty]);
      const passed = this.studentExercise.percentage_score && this.studentExercise.percentage_score >= this.exercise['min_success_score_difficulty_' + this.studentExercise.difficulty];
      console.log('isPassed', passed);
      return passed;
    },
    description() {
      return this.exercise?.description ?? this.test?.label;
    },
    messageHeader() {
      if (this.isPassed) {
        return 'Gehaald!'
      }

      return 'Bijna!'
    },
    message() {
      let message = `Je hebt ${this.studentExercise.percentage_score}% van de woorden goed getypt, `
      if (this.isPassed) {
        message += 'je mag door naar de volgende stap.'
        if (this.step.is_repeatable) {
          message += "Of ga terug naar de boomhut en doe nog meer herhaalstappen."
        }
      } else {
        message += 'herhaal oefeningen en doe het stapdictee nog een keer.'
      }

      return message
    }
  },
  mounted() {
    this.fetchSteps().then(() => {
      // get steps by world
      const currentWorldSteps = this.steps(this.activeWorld);

      // get index of current step
      const currentStepIndex = currentWorldSteps.findIndex(e => {
        return e.id === this.getStepID
      })

      if (currentStepIndex === -1) {
        console.error("Failed to find current step")

        return this.$router.push({
          name: "world", params: {
            world_id: this.activeWorld,
          }
        })
      }

      const nextStepIndex = currentStepIndex + 1;

      // if step is at end of world
      if (nextStepIndex === currentWorldSteps.length) {
        if (this.activeWorld !== 4) {
          this.isStepEndOfWorld = true;
        }
      }
      this.loaded = true;
    });
  }
}
</script>

<style scoped lang="scss">
</style>