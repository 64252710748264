<template>
  <div
      class="world"
      @click="activateWorld"
  >
    <transition name="index">
      <div class="active_world_wrapper" v-if="active" :key="$route.name">
        <transition name="fade">
          <go-back v-if="active && !isInitializing" :expect-event="true" @click.stop="goBack()"/>
        </transition>

        <PassedModal
            v-if="false"
            @close="closePassedModal"
        ></PassedModal>

        <transition name="fade">
          <div v-if="activeSteps.length > 0">
            <div
                class="step-wrapper faded-overlay"
                v-if="(!isInitializing || unloading) && active && isUnlocked(number)"
            >
              <div
                  v-for="(step, index) in activeSteps"
                  :id="'opdracht_' + step.position"
                  :key="`position_${index}`"
                  ref="position"
                  :data-disabled="step.status === -1"
                  :style="getPosition(step)"
                  class="opdracht"
              >
                <transition name="pop-in">
                  <img
                      @click="isTest(step, step.status === -1)"
                      :src="iconSource(step)"
                      :alt="step.status"
                      v-if="shouldBeLoaded(step.position) && iconSource(step)"
                      :key="step.position"
                      :class="{'current-step': isLastStep(index)}"
                  />
                </transition>
                <transition name="pop-in">
                  <span
                      @click="setAnchorStep(step)"
                      :class="{active: activeAnchorStep && activeAnchorStep.id === step.id, disabled: !hasAnchorSteps(step)}"
                      class="anchor"
                      v-if="shouldBeLoaded(step.position) && iconSource(step) && step.status !== -1"
                  >
                  <img
                      src="../../assets/img/world/step-anchor-without-anchor.png"
                      alt="anchor"
                  />
                    <p>{{step.position}}</p>
                  </span>
                </transition>
              </div>
            </div>

            <div
                :key="active"
                class="end-cave"
                v-if="number === 4 && active && !isInitializing && student.end_reward_4_collected"
                @click="$router.push({path: '/eindgrot'})"
            >
              <img src="../../assets/img/end-cave/end_cave_world_cave.png" alt="end-cave">
            </div>
          </div>
          <div v-else-if="active && (!isInitializing)">
            <c-overlay>
              <bubble>
                <template #header>
                  <h2>Er zijn nog geen stappen!</h2>
                </template>
                <template #body>Jouw begeleider heeft nog geen stappen voor jou klaargezet.
                </template>
                <template #footer>
                  <c-button @click="$router.push('/')">Terug naar het overzicht
                  </c-button>
                </template>
              </bubble>
            </c-overlay>
          </div>
        </transition>


        <transition name="fade">
          <c-overlay v-if="firstTimeVisitingWorld4">
              <bubble :key="firstTimeVisitingWorld4">
                <template #header>
                  <h2>Welkom in wereld 4</h2>
                </template>
                <template #body>
                  In dit level oefen je met weetwoorden en leenwoorden. Met welke stap begin jij?
                </template>
                <template #footer>
                  <c-button @click="visitedWorld4">Naar de stappen</c-button>
                </template>
              </bubble>
          </c-overlay>
        </transition>

        <transition name="pop-in">
          <bubble
              :key="activeAnchorStepId"
              v-if="activeAnchorStep && !isVisible"
              class="anchor-bubble"
              :ref="`bubble`"
              :closeable="true"
              @close="setAnchorStep(null)"
          >
            <template #header>
              <p>Ankerwoorden in deze stap</p>
            </template>
            <template #body>
              <c-anchor-image-slider :items="activeAnchorStep.anchors.map(e => e.image_url)"/>
            </template>
          </bubble>
        </transition>
        <transition name="fade">
          <help-overlay-button v-if="active && !isInitializing && number === 1"/>
        </transition>

        <CContentModal class="klankenkaart-modal" v-model="achievedModalVisible" @close="achievedModalVisible = false">
          <template #body>
            <div style="display: flex; width: 50vw; z-index: 10;">
              <div style="width: 50%; display: flex; align-items: center;">
                <div>
                <h2>Behaald</h2>
                <p>Je hebt de stappentoetsen behaald op niveau *.<br>Wil je dit Level ook nog op 2 of 3 sterren behalen?<br>Kies de stappen die je nog wilt oefenen.<br>Je kunt ook door naar het volgende level</p>
                </div>
              </div>
              <div style="width: 50%;">
                <img
                    style="max-height: 90%;"
                    src="@/assets/compass.svg"
                    alt="kiko Flying"
                />
              </div>
            </div>
          </template>
        </CContentModal>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import GoBack from "@/components/GoBack";
import PassedModal from "@/components/PassedModal";
import COverlay from "@/components/Base/Overlay";
import Bubble from "@/components/Base/Bubble";
import CButton from "@/components/Base/Button";
import HelpOverlayButton from "@/components/Base/HelpOverlayButton";
import CAnchorImageSlider from "@/components/Lespagina/AnchorImageSlider";
import CContentModal from "@/components/Modals/ContentModal.vue";

export default {
  name: "c-world",
  props: {
    number: {
      required: true,
      type: Number
    }
  },
  components: {CContentModal, CAnchorImageSlider, HelpOverlayButton, CButton, Bubble, COverlay, GoBack, PassedModal},
  data: () => ({
    loadedSteps: [],
    animationDone: true,
    loadTimeouts: [],
    unloadTimeouts: [],
    currentStepIndex: null,
    unloading: false,
    activeAnchorStep: null,
    firstTimeVisitingWorld4: false,
    achievedModalVisible: false,
    allStatusesAre5: null,
  }),
  mounted() {
    if (this.number === 4 && !localStorage.getItem('first_visit_world_4')) {
      this.firstTimeVisitingWorld4 = true;
    }
    if (this.active) {
      this.loadSteps()
    }

    document.addEventListener('click', (event) => {
      let containsAnchor = false
      let containsBubble = false

      event.composedPath().forEach(e => {
        if (e?.classList?.contains('anchor')) {
          containsAnchor = true
        }
        if (e?.classList?.contains('anchor-bubble')) {
          containsBubble = true
        }
      })

      if (!containsAnchor && !containsBubble) {
        this.setAnchorStep(null)
      }
    })
  },
  methods: {
    ...mapMutations({
      setWorld: "world/SET_WORLD",
    }),
    ...mapActions({
      fetchSteps: "world/fetchSteps",
    }),
    isDisabled() {
      return !this.isIntroductionCompleted || !this.isUnlocked(this.number)
    },
    visitedWorld4() {
      localStorage.setItem('first_visit_world_4', "1")
      this.firstTimeVisitingWorld4 = false;
    },
    setAnchorStep(step) {
      // if (this.activeAnchorStep > 0) {
      //   const oldElement = this.$refs[`bubble_${this.activeAnchorPopup}`]?.[0]?.$el
      //   oldElement?.removeAttribute('style')
      // }

      if (step === null || !this.hasAnchorSteps(step) || step.id === this.activeAnchorStep?.id) {
        return this.activeAnchorStep = null
      }

      // const element = this.$refs[`bubble`][0].$el

      // if (this.activeAnchorStep.id === step.id) {
      //   element.removeAttribute('style')
      //   return this.activeAnchorPopup = null
      // }

      this.activeAnchorStep = step

      // let w = window.innerWidth;
      // let h = window.innerHeight;
      //
      // requestAnimationFrame(() => {
      //   const boundingRect = element.getBoundingClientRect()
      //   let x;
      //   let y;
      //   console.log({boundingRect, w, h});
      //
      //   if (boundingRect.x > w / 2) {
      //     x = '-84%'
      //   } else {
      //     x = '26%'
      //   }
      //
      //   console.log(boundingRect);
      //
      //   if (boundingRect.y - 350 > h / 2) {
      //     y = '-103%'
      //   } else {
      //     y = '9%'
      //   }
      //
      //
      //   // element.style.transform = `translate(${x}, ${y})`
      // })
      //
      // requestAnimationFrame(() => {
      //   this.showAnchorPopup = true
      // })


    },
    activateWorld() {
      if (!this.active && !this.isInitializing && !this.isDisabled()) {
        this.animationDone = false;
        this.setWorld(this.number)
        this.$router.push({path: '/wereld/' + this.number})
        if (this.allStatusesAre5) {
          setTimeout(() => {
            console.log("this.achievedModalVisible = true");
            this.achievedModalVisible = true;
          }, 2800);
        }
      }
    },
    goBack() {
      this.unloadSteps().finally(() => {
        this.loadedSteps = []
        this.animationDone = false;
        this.$router.push({name: "world_overview"});
        this.setWorld(null);
      })
    },
    isTest(step, disabled) {
      if (disabled) return;
      this.unloadSteps()

      if (
          step.test_required === true &&
          step.can_start_test === true &&
          step.test_complete !== true && step.status !== -1
      ) {
        this.$router.push({
          name: "test",
          params: {stap_id: step.id, test_id: step.test_id},
        });
      } else if ((step.test_complete === true || step.test_required === false) && step.status !== -1) {
        this.$router.push({
          name: "lesson_page",
          params: {id: 1, stap_id: step.id},
        });
      }

    },
    getPosition(step) {
      const position = this.positions[step.position - 1];

      return `left: ${position?.x}%; top: ${position?.y}%;`;
    },
    loadSteps() {
      let allStatusesAre5 = true;
      this.clearTimeouts()
      this.loadedSteps = [];
      // this.loadedSteps = this.activeSteps
      let currentTestIndex;
      this.activeSteps.forEach((e, index) => {
        if (e.status !== 5 ) {
          allStatusesAre5 = false;
        }


        if (currentTestIndex >= 0 && index > currentTestIndex) {
          return;
        }

        // dont show after test completed
        if (!e.test_complete && e.test_required && e.id === e.test_id && !this.isVisible) {
          currentTestIndex = index;
          this.currentStepIndex = index;
          this.loadedSteps.push(e)
          return;
        }

        this.loadTimeouts.push(setTimeout(() => {
          this.loadedSteps.push(e)
          if (e.status !== -1 && e.status !== 4 && e.status !== 5 && this.currentStepIndex === null) {
            this.currentStepIndex = index
          }
        }, index * 100))
      })
      this.allStatusesAre5 = allStatusesAre5;
    },
    unloadSteps() {
      this.unloading = true;
      this.clearTimeouts()
      const steps = [...this.loadedSteps]
      return new Promise((resolve) => {
        if (steps.length === 0) resolve();
        steps.forEach((e, index) => {
          this.unloadTimeouts.push(setTimeout(() => {
            this.loadedSteps.pop()
            if (this.loadedSteps.length === 0) {
              setTimeout(() => resolve(), 200)
            }
          }, index * 50))
        })
      }).then(() => this.unloading = false)
    },
    clearTimeouts() {
      this.loadTimeouts.forEach((e) => clearTimeout(e))
      this.loadTimeouts = [];
      this.unloadTimeouts.forEach((e) => clearTimeout(e))
      this.unloadTimeouts = [];
    },
    showPassedModal() {
      this.isPassedModal = true;
    },
    closePassedModal() {
      this.isPassedModal = false;
    },
    toggleLegend() {
      this.legendActive = !this.legendActive;
    },
    isLastStep(index) {
      return this.currentStepIndex === index && !this.isUnlocked(this.number, true) && !this.isVisible
    },
    shouldBeLoaded(position) {
      return this.loadedSteps.find((f) => {
        return f.position === position
      }) || this.isVisible === true
    },
    hasAnchorSteps(step) {
      return step?.anchors?.length > 0
    },
  },
  computed: {
    ...mapState({
      user: 'user',
    }),
    ...mapGetters({
      getSteps: "world/getSteps",
      isActive: "world/isActive",
      iconSource: "world/getExerciseIconSource",
      isUnlocked: "world/isUnlocked",
      isIntroductionCompleted: "user/isIntroductionCompleted",
      positions: "world/getPositions",
      isInitializing: "isInitializing",
      student: "user/getStudent",
    }),
    isVisible() {
      return this.$store.state.helpOverlay.isVisible
    },
    active() {
      return this.isActive(this.number)
    },
    activeSteps() {
      return this.getSteps(this.number);
    },
    activeAnchorStepId() {
      return this.activeAnchorStep ? this.activeAnchorStep.id : null
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'world' && this.number === parseInt(this.$route.params.world_id)) {
        this.setWorld(this.number)
      }
    },
    activeSteps(newValue, oldValue) {
      if (newValue.length > 0 && oldValue.length === 0 && this.active) {
        this.loadSteps()
      }
    },
    active() {
      this.activeAnchorPopup = null
      if (this.active) {
        this.loadSteps()
      }
    },
    isVisible() {
      this.loadSteps()
    },
  }
};
</script>

<style scoped lang="scss">
.world {
  display: block;
  overflow: hidden;
  //height: 484px;
  //width: 720px;
  height: 100%;
  width: 100%;
  transition: all 1s;
  position: relative;

  .back-btn {
    top: 20px;
    left: 20px;
  }
}

.step-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.opdracht:not([data-disabled]) {
  cursor: pointer;
}

.opdracht:not([data-disabled]) img:hover {
  transform: scale(1.1);
}

.opdracht:not([data-disabled]) .active {
  transform: scale(1.1);
  z-index: 3;

  &:after {
    content: "";
    animation: flashing-step linear infinite 2s;
    border-radius: 50px;
    width: 33px;
    height: 33px;
    z-index: 1;
    left: 0;
    top: 0;
    position: absolute;
  }
}

.opdracht:not([data-disabled]) .anchor.active {
  transform: translateX(-50%) scale(1.1);
}

.opdracht {
  width: 4%;
  aspect-ratio: 1/1;

  position: absolute;
  border-radius: 50px;

  img {
    margin: -2px;
    width: 100%;

    &.current-step {
      animation: flashing-step linear infinite 2s;
      border-radius: 50px;

      img {
        width: 100%;
        border-radius: 50px;
      }
    }
  }
}

.end-cave {
  z-index: 5;
  position: absolute;
  left: 48.05%;
  top: 35.43%;
  height: 400px;
  width: 400px;
  transition: all 1s;
  animation: pulse 2s ease-in-out 0s infinite;

  img {
    cursor: pointer;
    height: 400px;
    width: 400px;
    transition: all 1s;
    filter: drop-shadow(13px 0px 22px #000000);
  }
}

.help {
  z-index: 103;
}

.anchor {
  position: absolute;
  left: 50%;
  top: 100%;
  width: 70%;
  transform: translateX(-50%);

  &.disabled {
    pointer-events: none;
    filter: grayscale(0.7);
  }

  img {
    z-index: 2;
    position: relative;
    width: 100%;
  }

  p {
    position: absolute;
    top: 47%;
    left: 50%;
    margin: 0;
    color: white;
    z-index: 2;
    transform: translate(-50%, -50%);
  }
}

.anchor-bubble {
  position: absolute;
  transform-origin: center;
  user-select: none;
  top: calc(50% - 150px);
  //transform: translate(-50%, -50%);
  left: calc(50% - 175px);
  padding: 15px;
  width: 350px;
  height: 300px;
  z-index: 95;
  align-content: center;
  align-items: center;

  p {
    margin: 0;
  }

  .anchor-image-container {
    margin-right: 0;
    width: 300px !important;
    height: 200px !important;
  }

  ::v-deep.anchorimage-slide {
    width: 300px !important;
    height: 200px !important;
  }
}

</style>
