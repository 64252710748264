<template>
  <div class="legend-wrapper" :class="{active: legendActive || helpOverlayIsVisible}">
    <div :class="{ legend: true }">
      <div class="legend-item-list flex-center">
        <div class="legend-item" :key="index" v-for="(item, index) in legendItems">
          <img
              :src="item.src"
              :alt="item.text.toLowerCase()"
          />
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
    <div  class="legend-btn-wrapper">
      <div @click="toggleLegend" class="legend-btn flex-center">
        <p>Uitleg oefeningen</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CLessonPageLegend",
  data: () => ({
    legendActive: false,
    legendItems: [
      {src: require('@/assets/isoleren-btn.png'), text: 'Verkennen'},
      {src: require('@/assets/icon-exercise.png'), text: 'Oefenen'},
      {src: require('@/assets/compass-icon.png'), text: 'Dictee'}
    ],
  }),
  computed: {
    helpOverlayIsVisible() {
      if (this.$store.state.helpOverlay.isVisible) {
        return this.$store.state.helpOverlay.step === 2
      }
      return false;
    }
  },
  methods: {
    toggleLegend() {
      this.legendActive = !this.legendActive;
    }
  },
};
</script>

<style scoped lang="scss">
.legend-wrapper {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 95;
  user-select: none;
  transform-origin: top right;
  //overflow: hidden;
  height: 50%;
  width: 25%;
  transform: translate(100%, -50%);
  transition: transform .2s;

  &.active {
    transform: translate(0, -50%);
  }

  p {
    color: white;
    font-size: 1.3vw;
  }
}

.faded {
  .legend-wrapper.active {
    z-index: 98;
  }
}

.legend {
  padding: 10px;
  width: 100%;
  height: 100%;
  //margin-top: -350px;
  background: #006c49;
  flex-wrap: wrap;
  justify-content: space-between;
  //margin-left: 50%;
  //transform: translateX(-50%);
  transition: margin 1s, border-width 1s, box-shadow 1s;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  border-left: 2px solid white;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}

.legend-btn-wrapper {
  width: 18%;
  height: 100%;
  position: absolute;
  left: calc(-18% + 2px);
  top: 0;

  .legend-btn {
    background: #006c49;
    border-bottom-left-radius: 1vw;
    border-top-left-radius: 1vw;
    color: white;
    font-weight: bold;
    align-items: center;
    height: 50%;
    width: 100%;
    top: 50%;
    left: 0;
    position: absolute;
    transform: translateY(-50%);
    pointer-events: all;
    cursor: pointer;
    border: 1px solid white;
    border-right: 0;


    & p {
      rotate: 90deg;
      font-size: max(12px, 0.8vw);
    }
    //&:after {
    //  content: "";
    //  position: absolute;
    //  z-index: -1;
    //  top: 0;
    //  right: 0;
    //  bottom: 0;
    //  left: 0;
    //  border-left: 2px solid white;
    //  border-right: 2px solid white;
    //  border-bottom: 2px solid white;
    //  border-bottom-left-radius: 15px;
    //  border-bottom-right-radius: 15px;
    //}
  }
}

.legend-content {
  transform: rotate(-90deg);
  margin: -5px;
  position: relative;
  top: 0;
  z-index: 1;
}

.legend-item-list {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;

  .legend-item {
    display: flex;
    height: 33%;
    width: 80%;
    gap: 10%;
    align-items: center;
    color: white;
    font-weight: bold;
    justify-content: flex-start;

    > * {
      margin: 0;
    }

    img {
      height: 80%;
      aspect-ratio: 1/1;
    }
  }
}
</style>
