<template>
  <div class="exercise-intro">
    <h3 class="exercise-description">{{ description }}</h3>

    <div class="exercise-word-list" v-if="showWordList">
      <h4 class="jungle-h4">Je gaat oefenen met de volgende woorden:</h4>
      <div class="intro-words">
        <p
            v-for="(word, id) in getWords"
            :key="id"
        >
          {{ word }}
        </p>
      </div>
    </div>

    <v-btn class="green exercise-button" @click="nextStep" tile> Begin!</v-btn>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'c-exercise-basic-intro',
  methods: {
    ...mapActions({
      nextStep: "exercise/nextStep"
    })
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      test: "exercise/getTest",
      isTest: "router/isTest",
      profile: "user/getProfile",
      getIntro: "template/getIntro",
      getWords: "exercise/getWords",
      isTemplate: "template/isTemplate",
    }),
    description() {
      if (this.isTest) {
        return this.test?.label
      }

      return this.exercise?.description;
    },
    showWordList() {
      return !this.isTemplate('StapDictee') && !this.isTemplate('OefenDictee');
    }
  }
}
</script>

<style scoped lang="scss">
.exercise-intro {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
  position: relative;
}

.exercise-word-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;

  h4 {
    color: #70706f;
    margin-bottom: 2%;
  }
}

.exercise-description {
  top: 20%;
  padding: 0 200px;
  text-align: center;
}

h3 {
  font-size: 30px;
  color: #016d47;
}
</style>