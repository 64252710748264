<template>
  <div class="story-input-container" :class="{'has-word': isTemplate('Zinnen1')}">
    <h3 class="description">{{ description }}</h3>

    <h2 class="word" v-if="hasHidden" :class="{visible}">{{ word }}</h2>

    <c-text-area class="story-input" v-model="currentInput" :hideable="hasHidden"/>
    <c-next-button v-model="currentInput" :disabled="disabled"/>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import CNextButton from "@/components/Exercise/Components/NextButton";
import CTextArea from "@/components/Exercise/Components/TextArea";

export default {
  name: "c-sentence-template",
  components: {CTextArea, CNextButton},
  methods: {
    ...mapMutations({
      setCurrentInput: "exercise/SET_CURRENT_INPUT",
      setWordVisibility: "exercise/SET_WORD_VISIBILITY",
    }),
  },
  computed: {
    ...mapGetters({
      word: "exercise/getWord",
      getCurrentInput: "exercise/getCurrentInput",
      description: "template/getDescription",
      hasHidden: "template/hasHidden",
      visible: "exercise/isWordVisible",
      isTemplate: "template/isTemplate",
      currentInputKey: "template/currentInputKey",
    }),
    disabled() {
      return !this.currentInput?.toLowerCase().includes(this.word.toLowerCase())
    },
    currentInput: {
      get() {
        return this.getCurrentInput?.[this.currentInputKey];
      },
      set(value) {
        this.setCurrentInput({[this.currentInputKey]: value, word: this.word});
      }
    },
  }
};
</script>

<style scoped lang="scss">
.story-input-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.has-word {
  margin-top: 0;
}

.visible {
  opacity: 1 !important;
}

.hide-button {
  margin: 40px 0 0;
}

.description {
}

.word {
  font-size: 30px;
  color: black;
  opacity: 0;
  transition: opacity .3s;
}
</style>