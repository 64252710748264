<template>
  <div class="jungle-content with-padding jungle-aspect-ratio">
    <Modal
        v-model="isModalVisible"
    >
      <template v-slot:body>
        <img class="flex-center introduction-video" src="../assets/img/world/video-wip.gif" alt="introduction-video">
        <p>Wij werken aan een introductievideo</p>
        <!--        Video!-->
        <!--        <youtube :video-id="videoId" class="first-video"></youtube>-->
      </template>
    </Modal>

    <v-form
        ref="form"
        @submit="updateStudentProfile($event)"
        class="profile-flexbox"
    >
      <ProfileForm v-model="updatedStudent" />
      <ProfileAvatarList v-model="updatedStudent" >
        <div class="button__wrapper">
          <v-btn
              class="no-margin btn-bekijk-video square-button green"
              @click="showModal"
              height="50"
              color="#fff"
              tile
              small
              rounded
          >
            Bekijk introductievideo
          </v-btn>
          <v-btn
              class="no-margin btn-save-profile square-button yellow"
              type="submit"
              height="50"
              color="#FFD801"
              tile
              small
              rounded
              @click.stop="updateStudentProfile"
          >
            Opslaan
          </v-btn>
        </div>
      </ProfileAvatarList>
    </v-form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Modal from "@/components/Modal";
import {Student} from "@/models/Student";
import ProfileForm from "@/components/Profile/ProfileForm";
import ProfileAvatarList from "@/components/Profile/ProfileAvatarList";

export default {
  name: "c-profile",
  components: {
    ProfileAvatarList,
    ProfileForm,
    Modal,
  },
  created() {
    this.updatedStudent = Student.createWriteable(this.student)
  },
  data: () => ({
    updatedStudent: null,
    isModalVisible: false,
    datePickerOpen: false,
    isEditNoteVisible: false,
    videoId: "dQw4w9WgXcQ",
  }),
  methods: {
    ...mapActions({
      "updateStudentProfileAction": "user/updateStudentProfile"
    }),
    updateStudentProfile(event) {
      event.preventDefault()
      event.stopPropagation()
      if (this.$refs.form.validate()) {
        this.updateStudentProfileAction(this.updatedStudent)
            .then(() => {
              if (!this.student.introduction_completed) {
                this.$router.push("/welcome");
              } else {
                this.$router.push("/");
              }
            });
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  watch: {
    dialog(visible) {
      this.datePickerOpen = !!visible;
    },
  },
  computed: {
    ...mapGetters({
      profile: "user/getProfile",
      dateOfBirthMoment: "user/getDateOfBirthMoment",
      student: "user/getStudent",
      groups: "user/getLevels",
      buddyId: "user/getBuddyId",
    }),
  },
};
</script>

<style scoped lang="scss">
.btn-save-profile {
  height: 50px;
  background-color: rgb(255, 216, 1);
  border-color: rgb(255, 216, 1);
}

.btn-save-profile:hover {
  transform: scale(1) rotate(5deg);
}

.btn-bekijk-video {
  height: 50px;
  background-color: rgb(255, 216, 1);
  border-color: rgb(255, 216, 1);
}

.button__wrapper {
  display: flex;
  gap: 10px;
  button {
  }
}

.profile-flexbox {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-content: center;
  height: 100%;
  padding: 20px 0;

  .profile-flexbox__column {
    flex: 1;
    gap: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    &:nth-child(2) {
      align-items: center;
    }
  }
}

</style>

<style lang="scss">

.v-list-item__title {
  color: #016d47;
  font-weight: bold;
}

.introduction-video {
  width: 100%;
  margin-top: -2px
}
</style>
