<template>
  <div class="loading-message-container">
    <h3 class="loading-message">{{loadingMessage}}{{loadingDots}}</h3>
  </div>
</template>
<script>
export default {
  name: 'c-loading',
  data: () => ({
    loadingInterval: null,
    loadingMessage: "Aan het laden",
    loadingDotsAmount: 3,
  }),
  created() {
    this.loadingInterval = setInterval(() => {
      this.loadingDotsAmount++;
      if (this.loadingDotsAmount === 4) {
        this.loadingDotsAmount = 0;
      }
    }, 500)
  },
  beforeDestroy() {
    clearInterval(this.loadingInterval)
  },
  computed: {
    loadingDots() {
      return Array.range(this.loadingDotsAmount, 0).reduce((acc) => acc + ".", "")
    }
  }
}
</script>

<style scoped lang="scss">
.loading-message-container {
  position: relative;
  height: 100%;
  width: 100%;

  .loading-message {
    position: absolute;
    top: 50%;
    left: 50%;  transform: translate(-50%, -50%);
    font-size: 30px;
    color: #016d47;
  }
}
</style>
