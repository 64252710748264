<template>
  <div class="jungle-content jungle-aspect-ratio">
      <img src="../../assets/Kiko-Ani.gif" class="kiko-gif" alt="kiko" />

      <img
        :src="
          'https://cdn.spellit.nl/buddies/' +
          profile.student.buddy_id +
          '/lantern.png'
        "
        alt="buddy-with-lantern"
        class="buddy-with-lantern"
      />

      <div class="text-bubble__container">
        <img
          src="../../assets/Tekstkader.png"
          alt="text-bubble"
          class="text-bubble__image"
        />
        <div class="text-bubble__field">
          <h1>Fantastisch!</h1>
          <br />
          <br />
          <p>
            Je bent klaar om de jungle in te gaan. Kom laten we snel beginnen.
          </p>
        </div>
      </div>

      <div class="continue-button__container" @click="goToJungle">
        <div class="continue-button__button"><p>Ga naar de jungle</p></div>
      </div>

      <img
        src="../../assets/img/profile/welcome-gate.png"
        alt="welcome-gate"
        class="welcome-gate"
      />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Student } from "@/models/Student";

export default {
  name: "Welcome",
  computed: {
    ...mapGetters({
      profile: "user/getProfile",
    }),
  },
  methods: {
    goToJungle() {
      let studentProfile = new Student(this.profile.student, null);
      studentProfile.introduction_completed = 1;
      this.$store
        .dispatch("user/updateStudentProfile", studentProfile)
        .then(() => this.$router.push("/"));
    },
  },
};
</script>

<style scoped lang="scss">
.welcome-gate {
  position: absolute;
  height: 95%;
  right: 1%;
}

.buddy-with-lantern {
  position: absolute;
  height: 70%;
  left: 20%;
  bottom: 0;
}

.text-bubble__container {
  position: absolute;
  left: 30%;
  top: 3%;
  width: 25%;
  aspect-ratio:  4/3;

  .text-bubble__image {
    height: 100%;
    aspect-ratio: 4/3;
  }

  .text-bubble__field {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    text-align: center;
    padding: 8% 10% 20% 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      color: #016d47;
      font-weight: bold;
      font-size: 2vmin;
    }

    p {
      font-weight: bold;
      font-size: 1.4vmin;
      margin: 0;
    }
  }
}

.continue-button__container {
  position: absolute;
  left: 60%;
  top: 20%;
  width: 10%;
  aspect-ratio: 1/1;
  z-index: 10;
  cursor: pointer;
  transition: transform .2s;

  &:hover {
    transform: scale(1.1);
  }

  .continue-button__button {
    border-radius: 50%;
    background: #03a708;
    border: 0.4vmin solid white;
    height: 100%;
    box-shadow: 0 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-weight: bold;
      font-size: 1.6vmin;
      margin: 0;
      color: white;
    }
  }
}

.kiko-gif {
  position: absolute;
  height: 40%;
  left: 0;
  z-index: 100;
}
</style>
