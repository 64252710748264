<template>
  <div class="content">
    <go-back></go-back>

    <div class="end-cave-avatars">
      <img alt="lantern" class="end-cave-lantern" :src="createBuddyImage('lantern')">
      <div class="end-cave-spider-web">
        <img alt="spider" class="end-cave-spider" src="@/assets/img/end-cave/spider.png">
        <img alt="web" class="end-cave-web" src="@/assets/img/end-cave/web.png">
        <img alt="string" class="end-cave-string" src="@/assets/img/end-cave/string.png">
      </div>

      <img alt="bat" class="end-cave-bat" src="@/assets/img/end-cave/bats.png">

      <kiko-flying class="end-cave-kiko"/>
    </div>

    <div class="diamond-drag-zone">
      <v-diamond
          class="diamond-drag-zone-diamond"
          v-for="(number, index) in draggableDiamonds" :key="index"
          :number="number"
          @put-it-in="putItIn"
          :is-filled="false"
      />
    </div>
    <div class="diamond-drop-zone">
      <v-diamond-hole
          v-for="(number, index) in diamonds" :key="index"
          :number="number"
          :index="index"
          :filled-by="filledObject[number]"
      />
    </div>
    <div class="letter-spawner-zone" v-if="isComplete">
      <v-letter-spawner/>
    </div>
  </div>
</template>

<script>
import VDiamond from "@/components/EndCave/Diamond";
import VDiamondHole from "@/components/EndCave/DiamondHole";
import {mapGetters} from "vuex";
import VLetterSpawner from "@/components/EndCave/LetterSpawner";
import GoBack from "@/components/GoBack";
import KikoFlying from "@/components/Animations/KikoFlying";

export default {
  name: "Home",
  components: {KikoFlying, GoBack, VLetterSpawner, VDiamondHole, VDiamond},
  data: () => ({
    profile: null,
    fullscreen: false,
    draggedElement: null,
    filledObject: {1: null, 2: null, 3: null, 4: null},
    diamonds: [1,2,3,4]
  }),
  methods: {
    putItIn(data) {
      if (data.targetIndex === this.winCode.indexOf(data.diamondNumber)) {
        this.$set(this.filledObject, data.targetIndex + 1, data.diamondNumber);
      }
    },
  },
  computed: {
    ...mapGetters({
      student: "user/getStudent",
      createBuddyImage: "user/createBuddyImage",
    }),
    draggableDiamonds() {
      return [1, 2, 3, 4].filter((f) => !Object.values(this.filledObject).some((e) => parseInt(e) === f));
    },
    winCode() {
      return this.student.end_reward_win_code;
    },
    isComplete() {
      return Object.values(this.filledObject).filter((e) => e !== null).length === 4
    }
  },
};
</script>

<style scoped lang="scss">
.end-cave-avatars {
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    position: absolute;
  }

  .end-cave-lantern {
    width: 35%;
    left: 15%;
    top: 20%;
  }
}

.letter-spawner-zone {
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  user-select: none;

  max-height: 968px;
  height: calc(100vh - 80px);
  aspect-ratio: 180/121;
}

.diamond-drag-zone {
  position: absolute;
  display: flex;
  bottom: 5%;
  right: 0;
  top: 80%;
  left: 0;
  width: 100%;
  user-select: none;
  overflow: hidden;
  justify-content: space-evenly;

  .diamond-drag-zone-diamond {
  }
}

.diamond-drop-zone {
  position: absolute;
  left: 54.5%;
  top: 31.3%;
  opacity: 1;
  aspect-ratio: 1/1;
  width: 23.5%;
}

.content {
  max-height: 968px;
  height: calc(100vh - 80px);
  aspect-ratio: 180/121;
  background: url("../assets/img/end-cave/end-cave.png") no-repeat;
  background-size: cover;
  position: relative;
  margin: 80px auto 0;

  ::v-deep .back-btn {
    top: 3%;
    left: 3%;
  }
}

.end-cave-spider-web {
  width: 10%;
  aspect-ratio: 1 / 4;
  /* height: 10%; */
  position: absolute;
  left: 70px;
  top: 0;

  .end-cave-web {
    display: none;
    width: 170px;
  }

  .end-cave-string {
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: 0;
    width: 4%;
    height: 55%;
  }

  .end-cave-spider {
    transform: scale(-1);

    top: 41%;
    width: 100%;
    z-index: 1;
    position: absolute;

    animation: spider-crawl infinite 4s ease-in-out;
  }
}

@keyframes spider-crawl {
  0% {
    transform: scale(-1) translateY(0);
  }
  50% {
    transform: scale(-1) translateY(-45%);
  }
  100% {
    transform: scale(-1) translateY(0);
  }
}

.end-cave-bat {
  width: 35%;
  left: 30%;
  top: 2%;
  position: absolute;
}

.back-btn:hover {
  + .end-cave-avatars .end-cave-spider-web .end-cave-spider {
    animation-duration: 1s
  }
}

.end-cave-kiko {
  position: absolute;
  top: 7%;
  right: 7%;
  z-index: 900;
  width: 15%;
  display: flex;
}
</style>
