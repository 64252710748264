import axios from "axios";
import store from "@/store";
import { endpoints } from "@/services/Endpoint";
import Cookies from "js-cookie";

const apiUrl = process.env.VUE_APP_BACKEND_URL;

export default class API {
  _client;

  constructor() {
    this._client = axios.create({
      baseURL: apiUrl,
      withCredentials: false,
      headers: {
        Authorization: "Bearer " + Cookies.get("access_token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    this.endpoints = endpoints;

    const self = this;

    this.client.interceptors.request.use(function (config) {
      config.headers.Authorization = "Bearer " + Cookies.get("access_token");

      return config;
    });

    this.client.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        const {
          config,
          response: { status },
        } = error;

        const originalRequest = config;

        if (status === 401) {
          if (!store.state.refreshingToken) {
            store.state.refreshingToken = true;

            await self
              .refresh()
              .then(async (response) => {
                const { access_token, refresh_token } = response.data;

                return store.dispatch("auth/setTokens", {
                  access_token,
                  refresh_token,
                });
              })
              .then(() => {
                store.state.refreshingToken = false;
                console.log("Refreshing token");
              });
          }

          return new Promise((resolve) => {
            originalRequest.headers.Authorization =
              "Bearer " + Cookies.get("access_token");
            resolve(axios(originalRequest));
          });
        }
        return Promise.reject(error);
      }
    );
  }

  /**
   * @return {import('axios').AxiosInstance}
   */
  get client() {
    return this._client;
  }

  login(user, password) {
    return this.client.post("/oauth2/token/create", {
      username: user,
      password: password,
      grant_type: "password",
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
    });
  }

  impersonate(token) {
    return this.client.post(this.url("/api/v1/exchange-token"), {
      token: token,
      grant_type: "spellit_exchange_token",
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
    });
  }

  refresh() {
    if (store.getters["auth/getRefreshToken"]) {
      return this.client
        .post("/oauth2/token/create", {
          grant_type: "refresh_token",
          refresh_token: store.getters["auth/getRefreshToken"],
          client_id: process.env.VUE_APP_CLIENT_ID,
          client_secret: process.env.VUE_APP_CLIENT_SECRET,
        })
        .catch(() => store.dispatch("user/logout"));
    }

    store.dispatch("user/logout");
    return Promise.reject();
  }

  get authHeader() {
    return { Authorization: "Bearer " + store.getters["auth/getAccessToken"] };
  }

  url(url, id = null) {
    if (id) {
      url = url.replace("{id}", id);
    } else {
      url = url.replace("{id}", "");
    }

    if (url.slice(-1) === "/") url = url.slice(0, -1);
    return url;
  }

  async get(url, id = null) {
    return this.client.get(this.url(url, id));
  }

  post(url, params) {
    return this.client.post(this.url(url, null), params, {
      headers: { ...this.authHeader },
    });
  }

  delete(url, id) {
    return this.client.delete(this.url(url, id));
  }

  put(url, id, params) {
    return this.client.put(this.url(url, id), params);
  }

  log(type) {
    this.post("/api/v1/activity", { type });
  }

  // TODO create audio module
  async play(url, type, fileName) {
    let path;
    if (type !== "part") {
      if (apiUrl.slice(-1) === "/") {
        path = apiUrl.slice(0, -1);
      } else {
        path = apiUrl;
      }
      path = path + url.replace("{type}", type);
    } else {
      path = url;
    }

    let wordPath = path.replace("{file_name}", fileName);

    let encodedUrl = encodeURI(wordPath).replace("'", "%27");

    const audio = new Audio(encodedUrl);

    if (store.state.isDev) {
      console.groupCollapsed(`Playing audio: ${fileName}`);
      console.trace();
      console.groupEnd();
    }

    store.commit("audio/SET_ACTIVE_AUDIO", { type, audio, fileName });

    return new Promise((resolve) => {
      audio.addEventListener("ended", () => {
        resolve(store.commit("audio/SET_ACTIVE_AUDIO", null));
      });
      audio.addEventListener(
        "canplaythrough",
        () => {
          audio
            .play()
            .catch(() => store.commit("audio/SET_ACTIVE_AUDIO", null));
        },
        false
      );
    });
  }
}
