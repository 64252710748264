import { Model } from "@/models/Model";
import Word from "@/models/exercises/Word";

export default class ExerciseData extends Model {
  constructor({
    word,
    anchor,
    anchors,
    colors,
    template,
    sound_group,
    positions = [],
  }) {
    super();
    this._word = word;
    this._anchor = anchor ? new Anchor(anchor) : null;
    this._anchors = anchors;
    this._colors = colors;
    this._template = template;
    this._sound_group = sound_group;
    this._positions = positions;
  }

  get word() {
    return new Word(this._word);
  }

  get anchor() {
    return this._anchor;
  }

  get anchors() {
    return this._anchors;
  }

  get colors() {
    return this._colors;
  }

  get template() {
    return this._template;
  }

  get soundgroup() {
    return this._sound_group;
  }

  get positions() {
    return this._positions;
  }
}

class Anchor extends Model {
  constructor({ id, name, description, hint, tip, image }) {
    super();
    this._id = id;
    this._name = name;
    this._description = description;
    this._hint = hint;
    this._tip = tip;
    this._image = image;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get description() {
    return this._description;
  }

  get hint() {
    return this._hint;
  }

  get tip() {
    return this._tip;
  }

  get image() {
    return this._image;
  }
}
