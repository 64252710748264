<template>
  <div class="help-overlay__button" ref="container" >
    <img
        v-if="!isVisible"
        class="help-overlay__button__image"
        src="../../assets/img/help-overlay/question-mark.png"
        @click="TOGGLE_VISIBILITY()"
        alt="help"
    />
    <img
        v-if="isVisible"
        class="help-overlay__button__image"
        src="../../assets/img/help-overlay/close.png"
        @click="TOGGLE_VISIBILITY()"
        alt="help"
    />
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "HelpOverlayButton",
  computed: {
    isVisible() {
      return this.$store.state.helpOverlay.isVisible
    }
  },
  methods: {
    ...mapMutations({
      TOGGLE_VISIBILITY: "helpOverlay/TOGGLE_VISIBILITY"
    }),
  },
}
</script>

<style scoped lang="scss">
.help-overlay__button {
  z-index: 102;
  cursor: pointer;
  transition: all .2s;
  position: absolute;
  right: 50px;
  bottom: 50px;
  pointer-events: all;
  height: 10%;

  &:hover {
    transform: scale(1.1);
  }

  .help-overlay__button__image {
    pointer-events: all;
    height: 100%;
  }
}
</style>