<template>
  <div class="text-area-container">
    <v-textarea
        ref="typing-input"
        class="centered typing-input"
        :class="{'has-visible-slider': hideable}"
        rounded
        white
        hide-details="true"
        v-model="input"
        @input="handleInput"
        @keydown.enter="handleEnter"
        no-resize
    />

    <c-hide-button v-if="hideable" class="hide-button"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CHideButton from "@/components/Exercise/Components/HideButton";

export default {
  name: "c-text-area",
  components: {CHideButton},
  props: ['value', 'hideable'],
  data: () => ({
    input: this?.value,
  }),

  methods: {
    ...mapActions({
      continueExercise: "exercise/continueExercise"
    }),
    handleInput() {
      this.$emit("input", this.input)
    },
    handleEnter(e) {
      if (e.key === "Enter" && !e.shiftKey) {
        if (this.isTemplate('Verhaal') || this.isTemplate('Zinnen1') || this.isTemplate('Zinnen2')) {
          return true;
        }
        e.preventDefault();
        if (this.input?.includes(this.word)) {
          this.continueExercise()
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      progressWord: "exercise/getProgressWord",
      progressStep: "exercise/getProgressStep",
      word: "exercise/getWord",
      isTemplate: "template/isTemplate",
    })
  },
  watch: {
    value(value) {
      this.input = value;
    },
    progressStep(newValue) {
      this.input = "";
      this.$emit("input", "")
      if (newValue) {
        this.$refs["typing-input"].focus({
          preventScroll: true
        });
      }
    },
    progressWord() {
      this.input = "";
      this.$emit("input", "")
    }
  }
};
</script>

<style scoped lang="scss">
.v-input {
  border: 2px solid #3bad2c;
  box-shadow: 5px 5px 2px -3px rgba(0, 0, 0, 0.25);
  background: white;
  flex-basis: 100%;
  margin: 50px 0;
}

.text-area-container {
  position: relative;
}

.hide-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

input {
  max-height: 47px !important;
}

.v-text-field--rounded {
  border-radius: 50px !important;
}

.v-text-field {
  padding: 15px !important;
  font-size: 32px !important;
  font-family: "Quicksand", serif !important;
}

.has-visible-slider {
  padding-left: 120px !important;
}
.v-input {
  border: 2px solid #3bad2c;
  box-shadow: 5px 5px 2px -3px rgba(0, 0, 0, 0.25);
  margin: 0;
  background: white;
  flex-basis: 100% !important;
}
</style>
