<template>
  <div v-if="notifications" class="notifications">
    <transition-group name="fade">
      <v-snackbar
        v-for="notification in notifications"
        :key="notification.id"
        :color="notification.type"
        bottom
        :value="true"
        multi-line
        class="spell-it__snackbar"
        timeout="-1"
      >
        <div style="white-space: pre-line;">{{ notification.message }}
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            :color="notification.type"
            v-bind="attrs"
            @click="removeNotification(notification.id)"
            class="no-capitalized"
          >
            Sluiten
          </v-btn>
        </template>
      </v-snackbar>
    </transition-group>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      notifications: (state) => state.notification.notifications,
    }),
  },
  methods: {
    ...mapActions("notification", { removeNotification: "removeById" }),
  },
};
</script>

<style lang="scss">
.spell-it__snackbar {
  .v-snack__wrapper {
    background-color: #fcd501 !important;
    color: #016d47 !important;

    .v-btn--text {
      color: #016d47 !important;
    }

    .v-snack__content {
      font-size: 1.2rem !important;
    }
  }
}

.v-snack__action {
  .v-btn {
    margin: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
