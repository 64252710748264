import Cookies from "js-cookie";
import axios from "axios";

const state = {};

const mutations = {
  SET_ACCESS_TOKEN(state, access_token) {
    if (!access_token) {
      Cookies.remove("access_token");
    } else {
      Cookies.set("access_token", access_token);
    }
  },
  SET_REFRESH_TOKEN(state, refresh_token) {
    if (!refresh_token) {
      Cookies.remove("refresh_token");
    } else {
      Cookies.set("refresh_token", refresh_token);
    }
  },
};

const actions = {
  setAccessToken: ({ commit }, access_token) => {
    commit("SET_ACCESS_TOKEN", access_token);
  },
  setTokens: ({ commit }, { access_token, refresh_token }) => {
    commit("SET_ACCESS_TOKEN", access_token);
    commit("SET_REFRESH_TOKEN", refresh_token);
  },
  clearTokens: ({ commit }) => {
    delete axios.defaults.headers.common["Authorization"];
    commit("SET_ACCESS_TOKEN", null);
    commit("SET_REFRESH_TOKEN", null);
  },
};

const getters = {
  getAccessToken: () => {
    return Cookies.get("access_token") ?? null;
  },
  getRefreshToken: () => {
    return Cookies.get("refresh_token") ?? null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
