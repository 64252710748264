import Pusher from "pusher-js";

const pusher = new Pusher("8b433c9133594ceb6398", {
  cluster: "eu",
});

const state = {
  pusher,
  channel: null,
};

const mutations = {
  SUBSCRIBE(state, payload) {
    state.channel = state.pusher.subscribe(payload);
  },
  UNSUBSCRIBE(state, payload) {
    state.pusher.unsubscribe(payload)
    state.channel = null;
  },
};

const actions = {
  subscribe({ commit }, channel) {
    commit("SUBSCRIBE", channel);
  },
  unsubscribe({ commit }) {
    commit("UNSUBSCRIBE");
  },
  bind({ state }, { type, callback }) {
    state.channel.bind(type, (data) => {
      const currentTime = new Date().getTime() / 1000;
      const sentAtTime = data.time;
      const secondsMargin = 10;

      if (currentTime < sentAtTime + secondsMargin) {
        callback(data);
      } else {
        console.error(
          `Received Pusher too late. Difference is: ${
            currentTime - sentAtTime
          } seconds`
        );
      }
    });
  },
};

const getters = {
  channel: (state) => state.channel,
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
