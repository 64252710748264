<template>
  <div class="word-list-container">
    <h3 class="world-list-header">Woorden in deze stap:</h3>

    <swiper
        :items="wordsPaginated"
    >
      <template #slide="{ item }">
        <div class="word-page">
          <div class="word-wrapper flex-center" v-for="(word, index) in item" :key="index">
            <h3>{{ word }}</h3>
          </div>
        </div>
      </template>
    </swiper>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Swiper from "@/components/Lespagina/Swiper.vue";

export default {
  name: "c-word-list-slider",
  components: {Swiper},
  methods: {
    url(value) {
      return value?.url;
    }
  },
  computed: {
    ...mapGetters({
      getWords: "lessonPage/getWordsByDifficulty",
    }),
    wordsPaginated() {
      let counter = 0;
      return this.getWords?.reduce((acc, curr) => {
        if (!acc[counter]) {
          acc[counter] = []
        }
        acc[counter].push(curr)
        if (acc[counter].length % 4 === 0) {
          counter++
        }
        return acc
      }, {})
    }
  }
};
</script>

<style scoped lang="scss">
.word-list-container {
  background: white;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  position: relative;

  h3 {
    font-size: 0.7em;
  }

  .world-list-header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 25%);
    z-index: 3;
  }

  :deep(.swiper) {
    width: 100%;
  }

  :deep(.swiper-wrapper) {
    height: 85%;
    position: absolute;
    bottom: 0;
  }
}

.word-page {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  flex-direction: column;
  margin: 0;

  .word-wrapper {
    height: 15%;

    p {
      font-size: 2vh;
      margin: 0;
    }
  }
}
</style>
