export class Model {
  static factory(data) {
    if (data instanceof Array) {
      return data.map((model) => new this(model));
    }

    return new this(data);
  }

  /**
   * @param {Model} data
   * @return {Model[]|Model}
   */
  static createWriteable(data) {
    return new this(data.getProperties(), false);
  }

  getProperties() {
    // noinspection JSCheckFunctionSignatures
    return Object.entries(this).reduce((acc, curr) => {
      const propertyKey = curr[0].substring(1);
      acc[propertyKey] = curr[1];
      return acc;
    }, {});
  }
}
