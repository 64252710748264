import { Model } from "@/models/Model";

export class Note extends Model {
  constructor(
    { created_at, date, id, text, title, updated_at, user_id },
    readOnly = true
  ) {
    super();
    this._created_at = created_at;
    this._date = date;
    this._id = id;
    this._text = text;
    this._title = title;
    this._updated_at = updated_at;
    this._user_id = user_id;

    if (readOnly) Object.freeze(this);
  }

  get created_at() {
    return this._created_at;
  }

  set created_at(value) {
    this._created_at = value;
  }

  get date() {
    return this._date;
  }

  set date(value) {
    this._date = value;
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get text() {
    return this._text;
  }

  set text(value) {
    this._text = value;
  }

  get title() {
    return this._title;
  }

  set title(value) {
    this._title = value;
  }

  get updated_at() {
    return this._updated_at;
  }

  set updated_at(value) {
    this._updated_at = value;
  }

  get user_id() {
    return this._user_id;
  }

  set user_id(value) {
    this._user_id = value;
  }

  get readOnly() {
    return this._readOnly;
  }

  set readOnly(value) {
    this._readOnly = value;
  }
}
