<template>
    <div class="hide-button" :class="{visible}" @click="toggle">
      <div class="eye-container">
        <font-awesome-icon
            class="eye-icon shown"
            icon="eye"
            color="#213f1b"
            :class="{visible: visible}"
        />
      </div>
      <div class="eye-container">
        <font-awesome-icon
            class="eye-icon hidden"
            icon="eye-slash"
            color="#5C260D"
            :class="{visible: !visible}"
        />
      </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'c-hide-button',
  data: () => ({
    timeout: null,
    test: false
  }),
  methods: {
    ...mapMutations({
      setWordVisibility: "exercise/SET_WORD_VISIBILITY",
    }),
    ...mapActions({
      useCheat: "exercise/useCheat",
    }),
    toggle() {
      this.setWordVisibility(!this.visible)
      if (this.visible) {
        this.useCheat();
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.setWordVisibility(false), 3000)
      }
    },
  },
  computed: {
    ...mapGetters({
      progressWord: "exercise/getProgressWord",
      visible: "exercise/isWordVisible",
      currentInput: "exercise/getCurrentInput",
    }),
    src() {
      if (this.visible) {
        return require("@/assets/flipped-open.png");
      }

      return require("@/assets/flipped-closed.png")
    },
    alt() {
      if (this.visible) {
        return "flipped-open";
      }

      return "flipped-closed"
    },
  },
  watch: {
    progressWord() {
      this.setWordVisibility(false)
    },
    currentInput() {
      this.setWordVisibility(false)
    },
  }
}
</script>

<style scoped lang="scss">
.hide-button {
  margin-left: 2%;
  cursor: pointer;
  background-color: #EF8310;
  height: 70%;
  aspect-ratio: 7 / 4;
  max-height: 65px;
  border-radius: 50px;
  border: 2px solid #5C260D;
  position: relative;
  transition: background-color .2s linear;
  display: flex;

  .eye-container {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .eye-icon {
    height: 50%;
    width: auto;
    position: absolute;
    transition: opacity .2s linear;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }

  &:after {
    background: white;
    border: 2px solid #5C260D;
    height: 80%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: left 0.2s linear, transform 0.2s linear;
    left: 4%;
  }

  &.visible {
    background-color: #4ea440;

    &:after {
      left: 96%;
      transform: translate(-100%, -50%);
    }
  }
}
</style>
