<template>
  <div class="assign-color-transition-wrapper">
    <transition-group
        name="list-complete"
        tag="div"
        class="assign-color-container"
    >
      <h3
          key="header"
          class="exercise-description"
          @click="checkMark = !checkMark"
      >
        {{ templateDescription }}
      </h3>

      <span key="sentence" v-if="filteredSentence && hasParameter('sentence')">
        <FilteredSentence/>
      </span>

      <div
          key="colors"
          class="input-container color-container"
          v-if="showColours"
      >
        <div class="colors">
          <div
              v-for="color in colors"
              :key="color.id"
              class="color-btn"
              @click="selectColour($event, color.c)"
              :style="{ backgroundColor: color.c }"
          ></div>
        </div>
      </div>

      <transition-group
          name="show-checkmark"
          tag="div"
          :key="`letters_${showBlocks}`"
          class="block-container"
          v-if="showBlocks"
      >
        <div
            :key="`word-container_${blocksVisible}`"
            class="assign-color-word-container"
            :class="{ big: isArrayFilled }"
        >
          <div
              v-for="(option, id) in wordColours"
              :key="`letterblock_${id}`"
              class="verdeel-klankgroepen"
              :class="{
              filled: filled(id),
              'select-letter': selectLetter(id),
              white: white(id),
            }"
              :style="{ 'background-color': backgroundColor(id) }"
          >
            <p class="letter-class">{{ option.letter }}</p>
          </div>
        </div>

        <div class="checkmark-container" key="checkmark" v-show="checkMark">
          <font-awesome-icon
              class="color-done-icon"
              icon="check"
              color="#51AE4B"
          />
        </div>
      </transition-group>

      <c-restricted-input
          key="restricted-input"
          v-if="hasInput && inputVisible"
          v-model="currentInputWord"
      />

      <c-next-button key="next-button" :disabled="nextIsDisabled"/>
    </transition-group>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CNextButton from "@/components/Exercise/Components/NextButton";
import FilteredSentence from "@/components/Exercise/Content/FilteredSentence";
import CRestrictedInput from "@/components/Exercise/Components/RestrictedInput";

export default {
  name: "c-assign-color-template",
  components: {CRestrictedInput, FilteredSentence, CNextButton},
  data: () => ({
    wordColours: [],
    checkMark: false,
    blocksVisible: true,
    inputVisible: false,
    blocksVisibleTimeout: null,
  }),
  mounted() {
    this.resetInput();
  },
  methods: {
    ...mapMutations({
      setCurrentInput: "exercise/SET_CURRENT_INPUT",
    }),
    ...mapActions({
      postData: "exercise/postData",
      wrongInput: "exercise/wrongInput",
    }),
    selectColour(event, color) {
      this.wordColours.some((e, index) => {
        if (e.colour === null && this.checkColour(color, index)) {
          this.wordColours[index].colour = color;

          this.currentInputColors = this.wordColours.map((m) =>
              m.colour ? m.colour.toLowerCase() : null
          );

          return true;
        }

        if (e.colour === null && this.exerciseData.colors[index] !== null) {
          if (!this.checkColour(color, index)) {
            this.wrongInput(event.target);

            const wrongArray = this.wordColours.map((m) =>
                m.colour ? m.colour.toLowerCase() : null
            );
            wrongArray[index] = color;
            this.postData({
              currentInput: {colors: wrongArray},
              repeatWord: false,
            });
          }

          return true;
        }
      });
    },
    resetInput() {
      if (this.hasInput) this.currentInputWord = "";
      if (this.blocksVisibleTimeout) clearTimeout(this.blocksVisibleTimeout);
      this.checkMark = false;
      this.blocksVisible = true;
      this.currentInputColors = [];
      this.wordColours = this.word?.split("").map((value) => ({
        colour: null,
        letter: value,
      }));
    },
    checkColour(color, index) {
      let rightColor = this.lengthenHex(this.exerciseData.colors[index]);
      return color.toLowerCase() === rightColor;
    },
    lengthenHex(color) {
      if (color === "#fff") return "#ffffff";
      if (color === "#000") return "#000000";
      return color;
    },
    shortenHex(color) {
      return color?.replace("#ffffff", "#fff")?.replace("#000000", "#000");
    },
    normalizeHex(color) {
      return color
          ?.toUpperCase()
          .replace("#000", "#000000")
          ?.replace("#fff", "#ffffff");
    },
    filled(id) {
      return !!(this.wordColours[id].colour ?? null);
    },
    white(id) {
      return this.wordColours[id].colour === "#ffffff";
    },
    selectLetter(id) {
      let arr = this.exerciseData.colors.map((color, i) => {
        if (color != null) {
          return i;
        }
      });
      return arr[id] !== undefined;
    },
    backgroundColor(id) {
      return !!this.selectLetter(id) && this.currentInputColors?.[id]
          ? this.currentInputColors?.[id]
          : "white";
    },
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      exerciseData: "exercise/getExerciseData",
      filteredSentence: "exercise/getFilteredSentence",
      getCurrentInput: "exercise/getCurrentInput",
      expectedInput: "exercise/getExpectedInput",
      progressStep: "exercise/getProgressStep",
      progressWord: "exercise/getProgressWord",
      colors: "exercise/getColors",
      word: "exercise/getWord",
      templateDescription: "template/getDescription",
      wordStructure: "exercise/getWordStructure",
      hasParameter: "template/hasParameter",
      isTemplate: "template/isTemplate",
      currentExercise: "exercise/getCurrentStudentExercise",
    }),
    hasInput() {
      return this.hasParameter("restrictedInput");
    },
    nextIsDisabled() {
      const arrayEmpty =
          this.currentInputColors && this.currentInputColors?.length === 0;
      const inputEmpty = this.hasInput ? this.currentInputWord === "" : false;

      return arrayEmpty || inputEmpty;
    },
    anyColor() {
      return this.currentInput?.length > 0;
    },
    isArrayFilled() {
      return (
          this.expectedInput?.colors?.filter((e) => !!e).length ===
          this.currentInputColors?.filter((e) => !!e).length
      );
    },
    showColours() {
      if (this.isTemplate("SpeurKleur1")) {
        return !this.isArrayFilled && !this.currentExercise.is_retry;
      }
      if (this.isTemplate("SpeurKleur2")) {
        return !this.isArrayFilled && !this.currentExercise.is_retry;
      }
      if (this.isTemplate("Blokken")) {
        return !this.isArrayFilled && !this.currentExercise.is_retry;
      }
      return true;
    },
    showBlocks() {
      if (this.isTemplate("SpeurKleur1") && this.isTemplate("SpeurKleur2")) {
        return true;
      }
      if (this.isTemplate("Blokken")) {
        return this.blocksVisible && !this.currentExercise.is_retry;
      }
      return true;
    },
    currentInput: {
      get() {
        return this.getCurrentInput;
      },
      set(value) {
        this.setCurrentInput(value);
      },
    },
    currentInputColors: {
      get() {
        return this.getCurrentInput?.colors;
      },
      set(value) {
        let payload;

        if (this.currentExercise.is_retry) {
          payload = {
            colors: this.expectedInput?.colors.map((e) => this.shortenHex(e)),
          };
        } else {
          payload = {colors: value.map((e) => this.shortenHex(e))};
        }

        if (this.hasInput) {
          payload.word = this.currentInputWord;
        }

        this.setCurrentInput(payload);
      },
    },
    currentInputWord: {
      get() {
        return this.getCurrentInput.word;
      },
      set(value) {
        const payload = {word: value};
        payload.colors = this.currentInputColors;
        this.setCurrentInput(payload);
      },
    },
  },
  watch: {
    isArrayFilled(newValue) {
      if (newValue) {
        this.checkMark = true;
        this.blocksVisibleTimeout = setTimeout(() => {
          if (this.isTemplate("Blokken")) {
            this.blocksVisible = false
          }
          this.inputVisible = true
        }, 1000);
      }
    },
    progressWord() {
      this.resetInput();
      this.inputVisible = false
    },
    progressStep(newValue) {
      if (newValue === 2) {
        this.resetInput();
      }
    },
    getCurrentInput(newValue) {
      if (newValue === "" || newValue.word === "WRONG!") {
        this.resetInput();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.assign-color-transition-wrapper {
  height: 100%;
}

.assign-color-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.3em;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.assign-color-container {
  > * {
    transition: all 0.2s;
  }

  .color-container {
    height: 20%;
  }

  .block-container {
    vertical-align: top;
    display: inline-block;
    gap: 1em;
    height: 3.5rem;

    .assign-color-word-container {
      vertical-align: top;
      display: inline-flex;
      transition: all 0.3s;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: fit-content;
      gap: 0.3rem;

      .verdeel-klankgroepen {
        height: 2.5em;
        width: 2.5em;
        border-radius: 25%;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        &.select-letter {
          color: black;
          border: 1px solid darkgrey;

          .letter-class {
            color: black;
          }

          &.filled {
            border: 2px solid #9c9c9c;
            outline: 1px solid #9c9c9c;

            .letter-class {
              color: white;
            }
          }

          &.white {
            .letter-class {
              color: black;
            }
          }
        }
      }

      .letter-class {
        margin: 0;
        padding: 0;
        font-size: 1.5em;
        font-weight: 700;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        color: #898989;
      }
    }

    .color-done-icon {
      scale: 1.5;
    }

    .checkmark-container {
      display: inline-flex;
      height: 100%;
      margin-left: 1em;
      align-items: center;
    }
  }
}

.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
}

.list-complete-leave-active {
  position: absolute;
  left: 0;
  right: 0;
}

//{colors: ["#0c56a8", "#43ab34", "#43ab34", "#0c56a8"], word: "weet"}
//colors: ["#0c56a8", "#43ab34", "#43ab34", "#0c56a8"]
//word: "weet"

.juisteletter {
  color: red !important;
}

.disabledColors {
  background-color: gray !important;
  display: none !important;
}

.restricted-input-container {
  margin: 0 auto;
  height: 15%;
}

.color-btn {
  cursor: pointer;
  margin: 0 5px;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  outline: none;
  border-width: 2px;
  border-color: white;
  border-style: solid;
}

.colors {
  display: flex;
  justify-content: center;
  transition: all 0.3s;
  overflow: visible;
  position: relative;
  max-height: 100%;
  height: 50%;
  max-width: 80%;
  //animation: fadeOut;

  .color-btn:active {
    transform: scale(1.1);
    transition: transform 0.2s;
  }

  &.hidden {
    animation: colorContainerFadeOut 0.2s ease-in-out;
    opacity: 0;
  }
}

@keyframes colorContainerFadeOut {
  0% {
    max-height: 100%;
    opacity: 1;
  }
  100% {
    max-height: 0;
    opacity: 0;
  }
}

//.show-checkmark-move, /* apply transition to moving elements */
//.show-checkmark-enter-active,
//.show-checkmark-leave-active {
//  transition-property: all;
//  transition-duration: .5s;
//}
//
//.show-checkmark-enter-from,
//.show-checkmark-leave-to {
//  opacity: 0;
//  width: 0;
//}
//
///* ensure leaving items are taken out of layout flow so that moving
//   animations can be calculated correctly. */
//.show-checkmark-leave-active {
//}

.show-checkmark-enter-active,
.show-checkmark-leave-active {
  transition-property: opacity;
  transition-duration: 0.3s;
}

.show-checkmark-enter,
.show-checkmark-leave-to {
  opacity: 0;
}

.show-checkmark-leave-active {
  position: absolute;
}

//.show-checkmark-enter,
//.show-checkmark-leave-to {
//  opacity: 0;
//}
//
//.show-checkmark-leave-active {
//  position: absolute;
//  left: 0;
//  right: 0;
//}
</style>
