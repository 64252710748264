import router from "@/router";

const state = {
  step: 0,
  target: 0,
  tutorials: [
    {
      pathName: "/wereld/1",
      name: "world",
      steps: [
        {
          header: "Welkom in de SPELL-IT jungle!",
          body: `De jungle bestaat uit vier levels. Volg het pad met de stappen.
Bij elke stap horen verschillende oefeningen en dictees.
Met de oefeningen behaal je punten om badges te verdienen.
Begin met de stappentoets en je ziet met welke stappen je kunt beginnen.`,
          footer: "Bekijk de jungle",
          top: "26%",
          left: "19%",
          target: "#opdracht_1",
        },
        {
          header: "Uitleg",
          body: `Doe eerst de stappentoets (de witte vlag), dan ontdek je welke stappen jij als eerste kunt gaan doen. Klik op UITLEG, dan zie je hoe een stap eruit kan zien en wat je moet doen. Een stap is bijvoorbeeld: open, op slot of gehaald. Klik nog een keer, dan verdwijnt de uitleg weer.`,
          footer: "Volgende",
          top: "33%",
          left: "56%",
          target: ".legend-wrapper",
        },
        {
          header: "Een stap",
          body: `Dit is een stap. Klik op het nummer om het ankerwoord te zien. Dan weet je met welke soort woorden je gaat oefenen. Klik op de stap om naar de lespagina te gaan en te oefenen.`,
          footer: "Volgende",
          top: "16%",
          left: "55%",
          target: "#opdracht_3",
        },
        {
          allow: () => !window.isUAMobile,
          header: "Scorebord",
          body: `Klik op het scorebord, dan zie je jouw behaalde punten, badges én diamanten! Klik nog eens, dan verdwijnt het scorebord weer.`,
          footer: "Volgende",
          top: "40%",
          left: "50%",
          target: ".scoreboard__dropdown",
        },
        {
          allow: () => !window.isUAMobile,
          header: "Terug",
          body: `Klik op de pijl, dan ga je weer naar de routekaart.`,
          footer: "Volgende",
          top: "2%",
          left: "26%",
          target: ".back-btn",
        },
        {
          allow: () => window.isUAMobile,
          header: "Scorebord",
          body: `Als je op het scorebord klikt dan zul je zien dat er een scorebord naar beneden schuift. Klik je er
nogmaals op dan schuift het weer omhoog. In het scorebord zie je hoeveel punten je hebt, welke
badges je hebt en hoeveel woorden je geoefend hebt. `,
          footer: "Volgende",
          top: "7%",
          left: "82%",
          target: "#menu-item__trophy",
        },
        {
          header: "Landkaart",
          body: `Als je op de landkaart klikt dan ga je weer terug naar de complete landkaart, dit kan overal waar je
bent in Spell-it.`,
          footer: "Volgende",
          top: "7%",
          left: "82%",
          target: "#menu-item__map",
        },
        {
          header: "Klankenkaart",
          body: `Klik op de knop en spiek op de klankenkaart! Spiek voor de klanken én de kleuren.`,
          footer: "Volgende",
          top: "7%",
          left: "82%",
          target: "#menu-item__klankenkaart",
        },
        {
          header: "Boomhut",
          body: `In de boomhut kun je je dagboek bekijken, badges kopen of je leeslogboek invullen. Zie je
allemaal blaadjes liggen…? Klik op de bezem en doe je oefeningen. Zo veeg je de boomhut
weer schoon!`,
          footer: "Volgende",
          top: "7%",
          left: "82%",
          target: "#menu-item__treehouse",
        },
        {
          header: "Profiel",
          body: `Hier kun je je naam aanpassen of een andere avatar kiezen.`,
          footer: "Volgende",
          top: "7%",
          left: "82%",
          target: "#menu-item__profile",
        },
        {
          header: "Vergroot je scherm",
          body: `Met deze knop kun je het scherm vergroten. Klik nog een keer, dan wordt het scherm weer iets kleiner.`,
          footer: "Volgende",
          top: "7%",
          left: "82%",
          target: "#menu-item__fullscreen",
        },
        {
          header: "Uitloggen",
          body: `Ben je klaar met SPELL-IT, klik dan op deze pijl. De volgende keer moet je eerst weer inloggen.`,
          footer: "Aan de slag",
          top: "7%",
          left: "82%",
          target: "#menu-item__logout",
        },
      ],
    },
    {
      pathName: "/boomhut",
      name: "treehouse",
      steps: [
        {
          header: "Welkom in de boomhut!",
          body: `Hier kun je bekijken wat jij hebt gedaan in SPELL-IT. \n\nSchrijf iets in je logboek, houd bij welke boeken je hebt gelezen of koop nieuwe badges in de shop. \n\nKlik op start voor de rondleiding.`,
          footer: "Start",
          top: "calc(1440px - 130%)", // ??
          left: "50%",
          target: null,
        },
        {
          header: "De bezem",
          body: `Zie je blaadjes in de boomhut? Klik dan op de bezem, het is tijd om te herhalen. \n\nDoe de oefeningen en veeg zo jouw boomhut weer schoon.`,
          footer: "Volgende",
          top: "25%",
          left: "59%",
          target: ".treehouse-broom",
        },
        {
          header: "Het logboek",
          body: `Schrijf in je logboek hoe het oefenen gaat. Welke oefeningen helpen jou vooral? Wat vind je leuk? Wat werkt wel? Heb je vragen aan juf of meester, schrijf ze op!`,
          footer: "Volgende",
          top: "59%",
          left: "70%",
          target: ".treehouse-book",
        },
        {
          header: "Jouw badges",
          body: `Oefenen in SPELL-IT levert badges én punten op. Klik op een badge en lees waarom je die hebt verdiend. Met de punten kun je ook badges kopen in de shop.`,
          footer: "Volgende",
          top: "33%",
          left: "73%",
          target: ".treehouse-badge",
        },
        {
          header: "De boekenplank",
          body: `Houd bij welke boeken je hebt gelezen. Geef elk boek een cijfer.`,
          footer: "Volgende",
          top: "51%",
          left: "81%",
          target: ".treehouse-naslag",
        },
        {
          header: "Jouw voortgang",
          body: `Bekijk hier hoe je elke stap op elk niveau hebt gemaakt. Ook zie je welke woorden je hebt geoefend.`,
          footer: "Volgende",
          top: "2%",
          left: "26%",
          target: ".treehouse-stats",
        },
        {
          header: "Terug naar de jungle",
          body: `Ga terug naar de jungle met de vier levels. Een slot betekent dat je dit level nog niet hoeft te doen.
Een diamant betekent dat je het level hebt behaald.`,
          footer: "Succes!",
          top: "19%",
          left: "30%",
          target: ".treehouse-board",
        },
      ],
    },
    {
      name: "lesson_page",
      steps: [
        {
          header: "Terug",
          body: `Als je op deze pijl klikt, dan ga je weer naar de jungle en het pad met de stappen.`,
          footer: "{arrow_right}",
          top: "2%", // ??
          left: "22%",
          target: ".back-btn",
        },
        {
          header: "Oefeningen en Dictee's ",
          body: `Hier vind je alle oefeningen op een rij. Doe in ieder geval 2 verrekijker-oefeningen en 2 schoen-oefeningen. Gaat het oefendictee goed, doe dan het stapdictee. Heb je 80% goed, dan is deze stap gehaald!`,
          footer: "{arrow_right}",
          top: "61%", // ??
          left: "50%",
          target: ".exercise-container",
        },
        {
          header: "Uitleg oefeningen",
          body: `Klik op uitleg, dan zie je wat deze figuren betekenen: verrekijker, schoen en het kompas. Eerst ga je klanken, woorden en klankgroepen verkennen. Dán ga je ermee oefenen en tot slot doe je de dictees om te ontdekken of je de woorden van deze stap beheerst.`,
          footer: "{arrow_right}",
          top: "34%", // ??
          left: "56%",
          target: ".legend-wrapper",
        },
        {
          header: "Niveaus",
          body: `Als je met de stap begint, bepaal je zelf op welk niveau je gaat oefenen. Je kunt de stap halen als je de oefeningen in ieder geval op niveau 2 sterren hebt gehaald.`,
          footer: "{arrow_right}",
          top: "5%", // ??
          left: "70%",
          target: ".difficulty-stars_container",
        },
        {
          header: "Woorden in de stap",
          body: `Wil je weten welke woorden je gaat oefenen in deze stap? Bekijk ze in dit vak en klik op het pijltje om alle woorden te zien.`,
          footer: "{arrow_right}",
          top: "30%", // ??
          left: "49.3%",
          target: ".word-list-container",
        },
        {
          header: "Ankerwoorden",
          body: `Bij elke stap of spellingregel hoort een plaatje met voorbeeldwoorden. Dat noemen we ankerwoorden. Deze helpen jou om te onthouden wat je oefent. Klik op Kliko als je tijdens het oefenen wilt spieken.`,
          footer: "Je kunt nu beginnen!",
          top: "57%", // ??
          left: "49%",
          target: ".anchor-image-container",
        },
      ],
    },
  ],
  isVisible: false,
};

const mutations = {
  SET_HELP_OVERLAY_VISIBILITY(state, payload) {
    state.isVisible = payload;
  },
  TOGGLE_VISIBILITY(state) {
    state.isVisible = !state.isVisible;
  },
  SET_TARGET(state, payload) {
    state.target = payload;
  },
  SET_STEP(state, payload) {
    state.step = payload;
  },
};

const actions = {};

const getters = {
  currentStep: (state) => (name) => {
    return (
      state.tutorials.find((f) => f.name === name)?.steps[state.step] ?? null
    );
  },
  currentTutorial: (state) => (name) => {
    return state.tutorials.find((f) => f.name === name);
  },
  pagination(state, getters) {
    return `${state.step + 1}/${
      getters.currentTutorial(router.currentRoute.name)?.steps.length
    }`;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
