<template>
  <div class="word-list-container">
    <h3 class="exercise-description" v-html="exercise.after_complete_text">
    </h3>

    <div class="word-overview">
      <div class="word-overview-good">
        <h4>Deze woorden kun je al goed typen:</h4>
        <div class="word-list">
          <p v-for="(detail, id) in correctResults" :key="id">
            {{ detail.word }}
            <span>
              <font-awesome-icon
                  class="side-icon"
                  icon="check"
                  color="#51AE4B"
              />
            </span>
            <span v-if="detail.cheats_used">
              <font-awesome-icon
                  class="side-icon"
                  icon="eye"
                  color="grey"
              />
            </span>
            <span v-if="detail.hints_used">
              <font-awesome-icon
                  class="side-icon"
                  icon="question"
                  color="grey"
              />
            </span>
          </p>
        </div>
      </div>

      <div class="word-overview-wrong" v-if="incorrectResults.length">
        <h4>Deze woorden mag je nog oefenen:</h4>
        <div class="word-list">
          <p v-for="(detail, id) in incorrectResults" :key="id">
            {{ detail.word }}
<!--            <span>-->
<!--              <font-awesome-icon-->
<!--                  class="side-icon"-->
<!--                  icon="times"-->
<!--                  color="#C01817"-->
<!--              />-->
<!--            </span>-->
            <span v-if="detail.cheats_used">
              <font-awesome-icon
                  class="side-icon"
                  icon="eye"
                  color="grey"
              />
            </span>
            <span v-if="detail.hints_used">
              <font-awesome-icon
                  class="side-icon"
                  icon="question"
                  color="grey"
              />
            </span>
          </p>
        </div>
      </div>
    </div>

    <COutroButtonContainer />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import COutroButtonContainer from "@/components/Exercise/Outro/OutroButtonContainer";

export default {
  name: "c-word-list",
  components: {COutroButtonContainer},
  methods: {
    ...mapActions({
      resetExercise: "exercise/resetExercise",
    })
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      results: "exercise/getCorrectResults",
      linkToLessonOverview: "router/linkToLessonOverview",
      correctResults: "exercise/getCorrectResults",
      incorrectResults: "exercise/getIncorrectResults",
      isInitializing: "isInitializing",
    }),
  }
};
</script>

<style scoped lang="scss">
.word-list-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.word-overview {
  width: 100%;
  display: flex;
  margin: 0 auto !important;
  justify-content: space-around;
}

.word-overview-good, .word-overview-wrong {
  width: 375px;
  padding: 20px 0;
  height: 100%;

  h4 {
    text-align: center;
    font-size: 20px;
    color: #016d47;
    height: 50px;
  }
}

.word-list {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  min-width: 50%;
  margin: 20px 0;
  gap: 10px;

  p {
    font-size: 18px !important;
    color: #016d47 !important;
    font-weight: 700;
    margin: 5px 0;
    text-align: center;
  }
}

.word-list-button-container {
  display: flex;
  align-content: center;
  justify-content: space-around;
}

.side-icon {
  margin: 0 2px;
}
</style>