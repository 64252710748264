<template>
  <div class="test-outro">

    <c-speech-bubble class="test-outro-message-container">
      <h3>Goed gedaan!</h3>
      <p>Je bent klaar met de instaptoets.</p>
      <p>Nu kun je beginnen aan de oefeningen.</p>


      <v-btn class="green test-button" tile @click.stop="$router.push(linkToWorld)">
        Ga naar oefeningen
      </v-btn>
    </c-speech-bubble>

    <div class="test-outro-kiko-container">
      <img
          src="@/assets/kiko.png"
          alt="kiko"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CSpeechBubble from "@/components/Base/SpeechBubble";

export default {
  name: 'c-test-outro',
  components: {CSpeechBubble},
  methods: {
    ...mapActions({
      nextStep: "exercise/nextStep",
    })
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      test: "exercise/getTest",
      profile: "user/getProfile",
      screenName: "user/getScreenName",
      createBuddyImage: "user/createBuddyImage",
      linkToWorld: "router/linkToWorld"
    }),
    description() {
      return this.exercise?.description ?? this.test?.label;
    }
  }
}
</script>

<style scoped lang="scss">

</style>