<template>
  <div class="login">
    <div class="shaped-div logo">
      <img alt="Vue logo" class="login-logo" src="../assets/logo_groot.png"/>
    </div>
    <h1>Inloggen</h1>
    <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="submitForm"
        lazy-validation
    >
      <v-text-field
          label="E-mailadres of gebruikersnaam"
          type="text"
          v-model="username"
          :rules="usernameRules"
          background-color="#fff"
          required
          :hide-details="true"
          solo
          flat
          height="50px"
      ></v-text-field>
      <div class="position-relative">
        <v-text-field
            label="Wachtwoord"
            :key="passwordType"
            :type="passwordType"
            v-model="password"
            background-color="#fff"
            required
            :hide-details="true"
            solo
            flat
            height="50px"
            :rules="[(v) => !!v || 'Wachtwoord is verplicht']"
        >
        </v-text-field>

        <transition name="fade">
          <font-awesome-icon
              :disabled="password.length > 0"
              :key="passwordType"
              v-if="passwordType === 'text'"
              class="center-absolute position-absolute pointer"
              icon="eye-slash"
              @click="togglePasswordType"
          />
          <font-awesome-icon
              :disabled="password.length > 0"
              :key="passwordType"
              v-else-if="passwordType === 'password'"
              class="center-absolute position-absolute pointer"
              icon="eye"
              @click="togglePasswordType"
          />
        </transition>
      </div>

      <v-btn
          class="no-border-shadow no-margin btn-blue"
          depressed
          type="submit"
          height="50"
          color="#FFD801"
          tile
          :loading="submitting"
      >
        Starten
      </v-btn>
      <br/><br/>
      <a
          :href="resetURL" class="lost-password" target="_blank"
      >Wachtwoord vergeten?</a
      >
    </v-form>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";
import store from "@/store";
import Notification from "@/models/Notification";

export default {
  async created() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    console.log(params);
    if (params.token) {
      console.log('voor impersonate');
      await this.impersonate(params.token)
      console.log('na impersonate');
      this.$router.replace("/");
    }
    console.log("this.loggedIn", this.loggedIn);
    if (this.loggedIn) {
      if (this.$route.path === "/logout") {
        this.logout().then(() => {
          if (!this.loggedIn) {
            store.dispatch(
                "notification/add",
                new Notification({
                  type: "success",
                  message: "Uitgelogd",
                })
            );

            history.replaceState(null, null, "/login");
          }
        });
      } else {
        this.$router.replace("/");
      }
    } else {
      history.replaceState(null, null, "/login");
    }
  },
  data: () => ({
    loginToken: null,
    valid: true,
    passwordType: "password",
    username: "",
    password: "",
    usernameRules: [
      (v) => !!v || "E-mailadres is verplicht",
      (v) => /.+@.+/.test(v) || "Geen geldig e-mailadres",
    ],
    submitting: false,
  }),
  computed: {
    ...mapState("user", ["loggedIn"]),
    ...mapGetters({
      user: "user/getProfile",
    }),
    resetURL() {
      return process.env.VUE_APP_BACKEND_URL + "/user/forgot";
    },
  },
  methods: {
    ...mapActions({
      fetchProfile: "user/fetchProfile",
      impersonate: "user/impersonate",
    }),
    togglePasswordType() {
      if (this.passwordType === "text") {
        return this.passwordType = "password"
      }

      if (this.passwordType === "password") {
        return this.passwordType = "text"
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    submitForm() {
      if (this.validate()) {
        this.submitting = true;
        const {username, password} = this;
        this.login({username, password}).then(() => {
          this.$fullscreen.request();
          this.submitting = false;
        }).catch(() => {
          this.submitting = false;
        });
      } else {
        store.dispatch(
            "notification/add",
            new Notification({
              type: "error",
              message: "Kan niet inloggen met deze gegevens",
            })
        );
      }
    },
    ...mapActions("user", ["login", "logout"]),
  },
};
</script>

<style scoped lang="scss">
.login {
  background: url("../assets/achtergrond.png");
  background-size: cover;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 80px;
  bottom: 0;
  overflow: overlay;
  padding: 0 50px 50px;

  .logo {
    padding: 20px 10px;
  }

  .logo img {
    height: 100%;
  }

  &:after {
    content: "";
    background: black;
    min-height: 600px;
    width: 100vw;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0.3;
    pointer-events: none;
  }
}

.login .v-input {
  margin: 25px 0;
}

.login-logo {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

h1 {
  color: #fff;
  width: inherit;
  transform: inherit;
  background: none;
  margin: 0;
  position: relative;
  z-index: 1;
}

.v-input ::v-deep label {
  text-align: center;
  left: 50% !important;
  transform: translateX(-50%);
  font-weight: bold;
  font-size: 1.2rem !important;
  color: #016d47 !important;
  height: 25px;
}

.v-input ::v-deep input {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem !important;
  color: #016d47 !important;
  margin: 0 20px;
}

.shaped-div.logo img {
  max-width: 500px;
  image-rendering: -webkit-optimize-contrast;
}

form {
  width: 35%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.v-btn {
  width: 100%;
  font-family: "Quicksand";
  font-weight: bold;
  font-size: 1.2rem !important;
  color: #016d47 !important;
  text-transform: inherit;
}

a.lost-password {
  color: white;
}

.password-eyecon {
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}
</style>
