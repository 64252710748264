import Vue from "vue";
import App from "./App.vue";
import Vuetify from "vuetify";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faArrowRight,
  faBug,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCompress,
  faExpand,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faPlayCircle,
  faQuestion,
  faSyncAlt,
  faTimes,
  faUndo,
  faUserCircle,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";
import Moment from "moment";
import "vuetify/dist/vuetify.min.css";
import { InlineSvgPlugin } from "vue-inline-svg";
import API from "@/services/API";
import { Helper } from "@/services/Helper";
import SlitherSlider from "slither-slider";
import VueYouTubeEmbed from "vue-youtube-embed";
import "@/assets/scss/main.scss";

Moment.locale("nl");

Vue.prototype.$moment = Moment;
Vue.prototype.$api = new API();
Vue.prototype.$helper = Helper;

Helper.addPrototypes();

Vue.filter("moment", function (date) {
  return Moment(date).format("DD-MM-YYYY");
});

library.add(
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faPlayCircle,
  faInfoCircle,
  faUserCircle,
  faExpand,
  faCompress,
  faAngleDown,
  faArrowRight,
  faVolumeUp,
  faSyncAlt,
  faBug,
  faCheck,
  faTimes,
  faUndo,
  faEye,
  faEyeSlash,
  faQuestion
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(Vuetify);
Vue.use(VueYouTubeEmbed);
Vue.use(InlineSvgPlugin);
Vue.use(SlitherSlider);

// Vue.config.productionTip = false;

let userAgent = null;
let isUAMobile = null;

const checkMobile = () => {
  console.log(`USERAGENT: ${userAgent}`);
  return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent
  );
};

const updateMobile = () => {
  userAgent = navigator.userAgent.toLowerCase();
  isUAMobile = checkMobile();
  window.isUAMobile = isUAMobile;
};

window.updateMobile = updateMobile;
window.updateMobile();

window.toggleDebugPanel = () => store.dispatch("toggleDebugPanel");

Vue.mixin({
  data: function () {
    return {
      isUAMobile, // vuetify conflict if isMobile, conflict with vue if _isMobile or $isMobile
      vhfix: (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2),
    };
  },
  methods: {
    updateMobile: () => {
      userAgent = navigator.userAgent.toLowerCase();
      isUAMobile = checkMobile();
    },
  },
});

new Vue({
  store,
  router,
  vuetify: new Vuetify(),
  render: (h) => h(App),
}).$mount("#app");
