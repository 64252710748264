<template>
  <v-btn
      class="green button"
      tile
      @click="$emit('click')"
      :disabled="disabled"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "CButton",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style scoped lang="scss">
.button {
  height: fit-content;
  width: fit-content;
  margin: auto;
}
</style>