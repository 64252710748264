<template>
  <img
      :src="speakerIcon()"
      alt="speaker-icon"
      class="speaker"
      @click.prevent="player.word(word);"
      :class="{ active: activeAudio.fileName === word }"
  />
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "c-speaker",
  props: {
    word: {
      required: true,
      type: String
    }
  },
  methods: {
    speakerIcon() {
      return require("../../assets/speakericon.png")
    },
  },
  computed: {
    ...mapState({
      player: (state) => state.audio.player,
      activeAudio: (state) => state.audio.activeAudio,
    }),
  }
}
</script>

<style scoped lang="scss">
.speaker:not(.disabled) {
  cursor: pointer;
}

img {
  transition: transform 0.5s;
}

.active {
  transform: scale(1.3);
}
</style>