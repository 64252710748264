<template>
  <transition name="difficulty-modal-fade">
    <div class="difficulty-modal-backdrop" @click="$emit('close')">
      <div
          @click.stop=""
          class="difficulty-modal"
          role="dialog"
          aria-labelledby="difficulty-modalTitle"
          aria-describedby="difficulty-modalDescription"
      >
        <section
            class="difficulty-modal-body"
            id="difficulty-modalDescription"
        >
          <h3 v-html="modalText" class="modal-text"></h3>
          <div class="difficulty-star-container">
            <v-btn
                v-for="(star) in Array.range(3, 1)"
                :key="star"
                height="100%"
                class="select-difficulty"
                color="#026B4A"
                :class="{completed: completed(star)}"
                @click.stop="setDifficultyAndClose(star)"
            >
              <img
                  :src="$helper.getPng(`stars-${star}.png`)"
                  :alt="`stars-${star}`"
              />

              <p v-if="completed(star)">Gehaald!</p>
            </v-btn>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'c-difficulty-modal',
  data: () => ({
    modalText: 'Op welk niveau wil je de oefeningen doen?'
  }),
  mounted() {
    this.setModalText()
  },
  methods: {
    ...mapActions({
      setDifficulty: "lessonPage/setDifficulty",
    }),
    setDifficultyAndClose(star) {
      this.setDifficulty({difficulty: star, id: this.$route.params.stap_id});
      this.$emit('close')
    },
    completed(level) {
      return this.highestCompleted >= level
    },
    setModalText() {
      if ([1,2].some(s => s === this.difficulty()) && this.highestCompleted === 2) {
        const png = this.$helper.getPng(`stars-3.png`)
        return this.modalText = `Je kunt de stap ook nog op niveau <img src='${png}' /> halen.`
      }

      if (this.highestCompleted === 3) {
        return this.modalText = "Je hebt alle niveaus gehaald! Klaar om naar de volgende stap te gaan?"
      }

      if (this.difficulty() &&this.increaseLevelPrompt) {
        const png = this.$helper.getPng(`stars-${this.difficulty()}.png`)
        return this.modalText = `Je hebt deze stap met <img src='${png}' /> behaald, je mag nu door naar de volgende stap.`
      }

      return this.modalText = 'Op welk niveau wil je de oefeningen doen?'
    },
  },
  computed: {
    ...mapGetters({
      step: "lessonPage/getStep",
      difficulty: "lessonPage/getDifficulty",
      highestCompleted: "lessonPage/highestCompleted",
    }),
    increaseLevelPrompt() {
      return this.highestCompleted < 3 && this.difficulty() < 3 && this.difficulty() <= this.highestCompleted
    },
  },
};
</script>

<style lang="scss">
.difficulty-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.difficulty-modal {
  overflow: hidden;
  z-index: 99;
  background: #fff;
  border-radius: 2vmin;
  border: 0.3vmin solid green;
  width: 50%;
  aspect-ratio: 2/1;
  right: 0;
  bottom: 0;
  padding: 1vmin;
}

.difficulty-modal-header,
.difficulty-modal-footer {
  display: flex;
  z-index: 999;
  min-height: 20%;
}

.difficulty-modal-header {
  position: absolute;
  color: #4AAE9B;
  justify-content: space-between;
}

.difficulty-modal-body {
  z-index: 999;
  height: 100%;
  position: relative;
  margin: 0 auto;
  text-align: center;
  overflow: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;

  .modal-text img{
    vertical-align: middle;
    translate: 0 -0.3rem;
    height: 5rem;
  }
}

.difficulty-modal-footer {
  margin: 0 auto;
  flex-direction: column;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border-radius: 2px;
}

.select-difficulty {
  &.completed {
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 1.5vmin;
    }

    p {
      color: #897F5A;
      background: #FFDA00;
      z-index: 2;
      position: absolute;
      font-size: 1.4vmin;
      width: 100% !important;
      text-anchor: middle;
      margin: 0;
    }
  }
}

.difficulty-star-container {
  display: flex;
  width: 100%;
  height: 40%;
  justify-content: space-around;

  button {
    aspect-ratio: 1/1;
    min-width: unset !important;
    height: unset !important;
    padding: 0 !important;
    border-radius: 1.5vmin !important;
  }

  .v-btn__content {
    aspect-ratio: 1/1;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.difficulty-modal-fade-enter,
.difficulty-modal-fade-leave-to {
  opacity: 0;
}

.difficulty-modal-fade-enter-active,
.difficulty-modal-fade-leave-active {
  transition: opacity .5s ease;
}

.completed {
  //border: #FFDA00 7px solid !important;
}
</style>