<template>
  <div class="letter" ref="letter"></div>
</template>
<script>
export default {
  name: "v-letter",
  props: ["config", "index"],
  data: () => ({
    timeouts: { start: null, restart: null },
  }),
  mounted() {
    this.$nextTick(() => {
      this.loop();
    });
  },
  beforeDestroy() {
    clearTimeout(this.timeouts.start);
    clearTimeout(this.timeouts.restart);
  },
  methods: {
    loop() {
      if (this.canIGo()) {
        this.randomizeSize();
        this.setSize();

        this.randomizeColour();
        this.setColour();

        this.randomizeLocation();
        this.setLocation();

        this.randomizeTransitionDuration();
        this.setTransitionDuration();

        this.randomizeAnimationDuration();
        this.setAnimationDuration();

        this.randomizeStart();
        this.start();
      } else {
        this.randomizeTransitionDuration();
        this.randomizeStart();
      }

      this.timeouts.restart = setTimeout(() => {
        this.resetAnimation();
        this.loop();
      }, this.config.duration.transition.current * 1000 + this.config.duration.start.current);
    },
    canIGo() {
      return Math.randomMinMax(1, 100) > 100 - this.config.spawnChance;
    },
    randomizeSize() {
      this.config.size.current = Math.randomMinMax(
        this.config.size.min,
        this.config.size.max
      );
    },
    setSize() {
      this.$refs["letter"].style.fontSize = this.config.size.current + "px";
      this.$refs["letter"].style.lineHeight = this.config.size.current + "px";
    },
    randomizeColour() {
      this.config.colour.R.current =
        this.config.colour.R.base +
        Math.randomMinMax(this.config.colour.R.min, this.config.colour.R.max);
      this.config.colour.G.current =
        this.config.colour.G.base +
        Math.randomMinMax(this.config.colour.G.min, this.config.colour.G.max);
      this.config.colour.B.current =
        this.config.colour.B.base +
        Math.randomMinMax(this.config.colour.B.min, this.config.colour.B.max);
    },
    setColour() {
      this.$refs[
        "letter"
      ].style.color = `rgb(${this.config.colour.R.current}, ${this.config.colour.G.current}, ${this.config.colour.B.current})`;
    },
    randomizeLocation() {
      const clientWidth = this.$refs["letter"].clientWidth;
      this.config.x = Math.randomMinMax(clientWidth, 1440) - clientWidth;
    },
    setLocation() {
      this.$refs["letter"].style.left = this.config.x + "px";
      this.$refs["letter"].style.top = -this.config.size.current + "px";
    },
    randomizeTransitionDuration() {
      this.config.duration.transition.current = Math.randomMinMax(
        this.config.duration.transition.min,
        this.config.duration.transition.max
      );
    },
    setTransitionDuration() {
      this.$refs["letter"].style.transitionDuration =
        this.config.duration.transition.current + "s";
    },
    randomizeAnimationDuration() {
      this.config.duration.animation.current = Math.randomMinMax(
        this.config.duration.animation.min,
        this.config.duration.animation.max
      );
    },
    setAnimationDuration() {
      this.$refs["letter"].style.animationDuration =
        this.config.duration.animation.current + "s";
    },
    randomizeStart() {
      this.config.duration.start.current = Math.randomMinMax(
        this.config.duration.start.min * 1000,
        this.config.duration.start.max * 1000
      );
    },
    start() {
      this.timeouts.start = setTimeout(() => {
        this.$emit("status", { status: 1, index: this.index });
        this.$refs["letter"].style.top = 900 + "px";
        this.$refs["letter"].innerHTML = String.randomChar();
        this.$refs["letter"].classList.add("active");
      }, this.config.duration.start.current);
    },
    resetAnimation() {
      this.$emit("status", { status: 0, index: this.index });
      this.$refs["letter"].innerHTML = "";
      this.$refs["letter"].style.fontSize = "0px";
      this.$refs["letter"].style.lineHeight = "0px";
      this.$refs["letter"].style.top = -this.config.size.current + "px";
      this.$refs["letter"].style.transitionDuration = "0s";
      this.$refs["letter"].classList.remove("active");
    },
  },
};
</script>

<style scoped>
.letter {
  font-size: 0;
  line-height: 0;
  color: yellow;
  position: absolute;
  transition: top linear;
  transition-duration: 0s;
  animation: rotation infinite linear;
  animation-duration: 0s;
  z-index: 500;
  font-weight: bold;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
