<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" v-show="value">
      <div
          class="modal"
      >
        <img src="../../assets/close-modal.png" @click="close" aria-label="Close modal" class="bubble-modal__close" />
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BubbleModal",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('change', false);
    },
  },
}
</script>

<style scoped>
.modal {
  position: relative;
  overflow: hidden;
  padding-top: 10px;
}

.bubble-modal__close {
  position: absolute;
  right: 10px;
  top: 15px;
  width: 50px;
}
</style>