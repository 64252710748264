import { Model } from "@/models/Model";
import ExerciseType from "@/models/exercises/ExerciseType";
import ExerciseStudent from "@/models/exercises/ExerciseStudent";
import store from "@/store/index";
import router from "@/router";

export default class Exercise extends Model {
  constructor({
    id,
    step_id,
    position,
    name,
    description,
    after_complete_text,
    exercise_type_id,
    highlight_structure,
    highlight_structure_in_color,
    target_structure,
    show_word_as_sound_groups,
    workbook_type,
    points_rewarded_difficulty_1,
    points_rewarded_difficulty_2,
    points_rewarded_difficulty_3,
    min_success_score_difficulty_1,
    min_success_score_difficulty_2,
    min_success_score_difficulty_3,
    repeat_exercise_after_complete,
    document_introduction,
    generate_document_read_words,
    generate_document_write_words,
    generate_document_write_sounds,
    generate_document_sentences,
    download_sound_scheme,
    use_audio_word,
    use_audio_structure,
    use_audio_sound_groups,
    use_audio_plural,
    use_audio_sentence,
    word_view_duration,
    created_at,
    updated_at,
    exercise_type,
    student_exercise,
    documents,
    show_progress_bar,
    status,
    status_label,
  }) {
    super();
    this._id = id;
    this._step_id = step_id;
    this._position = position;
    this.name = name;
    this._description = description;
    this._after_complete_text = after_complete_text;
    this._exercise_type_id = exercise_type_id;
    this._highlight_structure = highlight_structure;
    this._highlight_structure_in_color = highlight_structure_in_color;
    this._target_structure = target_structure;
    this._show_word_as_sound_groups = show_word_as_sound_groups;
    this._workbook_type = workbook_type;
    this._points_rewarded_difficulty_1 = points_rewarded_difficulty_1;
    this._points_rewarded_difficulty_2 = points_rewarded_difficulty_2;
    this._points_rewarded_difficulty_3 = points_rewarded_difficulty_3;
    this._min_success_score_difficulty_1 = min_success_score_difficulty_1;
    this._min_success_score_difficulty_2 = min_success_score_difficulty_2;
    this._min_success_score_difficulty_3 = min_success_score_difficulty_3;
    this._repeat_exercise_after_complete = repeat_exercise_after_complete;
    this._document_introduction = document_introduction;
    this._generate_document_read_words = generate_document_read_words;
    this._generate_document_write_words = generate_document_write_words;
    this._generate_document_write_sounds = generate_document_write_sounds;
    this._generate_document_sentences = generate_document_sentences;
    this._download_sound_scheme = download_sound_scheme;
    this._use_audio_word = use_audio_word;
    this._use_audio_structure = use_audio_structure;
    this._use_audio_sound_groups = use_audio_sound_groups;
    this._use_audio_plural = use_audio_plural;
    this._use_audio_sentence = use_audio_sentence;
    this._word_view_duration = word_view_duration;
    this._created_at = created_at;
    this._updated_at = updated_at;
    this._exercise_type = new ExerciseType(exercise_type);
    this._status = status;
    this._status_label = status_label;

    this._student_exercise = null;

    if (student_exercise) {
      this._student_exercise = {
        1: new ExerciseStudent(student_exercise[1]),
        2: new ExerciseStudent(student_exercise[2]),
        3: new ExerciseStudent(student_exercise[3]),
      };
    }

    if (
      !student_exercise &&
      store.getters["exercise/getExercise"]?.student_exercise
    ) {
      this._student_exercise =
        store.getters["exercise/getExercise"]?.student_exercise;
    }

    this._documents = documents;
    this.show_progress_bar = show_progress_bar;
  }

  get exercise_type() {
    return this._exercise_type;
  }

  get student_exercise() {
    return this._student_exercise;
  }

  get id() {
    return this._id;
  }

  get step_id() {
    return this._step_id;
  }

  get position() {
    return this._position;
  }

  get description() {
    return this._description && this._description !== ""
      ? this._description
      : null;
  }

  get after_complete_text() {
    if (store.getters["exercise/getCurrentStudentExercise"]?.isForcedRepeat()) {
      return "Dat was hard werken! Om verder te kunnen vragen we je om de oefening nog een keer te herhalen.";
    }

    return this._after_complete_text && this._after_complete_text !== ""
      ? this._after_complete_text.replace(/(?:\r\n|\r|\n)/g, "<br>")
      : "Je hebt geoefend met de woorden:";
  }

  get exercise_type_id() {
    return this._exercise_type_id;
  }

  get highlight_structure() {
    return this._highlight_structure;
  }

  get highlight_structure_in_color() {
    return this._highlight_structure_in_color;
  }

  get target_structure() {
    return this._target_structure;
  }

  get show_word_as_sound_groups() {
    return this._show_word_as_sound_groups;
  }

  get workbook_type() {
    return this._workbook_type;
  }

  get points_rewarded_difficulty_1() {
    return this._points_rewarded_difficulty_1;
  }

  get points_rewarded_difficulty_2() {
    return this._points_rewarded_difficulty_2;
  }

  get points_rewarded_difficulty_3() {
    return this._points_rewarded_difficulty_3;
  }

  get min_success_score_difficulty_1() {
    return this._min_success_score_difficulty_1;
  }

  get min_success_score_difficulty_2() {
    return this._min_success_score_difficulty_2;
  }

  get min_success_score_difficulty_3() {
    return this._min_success_score_difficulty_3;
  }

  get repeat_exercise_after_complete() {
    return this._repeat_exercise_after_complete;
  }

  get document_introduction() {
    return this._document_introduction && this._document_introduction !== ""
      ? this._document_introduction
      : null;
  }

  get generate_document_read_words() {
    return this._generate_document_read_words;
  }

  get generate_document_write_words() {
    return this._generate_document_write_words;
  }

  get generate_document_write_sounds() {
    return this._generate_document_write_sounds;
  }

  get generate_document_sentences() {
    return this._generate_document_sentences;
  }

  get download_sound_scheme() {
    return this._download_sound_scheme;
  }

  get use_audio_word() {
    return this._use_audio_word;
  }

  get use_audio_structure() {
    return this._use_audio_structure;
  }

  get use_audio_sound_groups() {
    return this._use_audio_sound_groups;
  }

  get use_audio_plural() {
    return this._use_audio_plural;
  }

  get use_audio_sentence() {
    return this._use_audio_sentence;
  }

  get word_view_duration() {
    return this._word_view_duration;
  }

  get created_at() {
    return this._created_at;
  }

  get updated_at() {
    return this._updated_at;
  }

  get documents() {
    return this._documents;
  }

  getStudentExercise() {
    if (
      this.student_exercise &&
      store.getters["lessonPage/getDifficulty"](
        router.currentRoute.params.stap_id
      )
    ) {
      return this.student_exercise[
        store.getters["lessonPage/getDifficulty"](
          router.currentRoute.params.stap_id
        )
      ];
    }
  }

  getDifficulty() {
    return store.getters["lessonPage/getDifficulty"]();
  }

  isPaused = () => this.status[this.getDifficulty()] === 1;

  isDone = () => this.status[this.getDifficulty()] === 2;

  isFailed = () => this.status[this.getDifficulty()] === 3;

  isOpen = () => {
    return this.status[this.getDifficulty()] <= 0;
  };

  // Isoleren
  isBinocular() {
    return this.exercise_type.category === 2;
  }

  // Exercise
  isShoe() {
    return this.exercise_type.category === 1;
  }

  // Dictee
  isCompass() {
    return (
      this.exercise_type.category === 100 || this.exercise_type.category === 101
    );
  }

  canRepeat() {
    return !!this.repeat_exercise_after_complete || !this.isDone();
  }

  get status() {
    return this._status;
  }

  get status_label() {
    return this._status_label;
  }
}
