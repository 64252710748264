<template>
  <div class="hidden-typing-input-container">
    <div class="card">
      <transition name="fade">
        <h2 v-if="isWordVisible">{{ word }}</h2>
        <h2>{{ exerciseData.word.sentence }}</h2>
      </transition>
    </div>
    <c-restricted-input v-model="currentInput" :hideable="true"  />

    <c-next-button :disabled="!currentInput"/>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import CNextButton from "@/components/Exercise/Components/NextButton";
import CRestrictedInput from "@/components/Exercise/Components/RestrictedInput";

export default {
  name: "c-typing-template",
  components: {CRestrictedInput, CNextButton},
  methods: {
    ...mapMutations({
      setCurrentInput: "exercise/SET_CURRENT_INPUT",
    }),
    ...mapActions({
      useCheat: "exercise/useCheat",
    }),
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      exerciseData: "exercise/getExerciseData",
      getCurrentInput: "exercise/getCurrentInput",
      progress: "exercise/getProgress",
      progressWord: "exercise/getProgressWord",
      word: "exercise/getWord",
      isWordVisible: "exercise/isWordVisible",
    }),
    progressStep() {
      return this.progress.step;
    },
    currentInput: {
      get() {
        return this.getCurrentInput?.word;
      },
      set(value) {
        this.setCurrentInput({word: value});
      }
    },
  },
};
</script>

<style scoped lang="scss">
.hidden-typing-input-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.restricted-input-container {
  margin: 50px auto;
  height: 15%;
  width: 50%;
}

.input {
  position: relative;
}

.hide-button {
  position: relative;
}

h2 {
  font-size: 60px;
  color: black;
}

</style>