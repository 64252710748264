<template>
  <div class="profile-flexbox__column">
    <div class="avatar-list__wrapper">
      <span class="form-label">Kies je avatar:</span>
      <div class="avatar-list">
        <div
            class="avatar-wrapper"
            v-for="image in Array.range(6, 1)"
            :key="image"
            @click="value.buddy_id = image"
        >
            <div
                v-if="image === value.buddy_id"
                class="selected-avatar"
            />
            <img
                class="avatar"
                :src="`https://cdn.spellit.nl/buddies/${image}/large.png`"
                width="163px"
                height="163px"
                :alt="`buddy_${image}`"
            />
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'ProfileAvatarList',
  props: ['value']
}
</script>
<style scoped lang="scss">

.form-label {
  color: #016d47;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 1.5vw;
}

///

.avatar-list__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.avatar-wrapper {
  position: relative;
  flex-basis: 33%;
  flex-shrink: 1;
  flex-grow: 1;
}

.avatar-list {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 30vw;
}

.avatar-list img {
  cursor: pointer;
  aspect-ratio: 1 / 1;
  transform: scale(0.9);
  height: 100%;
  width: 100%;
  transition: transform 0.2s;
  filter: drop-shadow(2px 2px 1px #222);
}

.avatar-list :hover img {
  transform: scale(1) rotate(5deg);
}

.selected-avatar {
  background: #ffd900;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  height: 100%;
  position: absolute;
  display: inline-block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
