<template>
  <div class="bubble">
      <div class="bubble_header">
        <slot name="header"></slot>
        <img @click="$emit('close')" v-if="closeable" class="close-button" src="../../assets/img/buttons/close-modal.png">
      </div>
      <div class="bubble_body">
        <slot name="body"></slot>
      </div>
      <div class="bubble_footer">
        <slot name="footer"></slot>
      </div>
  </div>
</template>

<script>
export default {
  name: "Bubble",
  props: {
    closeable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.bubble {
  border-radius: 20px;
  padding: 30px;
  background: white;
  border: 2px solid green;
  max-width: 400px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;

  .bubble_header {
    margin-bottom: 25px;
    color: #016d47;
    display: flex;
    justify-content: space-between;
  }

  .close-button {
    width: 30px;
    height: 30px;
    transition: transform .5s;
    cursor: pointer;
    margin-left: 10px;

    &:hover {
      transform: scale(1.1);
    }
  }

  .bubble_body {
    white-space: pre-wrap;
    color: #2c3e50;
    font-weight: bold;
  }

  .bubble_footer {
    margin-top: 25px;
    color: #016d47;
  }
}
</style>