<template>
  <div
      class="scoreboard"
  >
    <div class="column">
      <div class="scoreboard-badges scoreboard-area scoreboard-badges">
        <h3>Badges</h3>
        <div class="badges-top-menu">
          <img
              v-for="(badge, id) in getMyBadges" :key="id"
              :src="badge.image_url"
              class="badge-topmenu"
              alt="badges"
          />
        </div>
        <transition name="fade">
          <v-btn
              v-if="buttonActive"
              class="scoreboard-badges-button square-button"
              color="#FDDA00"
              type="button"
              tile
              @click.stop="goToBadges()"
          >
            Mijn Badges
          </v-btn>
        </transition>
      </div>
    </div>

    <div class="column" v-if="isLoggedIn">
      <div class="scoreboard-level scoreboard-area">
        <h3>Level</h3>
        <p class="scoreboard-large-txt">
          {{ profile.student.phase_id }}
        </p>
      </div>
      <div class="scoreboard-woorden scoreboard-area">
        <h3>Woorden geoefend</h3>
        <p class="scoreboard-large-txt">
          {{ profile.student.num_words }}
        </p>
      </div>
    </div>

    <div class="column" v-if="isLoggedIn">
      <div class="scoreboard-diamanten scoreboard-area">
        <h3>Diamanten</h3>
        <p
            v-if="
                    !profile.student.end_reward_1_collected &&
                    !profile.student.end_reward_2_collected &&
                    !profile.student.end_reward_3_collected &&
                    !profile.student.end_reward_4_collected
                  "
            class="scoreboard-large-txt"
        >
          0
        </p>

        <div class="collected_diamonds__container">
          <div
              class="collected_diamonds__wrapper"
              v-for="index in student.collectedRewards()"
              :key="index"
          >
            <img
                :src="require(`../../assets/img/end-cave/diamond-${index}.png`)"
                :alt="`admin${index}`"
            >
          </div>
        </div>
      </div>
      <div class="scoreboard-punten scoreboard-area">
        <h3>Behaalde punten</h3>
        <p class="scoreboard-large-txt" :key="profile.id">
          {{ profile.student.points }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ScoreboardContent",
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      getMyBadges: "user/getMyBadges",
      profile: "user/getProfile",
      student: "user/getStudent",
      buddyId: "user/getBuddyId",
      isIntroductionCompleted: "user/isIntroductionCompleted",
    }),
    buttonActive() {
      return this.$route.name !== "mijn badges"
    },
  },
  methods: {
    goToBadges() {
      this.$router.push({name: 'mijn badges'});
      this.$emit("change", false)
    },
  }
}
</script>

<style scoped lang="scss">
.scoreboard {
  display: flex;
  max-width: 802px;
  background: #006c49;
  margin: -10px auto auto;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 1px 1px 5px #4e4e4e;
  border: 2px solid white;
  height: 363px;
}

.scoreboard-badges-button {
  position: sticky !important;
  bottom: -10px !important;
  margin: 20px 0 0;
  //left: 50%;
}

.scoreboard h3 {
  color: #d2fad8;
  font-weight: bold;
}

.scoreboard-large-txt {
  color: white;
  font-size: 25px;
  font-weight: bold;
}

.scoreboard-punten .scoreboard-large-txt:before {
  background-image: url("../../assets/xp.png");
  background-size: 23px 23px;
  display: inline-block;
  width: 23px;
  height: 23px;
  content: "";
  vertical-align: baseline;
}

.column {
  width: 266px;
  padding: 10px;
  text-align: center;
}

.scoreboard-area {
  border: 1px solid white;
  margin: 20px;
  box-shadow: 1px 1px 5px #4e4e4e;
  height: 140px;
  border-radius: 15px;
  background: #409f78;
  padding: 20px;
}

.scoreboard-badges {
  height: calc(100% - 40px);
  overflow: overlay;
  position: relative;
}

.collected_diamonds__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  height: 90%;
  align-content: center;
  margin: auto;

  img {
    width: 40px;
  }
}
</style>