<template>
  <div
      class="typing-input-container"
      :class="{shown: shouldShow}"
  >
    <h3 class="typing-description">{{ description }}</h3>
    <FilteredSentence />

    <div class="leaf" v-if="shouldShow">
      <h2 class="typing-word">{{ word }}</h2>
    </div>
    <!--    <h2 v-else class="typing-word">{{ word }}</h2>-->

    <c-restricted-input
        spellcheck="false"
        ref="input"
        v-if="restricted"
        v-model="currentInput"
        class="typing-input"
    />

    <c-input
        ref="input"
        v-else
        v-model="currentInput"
        class="typing-input"
    />

    <c-next-button/>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CRestrictedInput from "@/components/Exercise/Components/RestrictedInput";
import CNextButton from "@/components/Exercise/Components/NextButton";
import CInput from "@/components/Exercise/Components/Input";
import FilteredSentence from "@/components/Exercise/Content/FilteredSentence";

export default {
  name: "c-typing-template",
  components: {FilteredSentence, CInput, CNextButton, CRestrictedInput},
  methods: {
    ...mapMutations({
      setCurrentInput: "exercise/SET_CURRENT_INPUT",
    }),
    ...mapActions({
      wrongInput: "exercise/wrongInput",
    }),
    dataValue(input) {
      if (this.isTemplate("KiesKlank")) {
        return {sound_group: input};
      }

      return {word: input};
    },
    dataKey() {
      if (this.isTemplate("KiesKlank")) {
        return "sound_group";
      }

      return "word";
    },
  },
  computed: {
    ...mapGetters({
      getCurrentInput: "exercise/getCurrentInput",
      getWord: "exercise/getWord",
      isTemplate: "template/isTemplate",
      description: "template/getDescription",
      hasLeaf: "template/hasLeaf",
      exerciseData: "exercise/getExerciseData",
      shouldShow: "template/shouldShow",
      restricted: "template/isRestricted",
      getErrors: "exercise/getErrors",
    }),
    word() {
      if (this.isTemplate("KiesKlank")) {
        let word = this.exerciseData?.template;
        if (this.currentInput) {
          for (let i = 0; i < this.currentInput.length; i++) {
            word = word.replace(/\./, this.currentInput.charAt(i))
          }
        }
        return word
      }

      return this.getWord;
    },
    currentInput: {
      set(value) {
        this.setCurrentInput(this.dataValue(value))
      },
      get() {
        return this.getCurrentInput?.[this.dataKey()]
      },
    }
  },
};
</script>

<style scoped lang="scss">
.typing-input-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .typing-input {
    margin: 0 auto;
  }

  &.shown {
    .typing-input {
      margin: 0 auto;
    }
  }
}

h2 {
  font-size: 40px;
  margin: 50px 0;
  letter-spacing: 5px;
}
</style>
