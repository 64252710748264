<template>
  <div class="badge-card" :class="{flipped: cardFlipped}" @click="toggleCard">
    <div class="badge-card-inner">
      <div class="badge-card-front">
        <div class="badge-name">
          <img alt="info" src="../../assets/info-icon.png" class="info-icon" />
          <p>{{ badge.name }}</p>
          <p v-if="shop" class="coins">{{ badge.cost }}</p>
        </div>
        <img class="badge-image" :src='badge.image_url_large' :alt="badge.image"/>

        <v-btn
            :class="{hidden: !shop}"
            class="square-button"
            color="#FDDA00"
            tile
            :disabled="!canBuy()"
            @click.stop="buy(badge.id)"
        >
          Koop
        </v-btn>
      </div>

      <div class="badge-card-back">
        <img alt="info" src="../../assets/info-icon.png" class="info-icon"/>
        <p>{{ badge.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Reward} from "@/models/Reward";

export default {
  name: 'c-badge-card',
  props: {
    badge: {
      type: Reward,
      required: true
    },
    shop: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    cardFlipped: false
  }),
  methods: {
    buy(id) {
      this.$emit('buy', id)
    },
    toggleCard() {
      this.cardFlipped = !this.cardFlipped;
    },
    canBuy() {
      const hasMoney = this.badge.cost <= this.profile.student.points
      const isBuyable = this.badge.is_buyable
      return hasMoney && isBuyable;
    },
  },
  computed: {
    ...mapGetters({
      profile: "user/getProfile",
    }),
  },
}
</script>

<style scoped lang="scss">
.badge-card {
  position: relative;
  height: 250px;
  width: 250px;
  perspective: 1000px;
  cursor: pointer;
}

.badge-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.badge-card.flipped .badge-card-inner {
  transform: rotateY(180deg);
}

.badge-card-front, .badge-card-back {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  height: 100%;
}

.badge-card-front {
  background: white;
  display: flex;
  justify-content: flex-end;

  .hidden {
    opacity: 0;
  }

  p {
    text-align: center;
    font-weight: bold;
    color: darkgray;
  }
}

.badge-card-back {
  background: #126C4A;
  transform: rotateY(180deg);

  p {
    color: white !important;
  }
}

.badge-image {
  width: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.badge-name {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  img {
  }

  p {
    margin: 10px 0;
    color: #016D47;
  }
}

p.coins {
  position: relative;
  color: #016D47;
}

p.coins:before {
  background-image: url('../../assets/xp.png');
  background-size: 15px 15px;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  content: "";
}

.buy {
  border: 2px solid white !important;
  text-transform: inherit;
  font-weight: bold;
  color: green !important;
}

.info-icon {
  width: 25px !important;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
</style>