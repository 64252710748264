<template>
  <transition name="progress-modal-fade">
    <div class="progress-modal-backdrop" v-if="shown">
      <div
          class="progress-modal"
          role="dialog"
          aria-labelledby="progress-modalTitle"
          aria-describedby="progress-modalDescription"
      >
        <img
            src="../../assets/close-modal.png"
            @click="closeModal()"
            aria-label="Close modal"
            class="btn-progress-close"
            alt="close-modal"
        />
        <header
            class="progress-modal-header"
            id="progress-modalTitle"
        >
          <slot name="header">
            <h1>Voortgang</h1>
          </slot>
        </header>

        <div class="progress-modal-body-wrapper">
          <section
              class="progress-modal-body"
              id="progress-modalDescription"
          >
            <!--          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In rhoncus, lorem in pulvinar commodo, massa nisl-->
            <!--             condimentum velit, eget blandit lectus arcu sit amet nunc. Aenean sagittis ut diam ac accumsan. Integer-->
            <!--             aliquam lectus orci, sit amet blandit justo dapibus porttitor. Aliquam erat volutpat. Morbi convallis-->
            <!--             pretium-->
            <!--             nisi, quis pretium ante maximus sit amet. Integer tempor erat accumsan arcu molestie faucibus. Phasellus eu-->
            <!--             volutpat risus, non varius felis. Vestibulum suscipit vestibulum purus porttitor venenatis.</p>-->


            <c-loading v-if="progress.length === 0"/>

            <v-data-table
                v-else
                show-expand
                :single-expand="true"
                :disable-filtering="true"
                :disable-pagination="true"
                :disable-sort="true"
                :hide-default-footer="true"
                :items="filteredProgress"
                :headers="headers"
                :mobile-breakpoint="0"
            >
              <template v-slot:item.status="{ item }">
                <img
                    :src="exerciseIcon(item)"
                    class="repetition-icon"
                    alt="status-icon"
                />
              </template>
              <template v-slot:item.name="{ item }">
                {{ getStepLabel(item.phase_id, item.step_id) }}
              </template>
              <template v-slot:item.progress="{ item }">
                <v-progress-linear
                    :value="item.progress"
                    height="30"
                    color="#53B045"
                    background-color="#EDEDED"
                    class="voortgang-bar"
                ></v-progress-linear>
              </template>
              <template v-slot:item.difficulty="{ item }">
                <img :src="difficultyIcon(item.difficulty)" width="50px" alt="difficulty"/>
              </template>
              <template v-slot:item.mastery="{ item }">
                {{item.percentage_score}}%
              </template>
              <template v-slot:item.words_exercised="{ item }">
                {{ item.words_exercised.length }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  [{{ item.words_exercised }}]
                    <v-data-table

                        :disable-filtering="true"
                        :disable-pagination="true"
                        :disable-sort="true"
                        :hide-default-footer="true"
                        :hide-default-header="true"
                        :items="item.words_exercised"
                        :headers="wordsExercisedHeaders"
                        :mobile-breakpoint="0"
                        :dense="true"
                        no-data-text=""
                    >
                      <template v-slot:item.speaker="{ item }">
                        <c-speaker :word="item"/>
                      </template>
                      <template v-slot:item.word="{ item }">
                        <p>{{ item }}</p>
                      </template>
                    </v-data-table>
                  <div :key="0" v-if="item.words_exercised.length === 0" class="no-words">

                    <p>Je hebt nog geen Schoenoefening(en) gemaakt.</p>
                    <p>Er staan daarom nog geen woorden in deze lijst.</p>
                    <p>Zodra
                       je Schoenoefeningen maakt zul je hier zien welke woorden je hebt geoefend.</p>
                    <p>Success!</p>
                  </div>
                </td>
              </template>
            </v-data-table>

            <c-progress-filter v-model="filteredProgress"/>
          </section>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapGetters} from "vuex";
import CLoading from "@/components/Loading";
import CProgressFilter from "@/components/Treehouse/ProgressFilter";
import gsap from 'gsap'
import CSpeaker from "@/components/Base/c-speaker";

export default {
  name: 'c-progress-modal',
  components: {CSpeaker, CProgressFilter, CLoading},
  props: ['shown'],
  data: () => ({
    filteredProgress: [],
    practicedWords: [],
    activePracticedWords: null,
    showWords: false,
    headers: [
      {text: 'Status', value: 'status'},
      {text: 'Naam stap', value: 'name'},
      {text: 'Voortgang', value: 'progress'},
      {text: 'Niveau', value: 'difficulty'},
      {text: 'Beheersingspercentage', value: 'mastery'},
      {text: 'Aantal geoefende woorden', value: 'words_exercised'},
      { text: '', value: 'data-table-expand' },
    ],
    wordsExercisedHeaders: [
      {text: 'Speaker', value: 'speaker'},
      {text: 'Word', value: 'word'},
    ]
  }),
  methods: {
    close() {
      this.$emit('close');
    },
    viewPracticedWords(index, words) {
      if (index === this.activePracticedWords) {
        this.activePracticedWords = null;
        this.practicedWords = [];
        this.showWords = false;
      } else {
        this.activePracticedWords = index;
        this.practicedWords = words;
        this.showWords = true;
      }
    },
    onBeforeEnter(el) {
      el.style.opacity = 0
      el.style.height = 0
    },
    onEnter(el, done) {
      gsap.to(el, {
        opacity: 1,
        height: '40px',
        delay: el.dataset.index * 0.15,
        onComplete: done
      })
    },
    onLeave(el, done) {
      gsap.to(el, {
        opacity: 0,
        height: 0,
        delay: el.dataset.index * 0.15,
        onComplete: done
      })
    },
    wordMastery(progress) {
      if (progress?.words_without_errors && progress?.words_exercised.length > 0) {

        return Math.floor((progress.words_without_errors.length / progress.words_exercised.length) * 100);
      }

      return 0
    },
    closeModal() {
      this.$emit('close')
    },
    getStepLabel(phase_id, step_id) {
      return this.steps(phase_id).find(e => e.id === step_id)?.label;
    },
  },
  computed: {
    ...mapGetters({
      progress: "user/getProgress",
      steps: "world/getSteps",
      exerciseIcon: "world/getExerciseIconSource",
      difficultyIcon: "exercise/getDifficultyIcon",
    }),
  },
  mounted() {
    console.log('ProgressModal.vue mounted()');
    this.$store.dispatch("user/fetchProgress");
  }
};
</script>

<style scoped lang="scss">
.progress-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.progress-modal {
  display: flex;
  flex-direction: column;
  z-index: 99;
  background: #F8F4E0;
  border-radius: 25px;
  outline: 4px solid #006c49;
  width: 80vw;
  height: 70vh;
  position: relative;
}

h1 {
  margin: -40px 0 -20px 10px;
  padding: 15px 5px 6px 5px;
  width: 65%;
  transform: rotate(-2deg);
  text-align: center;
  background: #FFD900;
  background: url('../../assets/titelbalk.png') no-repeat;
  background-size: cover;
  color: #016D47;
  z-index: 9999;
  image-rendering: auto;
}

.progress-modal-header,
.progress-modal-footer {
  display: flex;
  z-index: 999;
}

.progress-modal-header {
  position: absolute;
  width: 600px;
  color: #4AAE9B;
  justify-content: space-between;
}

.progress-modal-body {
  z-index: 999;
  background: #F8F4E0;
  height: 100%;
  overflow: auto;
  display: flex;
}

.progress-modal-body-wrapper {
  overflow: hidden;
  position: relative;
  padding: 50px 60px;
  height: 100%;
}

.progress-modal-footer {
  width: 600px;
  margin: 0 auto;
  flex-direction: column;
}

.btn-progress-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 62px;
  border: 62px;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
  z-index: 999;
}

.green {
  color: white;
  background: #4AAE9B;
  border-radius: 2px;
  transition: transform .5s ease-out;

  &.active {
    transform: scale(1.1);
  }
}

.insufficient-filter-disclaimer {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
}

.v-data-table {
  width: 100%;
}

::v-deep {
  .v-data-table__wrapper {
    background: white;
  }
}

.practiced-words {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 1s;
  padding: 20px 0 0 20px;
  flex-wrap: nowrap;

  ul {
    list-style: none;
    transition: all 1s;
    width: fit-content;

    li {
    }

    img {
      margin-bottom: -12px;
      display: inline-block;
    }

    p {
      display: inline-block;
      line-height: 40px !important;
      margin: 0 0 0 10px;
    }
  }

  &.open {
    width: 400px;
  }

  width: 0;
}

.no-words {
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .long-button {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .short-button {
    display: none;
  }
}

tr::after {
  content: "";
}

::v-deep .v-data-table__empty-wrapper {
  display: none;
}

::v-deep .v-data-table__expanded__content {
  td {
    background: #f7f3df;
  }
}
</style>