<template>
  <transition name="confirmation-modal-fade">
    <div class="confirmation-modal-backdrop">
      <div class="confirmation-modal"
        role="dialog"
        aria-labelledby="confirmation-modalTitle"
        aria-describedby="confirmation-modalDescription"
      >
        <header
          class="confirmation-modal-header"
          id="confirmation-modalTitle"
        >
          <slot name="header">
          </slot>
        </header>

        <section
          class="confirmation-modal-body"
          id="confirmation-modalDescription"
        >
          <slot name="body">
            
          </slot>
        </section>

        <footer class="confirmation-modal-footer">
          <slot name="footer">
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'ConfirmationModal',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style>
  .confirmation-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .confirmation-modal {
    overflow-x: auto;
    z-index: 99;
    background: #fff;
    border-radius: 25px;
    border: 2px solid green;
    width: 300px;
    height: 250px;
    right: 0;
    bottom: 0;
  }

  .confirmation-modal-header,
  .confirmation-modal-footer {
    display: flex;
    z-index: 999;
    min-height: 20%;
  }

  .confirmation-modal-header {
    position: absolute;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .confirmation-modal-body {
    z-index: 999;
    height: auto;
    position: relative;
    padding: 40px;
    margin: 0px auto;
    text-align: center;
    overflow: auto;
    align-items: center;
  }

  .confirmation-modal-footer {
    margin: 0 auto;
    flex-direction: column;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border-radius: 2px;
  }

  .confirmation-modal-fade-enter,
  .confirmation-modal-fade-leave-to {
    opacity: 0;
  }

  .confirmation-modal-fade-enter-active,
  .confirmation-modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>