import { Model } from "@/models/Model";

export default class DetailedResult extends Model {
  constructor({
    word,
    num_tries,
    num_errors,
    time_spent,
    completed,
    success,
    last_answer,
    cheats_used,
    hints_used,
  }) {
    super();
    this.word = word;
    this.num_tries = num_tries;
    this.num_errors = num_errors;
    this.time_spent = time_spent;
    this.completed = completed;
    this.success = success;
    this.cheats_used = cheats_used;
    this.hints_used = hints_used;
    this.last_answer = last_answer;
  }
}
