<template>
  <div class="top-menu">
    <div class="content-top-menu">
      <div class="logo">
        <img
            src="../../assets/logo_klein.png"
            @click="$router.push({ name: 'world_overview' })"
            alt="logo_klein"
        />
      </div>

      <ScoreboardDropdown @change="toggleScoreboard()" :open="scoreboard.open" v-if="!isUAMobile"/>
      <ScoreboardModal @change="toggleScoreboard()" :open="scoreboard.open" v-if="isUAMobile"/>

      <!-- <div
        class="legend-wrapper"
        v-if="isLoggedIn && isIntroductionCompleted"
      >
        <vue-slide-up-down
          class="legend"
          :active="legendActive"
          :duration="1000"
          :use-hidden="this.useHidden"
        >
          <div class="legend-content" dir="RTL">
            <ul class="legend-ul">
              <li><img src="../assets/img/exercises/exercise_open.png" /> Stap open</li>
              <li><img src="../assets/img/exercises/exercise_closed-1.png" /> Stap kun je nog niet doen</li>
              <li><img src="../assets/img/exercises/exercise-passed-3.png" /> Stap gehaald</li>
              <li><img src="../assets/img/exercises/exercise_test-6.png" /> Instaptoets</li>
              <li><img src="../assets/img/exercises/difficulty-3-stars.png" /> Kies je niveau</li>
            </ul>
          </div>
        </vue-slide-up-down>
        <button
          @click="toggleLegend()"
          v-show="isLoggedIn"
          class="legend-btn"
        >
          Uitleg stappen
        </button>
      </div> -->

      <div class="top-menu__buttons">
        <div id="nav">
          <div
              @click="scoreboard.open = !scoreboard.open"
              v-show="isLoggedIn && isIntroductionCompleted && isUAMobile"
              class="menu-item"
              id="menu-item__trophy"
          ><img
              src="../../assets/img/topmenu/trophy.png" alt="map"
          /></div>

          <div
              @click="goToOverview" v-show="isLoggedIn && isIntroductionCompleted"
              class="menu-item"
              id="menu-item__map"
          ><img
              src="../../assets/img/topmenu/map.png" alt="map"
          /></div>

          <div
              v-show="isLoggedIn && isIntroductionCompleted"
              class="menu-item"
              id="menu-item__klankenkaart"
          >
            <img
                src="../../assets/img/topmenu/klankenkoort-icon.png"
                @click="showKlankenModal"
                alt="klankenkaart"
            />
          </div>

          <router-link
              class="menu-item"
              to="/boomhut"
              v-show="isLoggedIn && isIntroductionCompleted"
              id="menu-item__treehouse"
          ><img
              src="../../assets/img/topmenu/treehouse.png"
              alt="boomhut"
          /></router-link>

          <!-- <router-link to="/profiel"><img src="../assets/menu-profiel.png" class="menu-item" /></router-link> -->
          <router-link
              to="/profiel" v-if="isLoggedIn" class="menu-item"
              id="menu-item__profile"
          >
            <transition name="fade" mode="out-in">
              <img
                  :src="buddyAvatar"
                  :key="profile.student.buddy_id"
                  alt="profile"
                  class="profile"
              />
            </transition>
          </router-link>

          <div
              class="menu-item"
              id="menu-item__fullscreen"
          >
            <img
                src="../../assets/img/topmenu/fullscreen.png"
                @click="toggle"
                alt="fullscreen"
            />
          </div>

          <div
              v-if="isLoggedIn"
              class="menu-item"
              id="menu-item__logout"
              @click="logout"
          >
            <img
                src="../../assets/img/topmenu/logout.png"
                alt="logout"
            />
          </div>
        </div>
      </div>
    </div>
    <CContentModal class="klankenkaart-modal" v-model="klankenModalVisible" @close="closeKlankenModal()">
      <template #body>
        <div class="klankenkaart-flexbox">
          <div class="klankenkaart-image-wrapper">
            <img alt="klankenkaart" :src="require('@/assets/img/topmenu/klankenkaart.png')" style="height:100%"/>
            <v-btn @click="downloadPdf" class="download-button square-button">Download</v-btn>
          </div>
        </div>
      </template>
    </CContentModal>

    <!--    <KlankenkaartModal-->
    <!--        v-show="isKlankenModal"-->
    <!--        @close="closeKlankenModal"-->
    <!--    ></KlankenkaartModal>-->
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import ScoreboardDropdown from "@/components/TopMenu/ScoreboardDropdown";
import ScoreboardModal from "@/components/TopMenu/ScoreboardModal";
import CContentModal from "@/components/Modals/ContentModal";

export default {
  components: {CContentModal, ScoreboardModal, ScoreboardDropdown},
  computed: {
    ...mapState({
      user: 'user',
      helpOverlay: 'helpOverlay',
    }),
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      getMyBadges: "user/getMyBadges",
      profile: "user/getProfile",
      buddyId: "user/getBuddyId",
      isIntroductionCompleted: "user/isIntroductionCompleted",
      isVisible: "overlay/isVisible"
    }),
    buddyAvatar() {
      return require(`../../assets/img/topmenu/buddy-${this.buddyId}.png`)
    },
  },
  data: () => ({
    fullscreen: false,
    legendActive: false,
    show: true,
    useHidden: true,
    badges: [],
    klankenModalVisible: false,
    scoreboard: {
      open: false,
    }
  }),
  methods: {
    ...mapActions({
      logout: "user/logout"
    }),
    ...mapMutations({
      setWorld: "world/SET_WORLD",
    }),
    toggleScoreboard() {
      return this.scoreboard.open = !this.scoreboard.open
    },
    goToOverview() {
      if (this.$route.name === "world_overview") return;
      this.setWorld(null)
      this.$router.push({name: 'world_overview'})
    },
    toggle() {
      this.$fullscreen.toggle();
    },
    fullscreenChange(fullscreen) {
      this.$fullscreen = fullscreen;
    },
    toggleLegend() {
      this.legendActive = !this.legendActive;
    },
    showKlankenModal() {
      this.klankenModalVisible = true;
    },
    closeKlankenModal() {
      this.klankenModalVisible = false;
    },
    src() {
      return require("/src/assets/pdf/klankenkaart.pdf")
    },
    downloadPdf() {
      window.open(this.src(), '_blank')
    },
  },
};
</script>

<!--suppress CssUnusedSymbol, CssInvalidPropertyValue -->
<style lang="scss">

#app:not(.is_mobile) {
  .top-menu {
    max-width: 1440px;
    margin: auto;
  }
}

.top-menu {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 103;

  .logo {
    display: flex;
    margin: 25px;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 98;
  }

  .logo img {
    height: 50px;
    cursor: pointer;
  }
}

.top-menu__buttons {
  z-index: 98;
  position: relative;
  top: 50%;
}

.content-top-menu {
  background: #ffd900;
  //overflow: hidden;
  margin: 0 auto;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  text-align: left;
}

/* badges */

.badges-top-menu {
  display: flex;
  margin-top: 20px;
  max-width: 200px;
  flex-wrap: wrap;
  justify-content: center;
}

.badge-topmenu {
  max-width: 50px;
}

#nav {
  display: flex;
  align-content: center;
  align-items: center;
  padding: 0 15px 0 0 !important;
}

#nav svg {
  margin-left: 10px;
}

.menu-item {
  float: right;
  margin: 0 5px;
  box-shadow: 1px 1px 10px rgba(87, 87, 87, 0.4);
  width: 75px;
  border-radius: 50%;
  border-width: 3px;
  border-color: white;
  border-style: solid;
  outline: none;
  background: #186c49;
  cursor: pointer;
  overflow: hidden;
  aspect-ratio: 1 / 1;

  &:hover {
    outline-width: 4px;
  }

  img {
    height: 100%;
    width: 100%;
    padding: 12px;
  }

  .profile {
  }
}

a,
.menu-item {
}


.top-menu {
  &:before {
    content: "";
    position: fixed;
    /* top: 80px; */
    pointer-events: none;
    top: 0;
    left: 0;
    height: 80px;
    width: 100vw;
    transition: background-color .5s, filter 0.3s;
    z-index: 98;
    background: #ffd900;
  }
}

.menu-item:not(.help-overlay__active-item) {
  z-index: 100;
}

//.menu-item:not(.help-overlay__active-item):before {
//  z-index: 100;
//  content: "";
//  position: absolute;
//  /* top: 80px; */
//  pointer-events: none;
//  width: 75px;
//  height: 75px;
//  border-radius: 50%;
//  background: rgba(0, 0, 0, 0);
//  transition: all .5s;
//}

// wtf is this shit

.faded {
  * {
    pointer-events: none;
    transition: background-color 5s;
  }

  .top-menu {
    &:before {
      content: "";
      position: fixed;
      /* top: 80px; */
      pointer-events: none;
      top: 0;
      left: 0;
      height: 80px;
      width: 100vw;
      transition: background-color .5s;
      z-index: 98;
      filter: brightness(50%);
    }
  }

  .menu-item:not(.help-overlay__active-item) {
    z-index: 100;
  }

  .logo {
    filter: brightness(50%);
  }

  //.menu-item:not(.help-overlay__active-item):before {
  //  z-index: 100;
  //  content: "";
  //  position: absolute;
  //  /* top: 80px; */
  //  pointer-events: none;
  //  width: 75px;
  //  height: 75px;
  //  border-radius: 50%;
  //  background: rgba(0, 0, 0, 0.4);
  //}
}

#menu-item__trophy img {
  transform: translateY(5%);
}

.klankenkaart-flexbox {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}

.klankenkaart-image-wrapper {
  aspect-ratio: 2167/1250;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;

  .download-button {
    height: 8% !important;
    font-size: 1.1vw !important;
    padding: 0 16px !important;
    position: absolute;
    bottom: 7%;
    right: 7%;
  }
}

::v-deep .content-modal {
  width: auto;
}

.klankenkaart-modal .content-modal {
  width: fit-content;

}
</style>
