<template>
  <div class="shine-spawner-wrapper">
    <img
      :style="getStyle('big')"
      class="shine-spawner big"
      :src="require('@/assets/img/end-cave/diamond-shine.png')"
      alt="shine-big"
      ref="big"
    />
    <img
      :style="getStyle('medium')"
      class="shine-spawner medium"
      :src="require('@/assets/img/end-cave/diamond-shine.png')"
      alt="shine-medium"
      ref="medium"
    />
    <img
      :src="require('@/assets/img/end-cave/diamond-shine.png')"
      :style="getStyle('small')"
      class="shine-spawner small"
      alt="shine-small"
      ref="small"
    />
  </div>
</template>

<script>
export default {
  name: "v-shine-spawner",
  props: {
    number: {
      type: Number,
      required: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.small.style.opacity = "0";
      this.$refs.medium.style.opacity = "0";
      this.$refs.big.style.opacity = "0";

      this.loop("small");
      this.loop("medium");
      this.loop("big");
    }, 10);
  },
  data: () => ({
    // TODO randomize all?
    settings: {
      1: {
        small: { x: 59, y: 82, timing: null },
        medium: { x: 27, y: 20, timing: null },
        big: { x: 89, y: 6, timing: null }
      },
      2: {
        small: { x: 50, y: 83, timing: null },
        medium: { x: 29, y: 19, timing: null },
        big: { x: 89, y: 53, timing: null }
      },
      3: {
        small: { x: 91, y: 47, timing: null },
        medium: { x: 16, y: 40, timing: null },
        big: { x: 69, y: 0, timing: null }
      },
      4: {
        small: { x: 35, y: 85, timing: null },
        medium: { x: 80, y: 25, timing: null },
        big: { x: 24, y: -2, timing: null }
      }
    }
  }),
  methods: {
    loop(size) {
      const element = this.$refs[size];

      if (element instanceof HTMLImageElement) {
        const timeout = Math.random() * 2 + 1;

        element.style.transition =
          "opacity " + Math.floor(timeout) + "s linear";

        setTimeout(() => {
          if (element.style.opacity === "1") {
            element.style.opacity = "0";
          } else {
            element.style.opacity = "1";
          }
          this.loop(size);
        }, timeout * 1000);
      }
    },
    getStyle(size) {
      const x = this.settings[this.number][size].x;
      const y = this.settings[this.number][size].y;

      return `top: ${y}px; left: ${x}px;`;
    }
  }
};
</script>

<!--suppress CssUnusedSymbol -->
<style lang="scss" scoped>
.filled {
  height: 100%;

  .shine-spawner-wrapper {
    position: absolute;
    transform: scale(0.9);
  }
}

.shine-spawner {
  position: absolute;
  transition: opacity 1s linear;
  opacity: 1;
  $small: 10%;
  $medium: 15%;
  $big: 20%;

  &.small {
    width: $small;
    height: $small;
  }

  &.medium {
    width: $medium;
    height: $medium;
  }

  &.big {
    width: $big;
    height: $big;
  }
}
</style>
