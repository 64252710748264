<template>
  <div class="jungle-content with-padding faded-overlay jungle-aspect-ratio">
    <div class="badges-header">
      <v-btn
          class="square-button"
          color="#FDDA00"
          type="button"
          tile
          @click.stop="$router.push({ name: 'shop' })"
      >
        Ga naar de shop
      </v-btn>
    </div>

    <div class="jungle-badges-list" v-if="myBadges.length > 0">
      <c-badge-card v-for="badge in myBadges" :key="badge.id" :badge="badge"/>
    </div>
    <div class="jungle-badges-list" v-else>
      <h3>Er zijn op dit moment geen badges die je kunt kopen.</h3>
    </div>
  </div>
</template>

<script>
import CBadgeCard from "@/components/Badges/BadgeCard";
import {mapGetters} from "vuex";

export default {
  components: {
    CBadgeCard,
  },
  computed: {
    ...mapGetters({
      myBadges: "user/getMyBadges"
    })
  }
}
</script>

<style scoped>
.badges-header {
}
</style>