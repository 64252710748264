<template>
  <div v-if="isTemplate('StapDictee')" class="outro-button-container">
    <c-next-button @click="nextStep" :post="false"/>
  </div>
  <div v-else class="outro-button-container">
    <v-btn class="green" tile @click.stop="$router.push(linkToLessonOverview)" v-if="!studentExercise.isForcedRepeat() && showBack">
      Ga terug naar de lespagina
    </v-btn>
    <v-btn class="green" tile @click="resetExercise" v-if="showRepeat || studentExercise.isForcedRepeat() || exercise.canRepeat()">
      Herhaal de oefening
    </v-btn>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import CNextButton from "@/components/Exercise/Components/NextButton.vue";

export default {
  name: 'COutroButtonContainer',
  components: {CNextButton},
  props: {
    showBack: {
      type: Boolean,
      default: true,
    },
    showRepeat: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    ...mapActions({
      resetExercise: "exercise/resetExercise",
      nextStep: "exercise/nextStep",
    }),
  },
  computed: {
    ...mapGetters({
      linkToLessonOverview: "router/linkToLessonOverview",
      exercise: "exercise/getExercise",
      studentExercise: "exercise/getCurrentStudentExercise",
      isTemplate: "template/isTemplate",
    })
  },
}
</script>
<style scoped lang="scss">
.outro-button-container {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  position: relative;
}
</style>