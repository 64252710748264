<template>
  <div class="overlay__container" ref="container">
    <div class="overlay__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "COverlay",
}
</script>

<style scoped lang="scss">
.overlay__container {
  position: absolute;
  background: rgba(0, 0, 0, 0.40);
  z-index: 9999;
  width: 1440px;
  height: 980px;
  margin: 0 auto;
  top: 0;

  .overlay__content {
    pointer-events: all;
    left: 50%;
    top: 50%;
    transition: all .5s;
    position: absolute;
    transform: translate(-50%, calc(-50% - 80px));
    width: fit-content;
    height: fit-content;
  }
}
</style>