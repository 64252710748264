<template>
  <div class="jungle-content with-padding faded-overlay jungle-aspect-ratio">
    <c-liana class="liana-left"/>

    <c-coins-backdrop/>

    <c-lesson-header/>

    <c-exercise-slider />

    <div class="lower-section">
      <c-anchor-image-slider :items="step.anchors.map(e => e.image_url)"/>

      <c-word-list-slider />

      <div class="character-container">
        <img class="kiko" src="../assets/kiko.png" alt="kiko"/>

        <img
            class="compass-boy"
            alt="compass-boy"
            :src="
                    'https://cdn.spellit.nl/buddies/' +
                    profile.student.buddy_id +
                    '/compass.png'
                  "
        />

        <help-overlay-button />
      </div>
    </div>

    <c-lesson-page-legend/>

  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import "../../node_modules/slither-slider/plugin-dist/slither-slider.css";
import CExerciseSlider from "@/components/Lespagina/ExerciseSlider";
import CAnchorImageSlider from "@/components/Lespagina/AnchorImageSlider";
import CCoinsBackdrop from "@/components/CoinsBackdrop";
import CLessonHeader from "@/components/Lespagina/LessonHeader";
import CLiana from "@/components/Liana";
import CWordListSlider from "@/components/Lespagina/WordListSlider";
import CLessonPageLegend from "@/components/Lespagina/Legend";
import HelpOverlayButton from "@/components/Base/HelpOverlayButton";

export default {
  components: {
    HelpOverlayButton,
    CLessonPageLegend,
    CWordListSlider,
    CLiana,
    CLessonHeader,
    CCoinsBackdrop,
    CAnchorImageSlider,
    CExerciseSlider,
  },
  methods: {
    ...mapActions({
      fetchStep: "lessonPage/fetchStep",
    }),
  },
  computed: {
    ...mapState("user", ["loggedIn", "user"]),
    ...mapGetters({
      step: "lessonPage/getStep",
      profile: "user/getProfile",
      difficulty: "lessonPage/getDifficulty",
      linkToWorld: "router/linkToWorld",
      words: "lessonPage/getWords",
    }),
  },
};
</script>

<style scoped lang="scss">
.jungle-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;

  .lower-section, .exercise-container {
    width: 100%;
  }

  .lower-section {
    gap: 2%;
    padding: 0;
    display: flex;
    height: 37%;
    justify-content: space-between;

    > * {
      height: 100%;
      width: 32%;
    }

    :deep(.swiper) {
      padding: 0 10%;

      .swiper-button-prev,
      .swiper-button-next {
        width: 10%;
      }

    }

    .character-container {
      width: 35%;
      position: relative;
      user-select: none;

      > * {
        position: absolute;
      }

      .kiko {
        height: 60%;
        left: 15%;
        bottom: 0;
      }

      .compass-boy {
        height: 150%;
        bottom: -15%;
        right: 0;
        z-index: 3;
      }

      :deep(.help-overlay__button) {
        height: 30%;
        bottom: 0;
        right: 0;
      }
    }
  }
}
</style>

<style lang="scss">

</style>
