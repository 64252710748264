import Vue from "vue";
import backgroundSound from "@/assets/sound/ambience/jungle-background.mp3";

const playAudio = async (type, fileName, delay, force) => {
  const endpoint = type === "part" ? "partAudio" : "wordAudio";
  await Vue.prototype.$api.endpoints[endpoint].play(
    type,
    fileName,
    delay,
    force
  );
};

const state = {
    player: {
        letter: (fileName, delay = 0, force = false) =>
            playAudio("part", fileName, delay, force),
        word: (fileName, delay = 0, force = false) =>
            playAudio("word", fileName, delay, force),
        plural: (fileName, delay = 0, force = false) =>
            playAudio("plural", fileName, delay, force),
        sound_groups: (fileName, delay = 0, force = false) =>
            playAudio("sound_groups", fileName, delay, force),
        structure: (fileName, delay = 0, force = false) =>
            playAudio("structure", fileName, delay, force),
        sentence: (fileName, delay = 0, force = false) =>
            playAudio("sentence", fileName, delay, force),
    },
    entrySound: {
        "/boomhut": new Audio(require("@/assets/sound/fx/door-open.mp3")),
    },
    effects: {
        "complete_exercise": new Audio(require("@/assets/sound/fx/complete_exercise.mp3")),
        "coins": new Audio(require("@/assets/sound/fx/coins.mp3")),
        "unlock-reward": new Audio(require("@/assets/sound/fx/unlock-reward.mp3")),
    },
    backgroundAudio: {
        "/boomhut": new Audio(
            require("@/assets/sound/ambience/jungle-background.mp3")
        ),
        "/wereld/4": new Audio(
            require("@/assets/sound/ambience/world-4-background.mp3")
        ),
    },
    currentBackground: null,
    fadeOutInterval: null,

    // startBackgroundAudio() {
    //   this.backgroundAudio?.play()
    // },
    // killBackgroundAudio() {
    //   this.backgroundAudio?.pause()
    //   this.backgroundAudio = null
    // },
    // const backgroundSound = require('@/assets/sound/effects/jungle-background.mp3');
    // this.backgroundAudio = new Audio(backgroundSound)
    //
    // document.addEventListener("click", () => {
    //   if (this.$route.name !== 'treehouse') {
    //     this.pauseBackgroundAudio()
    //   } else {
    //     this.startBackgroundAudio()
    //   }
    // }, {once: true});
    audioTimeouts: [],
    activeAudio: {
        type: null,
        audio: null,
    },
};

const mutations = {
    ADD_AUDIO_TIMEOUT(state, data) {
        state.audioTimeouts.push(data);
    },
    SET_ACTIVE_AUDIO(state, payload) {
        if (!payload) {
            state.activeAudio = {type: null, audio: null, fileName: null};
        } else {
            state.activeAudio = payload;
        }
    },
};

const actions = {
    playBackgroundMusic({state, dispatch}, path) {
        if (state.currentBackground) {
            dispatch("fadeBackgroundMusic", {
                out: true,
                audio: state.backgroundAudio[state.currentBackground],
            });
            state.currentBackground = null;
        }

        if (state.backgroundAudio[path]) {
            state.backgroundAudio[path].loop = true;
            dispatch("fadeBackgroundMusic", {
                out: false,
                audio: state.backgroundAudio[path],
            });
            state.currentBackground = path;
        }
    },
    playEntrySound({state}, path) {
        if (state.entrySound[path]) {
            state.entrySound[path].volume = 0.2;
            state.entrySound[path].play();
        }
    },
    playEffect({state}, id) {
        if (state.effects[id]) {
            state.effects[id].volume = 0.1;
            state.effects[id].play();
        }
    },
    pauseBackgroundMusic({state}) {
        Object.values(state.backgroundAudio).forEach((e) => e?.pause());
    },
    fadeBackgroundMusic({state}, {audio, out}) {
        let vol;
        let interval = 50; // 200ms interval

        if (!audio) return;

        if (!out) {
            audio.play();
            audio.vol = 0;
            vol = 0;
        } else {
            vol = 1.0;
        }

        let fadeout = setInterval(function () {
            // Reduce volume by 0.05 as long as it is above 0
            // This works as long as you start with a multiple of 0.05!
            if (out) {
                if (vol > 0) {
                    vol -= 0.05;
                    if (vol >= 0) {
                        audio.volume = vol;
                    } else {
                        audio.volume = 0;
                        audio.pause();
                    }
                } else {
                    audio.pause();
                    // Stop the setInterval when 0 is reached
                    clearInterval(fadeout);
                }
            } else {
                if (vol <= 1) {
                    vol += 0.05;
                    if (vol <= 1) {
                        audio.volume = vol;
                    } else {
                        audio.volume = 1;
                    }
                } else {
                    // Stop the setInterval when 0 is reached
                    clearInterval(fadeout);
                }
            }
        }, interval);
    },
    sendAudioRequest(obj, {fileName, type, delay = 0}) {
        Vue.prototype.$api.endpoints.wordAudio.play(type, fileName, delay);
    },
    clearAudio({commit, state}) {
        const audio = state.activeAudio.audio;
        if (audio) {
            audio.currentTime = 0;
            audio.pause();
            audio.src = "";
        }
        return commit("SET_ACTIVE_AUDIO", null);
    },
    async playAudio({dispatch}) {
        return Promise.resolve({
            word: (fileName, delay = 0) =>
                dispatch("sendAudioRequest", {fileName, delay, type: "word"}),
            sound_groups: (fileName, delay = 0) =>
                dispatch("sendAudioRequest", {fileName, delay, type: "sound_groups"}),
            structure: (fileName, delay = 0) =>
                dispatch("sendAudioRequest", {fileName, delay, type: "structure"}),
            sentence: (fileName, delay = 0) =>
                dispatch("sendAudioRequest", {fileName, delay, type: "sentence"}),
        });
    },
};

const getters = {
    getAudioPlayer(state) {
        return state.player;
    },
    isPlayingSentence(state) {
        return state.activeAudio.type === "sentence";
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
};
