import Vue from "vue";
import Step from "@/models/Step";
import Test from "@/models/exercises/Test";
import { Model } from "@/models/Model";
import { Profile } from "@/models/Profile";
import { Reward } from "@/models/Reward";
import Exercise from "@/models/exercises/Exercise";
import Word from "@/models/exercises/Word";
import store from "@/store";
import { Note } from "@/models/Note";
import { Book } from "@/models/Book";

export class Endpoint {
  name = "";
  url = null;
  model = Model.prototype;

  constructor(name, url, model) {
    this.name = name;
    this.url = url;
    this.model = model;
  }

  get(id = "", construct = true) {
    return Vue.prototype.$api.get(this.url, id).then((response) => {
      if (this.model && construct) {
        return endpoints[this.name].model.factory(response.data);
      }

      return response.data;
    });
  }

  put(id, params) {
    return Vue.prototype.$api.put(this.url, id, params).then((response) => {
      if (this.model) {
        return endpoints[this.name].model.factory(response.data);
      }

      return response.data;
    });
  }

  post(params) {
    return Vue.prototype.$api.post(this.url, params).then((response) => {
      if (this.model) {
        return endpoints[this.name].model.factory(response.data);
      }

      return response.data;
    });
  }

  delete(id) {
    return Vue.prototype.$api.delete(this.url, id);
  }

  async play(type, fileName, delay, force) {
    if (!fileName) return;
    if (!force && store.state.audio.activeAudio.type) {
      await store.dispatch("audio/clearAudio");
      return;
    }
    await store.dispatch("audio/clearAudio");

    const wordData = store.getters["exercise/getExerciseData"]?.word;
    const readLetters = wordData?.read_letters;
    const structure = wordData?.structure_audio_parts;

    if (type === "part" && structure) {
      for (const [index, structureUrl] of structure.entries()) {
        // await rootState.audio.player[playing](getters.getWord, delay, true);
        await Vue.prototype.$api.play(
          structureUrl,
          type,
          readLetters.split(",")[index] // TODO
          // index === fileName.split("").length - 1
        );
      }

      return Promise.resolve();
    } else {
      return await Vue.prototype.$api.play(this.url, type, fileName);
    }
  }
}

export const endpoints = {
  step: new Endpoint("step", "/api/v1/step/{id}", Step),
  level: new Endpoint("level", "/api/v1/level"),
  test: new Endpoint("test", "/api/v1/test/{id}", Test),
  progress: new Endpoint("progress", "/api/v1/progress", null),
  me: new Endpoint("me", "/api/v1/user/me/", Profile),
  note: new Endpoint("note", "/api/v1/note/{id}", Note),
  book: new Endpoint("book", "/api/v1/book/{id}", Book),
  'book-read': new Endpoint("book-read", "/api/v1/book-read/{id}", Book),
  repeatable: new Endpoint("repeatable", "/api/v1/step/repeatable", Step),
  activity: new Endpoint("activity", "/api/v1/activity", null), // Model useful? If so, TODO
  // buddy: new Endpoint("me", "/api/v1/user/me", Profile),
  reward: new Endpoint("reward", "/api/v1/reward/", Reward),
  buyReward: new Endpoint("reward", "/api/v1/reward/buy", null),
  exercise: new Endpoint("exercise", "/api/v1/exercise/{id}", Exercise),
  reset: new Endpoint("reset", "/api/v1/exercise/reset/{id}", Exercise),
  word: new Endpoint("word", "/api/v1/word/{id}", Word),
  wordAudio: new Endpoint("word", "/word/{type}/{file_name}.mp3", null),
  pluralAudio: new Endpoint("plural", "/word/{type}/{file_name}.mp3", null),
  partAudio: new Endpoint("part", "/word/parts/{file_name}.mp3", null),
  userReport: new Endpoint("user-report", "/api/v1/user-report", null),
};
