<template>
  <div class="hint-container">
    <div class="avatar">
      <img
          src="../../../assets/KikoHints.gif"
          @click="setHintVisibility(true)"
          alt="kiko-hint"
      />
    </div>

    <transition name="hint-modal-fade">
      <div
          v-if="hintVisible"
          class="hint-modal"
          role="dialog"
          aria-labelledby="hint-modalTitle"
          aria-describedby="hint-modalDescription"
      >
        <div class="hint-modal-header" id="hint-modalTitle">
          <slot name="header"></slot>
        </div>

        <div class="hint-modal-body" id="hint-modalDescription">
          <img
              src="../../../assets/close-modal.png"
              alt="kiko-modal"
              @click="setHintVisibility(false)"
              aria-label="Close modal"
              class="btn-hint-close"
          />
          <slither-slider
              :options="{
                dots: false,
              }"
          >
            <div v-for="(slide, index) in [0,1]" :key="index" class="slide-container">
              <h3>{{ slide === 0 ? "Tip" : "Hint" }}</h3>
              <p>{{ slide === 0 ? anchorTip : anchorHint }}</p>
              <img :src="anchorImage" alt="anchor-image" class="anchor-image"/>
            </div>
            <template slot="previous">
              <p class="previous slider-arrow">‹</p>
            </template>
            <template slot="next">
              <p class="next slider-arrow">›</p>
            </template>
          </slither-slider>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

export default {
  name: "c-hint-avatar",
  props: {},
  methods: {
    ...mapMutations({
      SET_HINT_VISIBILITY: "exercise/SET_HINT_VISIBILITY",
    }),
    ...mapActions({
      useHint: "exercise/useHint",
    }),
    setHintVisibility(payload) {
      this.SET_HINT_VISIBILITY(payload);
      if (payload) {
        this.useHint();
      }
    },
  },
  computed: {
    ...mapGetters({
      anchorImage: "exercise/getAnchorImage",
      anchorHint: "exercise/getAnchorHint",
      anchorTip: "exercise/getAnchorTip",
    }),
    ...mapState({
      hintVisible: (state) => state.exercise.hintVisible,
    }),
  },
};
</script>

<style scoped lang="scss">
.avatar {
  aspect-ratio: 3 / 2;
  height: 35%;
  position: absolute;
  display: flex;
  float: right;
  right: 0;
  bottom: 0;
  margin: 25px 0;
  cursor: pointer;
  pointer-events: none;
}

.avatar img {
  pointer-events: all;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 90;
}

.hint-modal {
  z-index: 99;
  background: #fff;
  border-radius: 25px;
  border: 2px solid green;
  width: 500px;
  box-shadow: -5px -5px 4px 2px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 0;
  right: 0;
}

.hint-modal-header {
  display: flex;
  z-index: 999;
  position: absolute;
  color: #4aae9b;
  justify-content: space-between;
}

.hint-modal-body {
  z-index: 999;
  height: 100%;
  padding: 30px;
  position: relative;
}

.hint-modal-body img {
  max-width: 50%;
}

.hint-modal-body p {
  //font-size: 20px !important;
}

.hint-modal-body h3 {
  color: #016d47 !important;
}

.hint-modal-footer .v-btn {
  max-width: 20%;
  margin: 0 auto;
}

.anchor-image {
  position: relative;
  float: right;
}

.btn-hint-close {
  z-index: 10;
  position: absolute;
  width: 62px !important;
  height: 62px !important;
  padding: 5px;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  margin: 0;
  width: 28px;
  font-size: 300% !important;
  cursor: pointer;

  &.previous {
    left: 0;
    transform: translate(-100%, -50%);
  }

  &.next {
    right: 0;
    transform: translate(100%, -50%);
  }
}

.slide-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
</style>

<style>
.hint-modal-fade-enter,
.hint-modal-fade-leave-to {
  opacity: 0;
}

.hint-modal-fade-enter-active,
.hint-modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>