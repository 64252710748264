<template>
  <div class="library-background">
    <div class="library-container">
      <go-back @click="$router.push('treehouse')"/>
      <div class="page-title">
        <h1>Boekenplank</h1>
      </div>
      <div class="library-content">
        <div class="books-list-container">
          <div class="books-list-header">
            <h2 class="books-list-header-item">Deze boeken heb ik al gelezen:</h2>
            <h2 class="books-list-header-item" v-if="booksRead.length > 0">Cijfer:</h2>
          </div>
          <div class="books-list">
            <div class="books-row" v-if="booksRead.length === 0">
              <p>Er zijn nog geen boeken toegevoegd.</p>
            </div>
            <div class="books-row" v-for="(book, index) in booksRead" :key="index">
              <div class="book-icon-wrapper">
                <img src="../assets/Icoon_boek.png" alt="boek" class="book-icon"/>
              </div>
              <div class="book-info">
                <p class="book-title">{{ index + 1 }}.   {{ book.title }}</p>
                <p class="book-review">{{ book.review ?? "-" }}</p>
              </div>
              <div class="book-button-delete">
                <v-btn
                    height="50px"
                    width="50px"
                    color="#E95F56"
                    fab
                    depressed
                    @click="deleteBook(book.id)"
                    :disabled="deleted.some(s => s === book.id)"
                >
                  <v-icon color="#fff">mdi-trash-can-outline</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <div class="add-book-wrapper">
          <h2 class="add-book-header">Heb je nog een boek gelezen? Voeg hem toe!</h2>
          <div class="add-book">
            <v-text-field
                class="add-book-field"
                v-model="title"
                label="Wat is de titel van het boek?"
                type="text"
                background-color="#fff"
                required
                solo
                text
                validate-on-blur
            ></v-text-field>

            <v-select
                class="add-book-field"
                :items="Array.range(10, 1)"
                v-model="review"
                label="Welk cijfer geef je het?"
                type="number"
                background-color="#fff"
                required
                solo
                menu-props="rounded"
                text
                validate-on-blur
            ></v-select>

            <v-btn
                class="add-book-button"
                height="50px"
                width="50px"
                color="#016C49"
                fab
                depressed
                @click="addBook({title, review})"
            >
              <v-icon color="#ffffff">
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import GoBack from "@/components/GoBack"
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    GoBack
  },
  data: () => ({
    title: null,
    review: null,
    deleted: []
  }),
  computed: {
    ...mapGetters({
      books: "user/getBooks",
      booksRead: "user/getBooksRead",
    })
  },
  methods: {
    ...mapActions({
      addBook: "user/addBookRead",
      deleteBookAction: "user/deleteBookRead"
    }),
    deleteBook(id) {
      this.deleted.push(id)
      this.deleteBookAction(id).then(() => this.deleted = this.deleted.filter(f => f !== id))
    }
  },
  watch: {
    booksRead() {
      this.title = null;
      this.review = null;
    }
  }
}
</script>

<style scoped lang="scss">
.library-background {
  background: url("../assets/boekenplank.png") no-repeat;
  background-size: cover;
  height: calc(100vh - 80px);
  width: 100vw;
  padding: 120px 20px 20px 20px;
  position: fixed;
  top: 80px;
  overflow: auto;
}

.library-container {
  background: #F8F4E0;
  border-radius: 5px;
  margin: auto;
  height: calc(100% - 80px);
  width: calc(100vw - 260px);
  position: relative;
  max-height: 1096px;
  min-height: 800px;
  max-width: 1452px;
  min-width: 650px;

  .back-btn {
    top: -95px;
    left: 0;
  }

  .library-content {
    height: 100%;
    overflow: auto;
    padding: 2% 3% 0;
    position: relative;

    .books-list-header {
      display: flex;

      .books-list-header-item:first-child {
        width: 60%;
      }
    }

    .books-list-header .books-list-header-item, .add-book-wrapper .add-book-header {
      color: #016D47;
      margin-bottom: 20px;
      text-align: left;
    }
  }
}

.books-list-container {
  margin: 20px 0;
  border-radius: 0.5rem;

  .books-list {
    background: white;
    border-radius: 0.5rem;

    .books-row {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 10px 20px;
      gap: 3%;
      position: relative;

      .book-icon-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .book-icon-wrapper, .book-icon-wrapper .book-icon {
        width: 100px;
      }

      .book-info {
        display: flex;
      height: 100%;
      align-items: flex-start;
      flex-direction: column;

        .book-title, .book-review {
          padding: 0;
          margin: 0;
          color: #3aac2c;
          font-size: 1.2rem;
          white-space: nowrap;
          min-width: 10%;
          width: 50%;
        }

        .book-review {
          position: absolute;
          left: 60%;
          text-align: left;
        }
      }

    .book-button-add {
      width: 100%;

      button {
        float: right;
      }
    }

    .book-button-delete {
      width: 100%;

      button {
        float: right;
      }
    }
  }
  }
}

.add-book-wrapper {
  position: sticky;
  left: 0;
  right: 0;
  background: #f8f4e0;
  padding: 20px 0 5px;
  bottom: 0;

  .add-book {
    display: flex;
    width: 100%;
  }

  .add-book-field {
    border-radius: 25px;

    :deep(input), :deep(.v-select__selection) {
      font-size: 20px;
    }

    &:nth-child(1) {
      max-width: 50%;
      min-width: 277px;
    }

    &:nth-child(2) {
      margin: 0 20px;
      max-width: 20%;
      min-width: 232px;
    }

    :deep(.v-select__selection) {
      width: 100%;
      max-width: 100% !important;;
      text-align: center;
    }
  }

  .add-book-button {
    box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 30%);
  }
}
</style>

<style lang="scss">
</style>