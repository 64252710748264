<template>
  <div
      class="scoreboard__dropdown"
      v-if="isLoggedIn && isIntroductionCompleted"
      :class="scoreboardActive"
  >
    <ScoreboardContent @change="$emit('change')" />
    <button
        @click="$emit('change', !open)"
        class="scoreboard-btn"
    >
      <img
          class="scoreboard-trophy"
          src="../../assets/img/topmenu/trophy.png"
          alt="trophy"
      />
      Scorebord
    </button>
  </div>
</template>

<script>
import ScoreboardContent from "@/components/TopMenu/ScoreboardContent";
import {mapGetters} from "vuex";

export default {
  name: "ScoreboardDropdown",
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  components: {ScoreboardContent},
  computed: {
    ...mapGetters({
      helpOverlayStep: "helpOverlay/currentStep",
      isLoggedIn: "user/isLoggedIn",
      isIntroductionCompleted: "user/isIntroductionCompleted",
    }),
    isVisible() {
      return this.$store.state.helpOverlay.isVisible
    },
    helpOverlayStep() {
      return this.$store.state.helpOverlay.step
    },
    scoreboardActive() {
      if (this.open && !this.isVisible) {
        return {active: true}
      }

      if (this.isVisible && this.$route.name === 'world') {
        if (this.helpOverlayStep === 3) {
          return {active: true, 'help-overlay__active-item': true}
        }
        if (this.helpOverlayStep === 4) {
          return {'transition_out': true}
        }
      }

      return {}
    }
  },
}
</script>

<style scoped lang="scss">
.scoreboard-trophy {
  width: 25px;
  padding-bottom: 5px;
  vertical-align: middle;
}

.scoreboard-btn {
  display: block;
  background: #006c49;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: white;
  font-weight: bold;
  padding: 20px 20px 10px 20px;
  margin: -2px auto;
  box-shadow: -2px 3px 3px #8e8e8e, 2px 3px 3px rgba(0, 0, 0, 0.5);
  border-left: 2px solid white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  align-items: center;
}

.scoreboard__dropdown {
  display: block;
  position: absolute;
  margin: auto;
  left: 50%;
  z-index: 99;
  transform: translate(-50%, -363px);
  transition: transform 1s !important;
  pointer-events: none;

  &.active {
    transform: translate(-50%, 0);
  }

  * {
    pointer-events: all;
  }
}

.faded {
  .scoreboard__dropdown:not(.help-overlay__active-item) {
    filter: brightness(50%);
  }
}
</style>