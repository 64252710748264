<template>
  <div class="restricted-input-container" :class="{hideable}">
    <v-text-field
        ref="typing-input"
        class="centered typing-input"
        :class="{ 'wrong-style': wrongStyle, null: !wrongStyle }"
        rounded
        white
        hide-details="true"
        :value="input"
        @keypress="handleKeyPress"
        @keydown.backspace.prevent="backspace"
    />
    <c-hide-button v-if="hideable"/>

    <c-input-hints v-if="hasInputHints"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CHideButton from "@/components/Exercise/Components/HideButton";
import CInputHints from "@/components/Exercise/Components/InputHints";

export default {
  name: "CRestrictedInput",
  components: {CInputHints, CHideButton},
  props: ['value', "hideable"],
  data: () => ({
    input: this?.value,
    wrongStyle: false,
  }),
  mounted() {
    document.querySelectorAll('input').forEach((input) => {
      input.setAttribute('spellcheck', 'false');
    })
  },
  methods: {
    ...mapActions({
      checkRestrictedInput: "exercise/checkRestrictedInput",
      incrementErrors: "exercise/incrementErrors",
      wrongInput: "exercise/wrongInput",
    }),
    backspace() {
      this.input = this.input?.slice(0, -1)
      this.$emit("input", this.input)
    },
    handleKeyPress(event) {
      this.checkRestrictedInput({event, input: this.input}).then(({input}) => {
        // if (!isAllowed) this.wrongInput(this.$refs["typing-input"].$el)
        this.input = input;
        this.$emit("input", input)
      })
    },
  },
  computed: {
    ...mapGetters({
      progressWord: "exercise/getProgressWord",
      progressStep: "exercise/getProgressStep",
      getErrors: "exercise/getErrors",
      hasInputHints: "template/hasInputHints",
    })
  },
  watch: {
    value(value) {
      this.input = value;
    },
    progressStep(newValue) {
      this.input = "";
      this.$emit("input", "")
      if (newValue) {
        this.$refs["typing-input"].focus({
          preventScroll: true
        });
      }
    },
    progressWord() {
      this.input = "";
      this.$emit("input", "")
    },
    getErrors() {
      this.wrongInput(this.$refs["typing-input"].$el)
    }
  }
};
</script>

<style scoped lang="scss">

.hide-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.input-hint__container {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.v-input {
  border: 2px solid #3bad2c;
  box-shadow: 5px 5px 2px -3px rgba(0, 0, 0, 0.25);
  margin: 0 !important;
  background: white;
  flex-basis: 100%;
}

input {
  max-height: 47px !important;
}

//margin: -8px 0 0;

.typing-input {
  input {
    text-align: center;
  }
}

.wrong-style {
  animation: shake 0.35s ease-in-out 0s 1;
}

.v-input {
  border: 2px solid #3bad2c;
  box-shadow: 5px 5px 2px -3px rgba(0, 0, 0, 0.25);
  margin: 20px 0;
  background: white;
  flex-basis: 100% !important;
}

.typing-input ::v-deep {
  input {
    text-align: center;
  }
}

.restricted-input-container.hideable {
  ::v-deep {
    .v-input__control {
      display: flex;
      align-content: center;
    }
    .v-input__slot {
      padding: 0;
      width: calc(100% - 35%);
    }
  }
}

.verdubbelaar {
  border-radius: 50%;
  padding: 3px;
  right: 15px;
  top: 33px;
  position: absolute;
  z-index: 9999;
}
</style>

<style lang="scss">
</style>
