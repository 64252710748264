<template>
  <div class="debug-panel-container" v-if="this.debugPanelVisibility">
    <div :class="{ 'debug-panel': true, active: this.devPanel }">
      <p v-if="$route">ROUTE NAME: {{ $route.name }}</p>
      <p>WORLD: {{ activeWorld }}</p>

      <p @click="clearTokens()">CLEAR TOKEN</p>

      <p v-if="exercise" class="clickable">
        TEMPLATE:
        <strong @click="copyToClipboard(template)">{{ template }}</strong>
      </p>

<!--      <p v-if="progress && (test || exercise)">-->
<!--        PROGRESS:<strong>{{ progress }}</strong>-->
<!--      </p>-->

      <p v-if="formattedExpectedInput" class="expected-input">
        EXPECTED INPUT: <strong v-html="formattedExpectedInput"></strong>
      </p>

      <p v-if="test || exercise">
        CURRENT INPUT: <strong>{{ currentInput }}</strong>
      </p>

      <p v-if="isLessonPage">
        CURRENT INDEX <strong>{{ getSliderIndex }}</strong>
      </p>

<!--      <div v-if="words" @click="toggleWordState" :class="{clickable: true, openable: true, open: wordsState} ">-->
<!--        <div class="words">-->
<!--          <div :key="index" v-for="(word, index) in words">{{ word }}</div>-->
<!--        </div>-->
<!--        <p>WORDS</p>-->
<!--      </div>-->

      <div v-if="exercise" @click="resetExercise" class="clickable">
        <p>REPEAT</p>
      </div>
      <div v-if="test || exercise" @click="previousStep" class="clickable">
        <p>PREV STEP</p>
      </div>
      <div v-if="test || exercise" @click="nextStep" class="clickable">
        <p>NEXT STEP</p>
      </div>

      <div class="button-container" v-if="word">
        <div>
          <v-btn @click="inputWrong()" color="error">INPUT</v-btn>
          <v-btn @click="answerWrong()" color="error" class="next-question">▶</v-btn>
        </div>

        <div>
          <v-btn @click="inputRight()" color="success">INPUT</v-btn>
          <v-btn @click="answerRight()" color="success" class="next-question">▶</v-btn>
        </div>
      </div>

<!--      <p>-->
<!--        {{ getFormattedTimer }} ({{ getFormattedTime }})-->
<!--      </p>-->
    </div>
    <div @click="toggle" class="toggle">
      <div v-if="this.devPanel">›</div>
      <div v-if="!this.devPanel">‹</div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

export default {
  name: "CDebugPanel",
  data: () => ({
    wordsState: false,
    posting: false
  }),
  methods: {
    ...mapActions({
      continueExercise: "exercise/continueExercise",
      setAccessToken: "auth/setAccessToken",
      clearTokens: "auth/clearTokens",
      resetExercise: "exercise/resetExercise",
      nextStep: "exercise/nextStep",
      previousStep: "exercise/previousStep",
    }),
    ...mapMutations({
      toggle: "TOGGLE_DEV_PANEL",
      setCurrentInput: "exercise/SET_CURRENT_INPUT",
    }),
    copyToClipboard(string) {
      return navigator.clipboard.writeText(string);
    },
    toggleWordState() {
      this.wordsState = !this.wordsState;
    },
    inputWrong() {
      this.setCurrentInput({word: "WRONG!"})
    },
    inputRight() {
      this.setCurrentInput(this.expectedInput ?? {word: this.word})
    },
    answerWrong() {
      if (!this.posting) {
        this.posting = true
        this.inputWrong();
        this.continueExercise().then(() => this.posting = false);
      }
    },
    answerRight() {
      if (!this.posting) {
        this.posting = true
        this.inputRight();
        this.continueExercise().then(() => this.posting = false);
      }
    },
  },
  computed: {
    ...mapGetters({
      expectedInput: "debug/getExpectedInput",
      currentInput: "exercise/getCurrentInput",
      exercise: "exercise/getExercise",
      test: "exercise/getTest",
      template: "template/getCurrentTemplate",
      progress: "exercise/getProgress",
      exerciseWords: "exercise/getWords",
      lessonPageWords: "lessonPage/getWords",
      word: "exercise/getWord",
      getFormattedTimer: "timer/getFormattedTimer",
      getFormattedTime: "timer/getFormattedTime",
      isLessonPage: "router/isLessonPage",
      isExercise: "router/isExercise",
      isTemplate: "template/isTemplate",
      getSliderIndex: "lessonPage/getSliderIndex",
      activeWorld: "world/getActiveWorld",
    }),
    ...mapState({
      devPanel: (state) => state.devPanel,
      debugPanelVisibility: (state) => state.debugPanelVisibility,
    }),
    words() {
      if (this.exerciseWords?.length > 0) return this.exerciseWords
      if (this.lessonPageWords?.length > 0) return this.lessonPageWords

      return [];
    },
    formattedExpectedInput() {
      if (!this.expectedInput) return null;
      let expectedInput = Object.assign({}, this.expectedInput);

      if (
          this.isTemplate("Blokken") ||
          this.isTemplate("SpeurKleur1") ||
          this.isTemplate("SpeurKleur2")
      ) {
        const colors = [];
        expectedInput?.colors?.forEach((e) => {
          if (e) {
            colors.push(
                `<div class=color-btn style='background-color: ${e}'></div>`
            );
          }
        });
        expectedInput.colors = colors;
      }

      return expectedInput;
    },
  },
};
</script>

<style scoped lang="scss">
.debug-panel-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  pointer-events: none;

  .debug-panel {
    background: white;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 50px;
    transition: transform 1s;
    transform: translateX(100%);
    flex-wrap: wrap;
    padding: 0 20px;
    pointer-events: all;

    * {
      margin: 0;
      padding: 0;
    }

    p, strong {
      font-size: 15px !important;
    }

    .next-question {
      width: 50px;
      min-width: 50px !important;
      padding: 0 !important;
      margin-left: 10px;
    }

    .openable {
      cursor: pointer;
      display: flex;
      justify-content: space-around;

      p:after {
        content: "▲";
      }

      &.open p:after {
        content: "▼";
      }

      .words {
        position: absolute;
        bottom: 100%;
        max-height: 500px;
        height: 0;
        transition: all 1s;
        overflow: scroll;
        background: white;
        width: 300px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;

        div {
          margin: 10px;
          list-style: none;
        }
      }

      &.open .words {
        height: auto;
      }
    }

    .clickable {
      cursor: pointer;
    }

    &.active {
      transform: translateX(0);
      margin-right: 50px;
      pointer-events: all;
    }

    .v-btn.error {
      background: red;
    }

    .v-btn.success {
      background: green;
    }

    p {
      line-height: 50px;
      height: 50px;
      display: flex;

      strong {
        display: flex;
        cursor: pointer;
        margin-left: 10px;
        font-size: 16px;
      }
    }
  }
}



.toggle {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 50px;

  pointer-events: all;
  cursor: pointer;
  background: white;
}

.button-container {
  position: fixed;
  bottom: 50px;
  display: flex;
  left: 50%;
  translate: -50%;

  > div {
    margin: 10px;
  }
}

:deep(.color-btn) {
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
  aspect-ratio: 1/1 !important;
  border-radius: 50%;
  outline: 2px solid white;
  margin: auto;
}
</style>
