<template>
  <div class="exercise-content">
    <c-exercise-progress-bar />

    <div class="exercise-template">
      <component :is="getContent" :key="getCurrentTemplate"/>
    </div>

    <c-speakers v-if="hasSpeakers"/>
    <c-hint-avatar v-if="hasHint"/>
  </div>
</template>

<script>
import CHintAvatar from "@/components/Exercise/Components/HintAvatar";
import CExerciseProgressBar from "@/components/Exercise/ExerciseProgressBar";
import {mapActions, mapGetters} from "vuex";
import CSpeakers from "@/components/Exercise/Components/Speakers";

export default {
  name: 'c-exercise-content',
  components: {CSpeakers, CExerciseProgressBar, CHintAvatar},
  data: () => ({
    enterListener: null
  }),
  methods: {
    ...mapActions({
      nextWord: "exercise/nextWord",
    }),
  },
  computed: {
    ...mapGetters({
      currentInput: "exercise/getCurrentInput",
      getContent: "template/getContent",
      isTest: "router/isTest",
      hasHint: "template/hasHint",
      hasSpeakers: "template/hasSpeakers",
      isInitializing: "isInitializing",
      getCurrentTemplate: "template/getCurrentTemplate",
    }),
  }
}
</script>

<style scoped>
.exercise-content {
  position: relative;
  height: 100%;
  /*bottom: 0;*/
  /*top: 0;*/
  /*width: 100%;*/
}

.exercise-template {
  margin: 20px 0 0;
  height: calc(100% - 50px);
  /*left: 50%;*/
  /*position: relative;*/
  /*transform: translateX(-50%);*/
}
</style>
