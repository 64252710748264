import { render, staticRenderFns } from "./ScoreboardModal.vue?vue&type=template&id=310cd2de&scoped=true"
import script from "./ScoreboardModal.vue?vue&type=script&lang=js"
export * from "./ScoreboardModal.vue?vue&type=script&lang=js"
import style0 from "./ScoreboardModal.vue?vue&type=style&index=0&id=310cd2de&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310cd2de",
  null
  
)

export default component.exports