<template>
  <div class="legend-wrapper" ref="legend-wrapper">
    <div :class="{ legend: true }">
        <div class="legend-item-list">
          <div class="legend-item">
            <img
                src="@/assets/img/exercises/exercise_open.png"
                alt="step_open"
            />
            <p>Stap open</p>
          </div>
          <div class="legend-item">
            <img
                src="@/assets/img/exercises/exercise_closed-1.png"
                alt="step_closed"
            />
            <p>Stap nog op slot</p>
          </div>
          <div class="legend-item">
            <img
                src="@/assets/img/exercises/exercise_passed.png"
                alt="step_completed"
            />
            <p>Met stappentoets  gehaald</p>
          </div>
          <div class="legend-item">
            <img
                src="@/assets/img/exercises/exercise-passed-3.png"
                alt="step_completed"
            />
            <p>Stap gedaan én gehaald</p>
          </div>
          <div class="legend-item">
            <img
                src="@/assets/img/exercises/exercise_test-6.png"
                alt="step_test"
            />
            <p>Stappentoets</p>
          </div>
          <div class="legend-item">
            <img
                src="@/assets/img/exercises/difficulty-3-stars.png"
                alt="step_difficulty"
            />
            <p>Kies je niveau</p>
        </div>
      </div>
    </div>
    <div @click="toggleLegend" class="legend-btn"><p>Uitleg stappen</p></div>
  </div>
</template>

<script>
import VueSlideUpDown from "vue-slide-up-down";

export default {
  name: "CWorldLegend",
  components: {
    VueSlideUpDown,
  },
  data: () => ({
    legendActive: false,
  }),
  methods: {
    toggleLegend() {
      this.legendActive = !this.legendActive;
    },
  },
  computed: {
    isVisible() {
      return this.$store.state.helpOverlay.isVisible
    },
    helpOverlayStep() {
      return this.$store.state.helpOverlay.step
    }
  },
  watch: {
    helpOverlayStep(newValue) {
      if (this.isVisible) {
        this.legendActive = newValue === 1
      }
    },
    legendActive(newValue) {
      if (newValue) {
        this.$refs['legend-wrapper'].classList.add('active')
      }

      if (!newValue) {
        this.$refs['legend-wrapper'].classList.remove('active')
      }
    }
  }
};
</script>

<style scoped lang="scss">
.legend-wrapper {
  position: absolute;
  right: 0;
  top: 50%;
  user-select: none;
  transform-origin: top right;
  overflow: hidden;
  pointer-events: none;
  height: 500px;
  width: 25%;
  z-index: 102;
  padding: 2px;
  margin-right: -2px;
  transform: translate(calc(100% - 50px), -50%);
  transition: transform 1s;

  &.active {
    transform: translate(0, -50%);
  }
}

.legend {
  padding: 10px;
  width: calc(100% - 50px);
  height: 100%;
  background: #006c49;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: margin 1s, border-width 1s, box-shadow 1s;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  outline: 2px solid white;
  margin-left: 50px;

  &.active {
    box-shadow: 0px 0px 20px 5px #4e4e4e;
    margin-top: 0px;
  }
}

.legend-btn {
  display: block;
  background: #006c49;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  color: white;
  font-weight: bold;
  padding: 10px 20px 10px 20px;
  align-items: center;
  position: absolute;
  transform: translateY(-50%);
  margin: 0 2px;
  pointer-events: all;
  cursor: pointer;
  width: 50px;
  height: 160px;
  left: 0;
  top: 50%;
  overflow: hidden;

  &.active {

  }

  p {
    color: white;
    position: relative;
    font-size: 16px;
    width: 120px;
    margin: 0;
    top: 50%;
    transform: translate(-44% ,-50%) rotate(-90deg);
  }

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-left: 2px solid white;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
}

.legend-content {
  margin: -5px;
  position: relative;
  top: 0;
  z-index: 1;
}

.legend-item-list {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;

  img {
    width: 60px !important;
    margin-right: 20px;
  }

  .legend-item {
    display: flex;
    align-items: center;
    color: white;
    font-weight: bold;

    p {
      flex-grow: 1;
      text-align: left;
      color: white;
      font-size: 1vw;
      margin: 0;
    }
  }
}

.faded {
  .legend-wrapper:not(.help-overlay__active-item) {
    filter: brightness(.5);
  }
}
</style>
