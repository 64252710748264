import { Model } from "@/models/Model";

/**
 * @namespace
 */
export class Student extends Model {
  constructor(
    {
      buddy_id,
      created_at,
      date_of_birth,
      dl,
      dle,
      end_reward_1_collected,
      end_reward_2_collected,
      end_reward_3_collected,
      end_reward_4_collected,
      end_reward_win_code,
      first_name,
      gender,
      level_id,
      identifier,
      introduction_completed,
      last_name,
      name,
      num_words,
      phase_id,
      points,
      screen_name,
      updated_at,
      user_manual_flow,
      user_id,
    },
    readOnly = true
  ) {
    super();
    this._buddy_id = buddy_id;
    this._created_at = created_at;
    this._date_of_birth = date_of_birth;
    this._dl = dl;
    this._dle = dle;
    this._end_reward_1_collected = end_reward_1_collected;
    this._end_reward_2_collected = end_reward_2_collected;
    this._end_reward_3_collected = end_reward_3_collected;
    this._end_reward_4_collected = end_reward_4_collected;
    this._end_reward_win_code = end_reward_win_code;
    this._first_name = first_name;
    this._gender = gender;
    this._level_id = level_id;
    this._identifier = identifier;
    this._introduction_completed = introduction_completed;
    this._last_name = last_name;
    this._name = name;
    this._num_words = num_words;
    this._phase_id = phase_id;
    this._points = points;
    this._screen_name = screen_name;
    this._updated_at = updated_at;
    this._user_manual_flow = user_manual_flow;
    this._user_id = user_id;
    this._readOnly = readOnly;

    if (readOnly) Object.freeze(this);
  }

  get buddy_id() {
    return this._buddy_id;
  }

  set buddy_id(value) {
    this._buddy_id = value;
  }

  get created_at() {
    return this._created_at;
  }

  set created_at(value) {
    this._created_at = value;
  }

  get date_of_birth() {
    return this._date_of_birth;
  }

  set date_of_birth(value) {
    this._date_of_birth = value;
  }

  get dl() {
    return this._dl;
  }

  set dl(value) {
    this._dl = value;
  }

  get dle() {
    return this._dle;
  }

  set dle(value) {
    this._dle = value;
  }

  hasEndReward(number) {
    return !!this[`end_reward_${number}_collected`];
  }

  collectedRewards() {
    return Array.range(4, 1).reduce((acc, cur) => {
      if (this.hasEndReward(cur)) {
        acc.push(cur);
      }
      return acc;
    }, []);
  }

  get end_reward_1_collected() {
    return this._end_reward_1_collected;
  }

  set end_reward_1_collected(value) {
    this._end_reward_1_collected = value;
  }

  get end_reward_2_collected() {
    return this._end_reward_2_collected;
  }

  set end_reward_2_collected(value) {
    this._end_reward_2_collected = value;
  }

  get end_reward_3_collected() {
    return this._end_reward_3_collected;
  }

  set end_reward_3_collected(value) {
    this._end_reward_3_collected = value;
  }

  get end_reward_4_collected() {
    return this._end_reward_4_collected;
  }

  set end_reward_4_collected(value) {
    this._end_reward_4_collected = value;
  }

  get end_reward_win_code() {
    if (this._end_reward_win_code) {
      return this._end_reward_win_code
        .toString()
        .split("")
        .map((m) => parseInt(m));
    }

    return this._end_reward_win_code;
  }

  get first_name() {
    return this._first_name;
  }

  set first_name(value) {
    this._first_name = value;
  }

  get gender() {
    return this._gender;
  }

  set gender(value) {
    this._gender = value;
  }

  get level_id() {
    return this._level_id;
  }

  set level_id(value) {
    this._level_id = value;
  }

  get identifier() {
    return this._identifier;
  }

  set identifier(value) {
    this._identifier = value;
  }

  get introduction_completed() {
    return this._introduction_completed;
  }

  set introduction_completed(value) {
    this._introduction_completed = value;
  }

  get last_name() {
    return this._last_name;
  }

  set last_name(value) {
    this._last_name = value;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get num_words() {
    return this._num_words;
  }

  set num_words(value) {
    this._num_words = value;
  }

  /**
   * @type {number}
   */
  get phase_id() {
    return this._phase_id;
  }

  set phase_id(value) {
    this._phase_id = value;
  }

  get points() {
    return this._points;
  }

  set points(value) {
    this._points = value;
  }

  get screen_name() {
    return this._screen_name;
  }

  set screen_name(value) {
    this._screen_name = value;
  }

  get updated_at() {
    return this._updated_at;
  }

  set updated_at(value) {
    this._updated_at = value;
  }

  get user_manual_flow() {
    return this._user_manual_flow;
  }

  set user_manual_flow(value) {
    this._user_manual_flow = value;
  }

  get user_id() {
    return this._user_id;
  }

  set user_id(value) {
    this._user_id = value;
  }

  get readOnly() {
    return this._readOnly;
  }

  set readOnly(value) {
    this._readOnly = value;
  }
}
