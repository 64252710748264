<template>
  <div class="speaker-icons">
        <div
            class="speaker"
            :class="{disabled: !speaker.has}"
            v-for="speaker in speakers"
            :key="speaker.type"
            @click.prevent="speaker.play()"
        >
          <img
              :src="speakerIcon"
              alt="speaker-icon"
              :class="{ active: activeAudio.type === speaker.type }"
          />
          <p>{{speaker.label}}</p>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "CSpeakers",
  methods: {
    playWord() {
      this.player.word(this.getWord);
    },
    word() {
      return {
        type: 'word',
        has: true,
        use: this.isTest || !!this.exercise?.use_audio_word,
        label: "Woord",
        play: () => this.player.word(this.getWord)
      }
    },
    soundGroup() {
      return {
        type: 'sound_groups',
        has: !!this.studentExercise?.exercise_data?.word?.audio_sound_groups,
        use: !!this.exercise?.use_audio_sound_groups,
        label: "Klankgroepen",
        play: () => this.player.sound_groups(this.getWord)
      }
    },
    plural() {
      return {
        type: 'plural',
        has: !!this.studentExercise?.exercise_data?.word?.audio_plural,
        use: !!this.exercise?.use_audio_plural,
        label: "Verlengen",
        play: () => this.player.plural(this.getWord)
      }
    },
    structure() {
      return {
        type: 'part',
        has: !!this.studentExercise?.exercise_data?.word?.audio_structure,
        use: !!this.exercise?.use_audio_structure,
        label: "Klanken",
        play: () => this.player.letter(this.getWord, 1300)
      }
    },
    sentence() {
      return {
        type: 'sentence',
        has: this.isTest || !!this.studentExercise?.exercise_data?.word?.audio_sentence,
        use: this.isTest || !!this.exercise?.use_audio_sentence,
        label: "Zin",
        play: () => this.player.sentence(this.getWord)
      }
    },
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      studentExercise: "exercise/getCurrentStudentExercise",
      correctResults: "exercise/getCorrectResults",
      incorrectResults: "exercise/getIncorrectResults",
      wordGetter: "exercise/getWord",
      isTest: "router/isTest",
      isTemplate: "template/isTemplate",
    }),
    ...mapState({
      player: (state) => state.audio.player,
      activeAudio: (state) => state.audio.activeAudio,
    }),
    speakers() {
      return [
        this.word(),
        this.soundGroup(),
        this.structure(),
        this.plural(),
        this.sentence(),
      ].filter(f => f.use).reverse()
    },
    getWord() {
      return this.wordGetter;
    },
    speakerIcon() {
      return require("../../../assets/speakericon.png")
    },
  },
};
</script>

<style scoped lang="scss">
.speaker-icons {
  gap: 2%;
  width: 20%;
  height: 50%;
  position: absolute;
  bottom: -20px;
  display: flex;
  left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
  flex-direction: column-reverse;

  .speaker {
    display: flex;
    gap: 5%;
    height: 14%;
    align-items: center;

    p {
      font-size: 1rem;
    }

    img {
      aspect-ratio: 1/1;
      width: 18%;
      pointer-events: all;
      transition: transform 0.5s;
    }

    &.disabled {
      img {
        opacity: .5;
        pointer-events: none;
      }
    }
  }

  .speaker:not(.disabled) {
    cursor: pointer;
  }

  .active {
    transform: scale(1.3);
  }
}
</style>
