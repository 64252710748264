const state = {
  route: null,
};

const actions = {};

const mutations = {
  SET_ROUTE(state, payload) {
    state.route = payload;
  },
};

const getters = {
  getRoute(state) {
    return state.route;
  },
  getRouteID(state, getters, rootState, rootGetters) {
    let id;
    if (rootGetters["router/isTest"]) {
      id = rootGetters["router/getRoute"]?.params.test_id;
    }
    if (rootGetters["router/isExercise"]) {
      id = rootGetters["router/getRoute"]?.params.exercise_id;
    }

    return id ? parseInt(id) : id;
  },
  getStepID(state, getters, rootState, rootGetters) {
    const id = rootGetters["router/getRoute"]?.params.stap_id;
    return id ? parseInt(id) : id;
  },
  getWorldID(state, getters, rootState, rootGetters) {
    const id = rootGetters["router/getRoute"]?.params.world_id;
    return id ? parseInt(id) : id;
  },
  getPhaseID(state, getters, rootState, rootGetters) {
    const id = rootGetters["router/getRoute"]?.params?.id;
    return id ? parseInt(id) : id;
  },
  linkToWorld(state, getters, rootState, rootGetters) {
    const test = rootGetters["exercise/getTest"];
    if (getters.isTest && test) {
      return `/wereld/${test?.phase_id}`;
    }
    const step = rootGetters["lessonPage/getStep"];
    if (getters.isLessonPage && step) {
      return `/wereld/${step?.phase_id}`;
    }

    return null;
  },
  linkToLessonOverview(state, getters) {
    return `/fase/${getters.getPhaseID}/stap/${getters.getStepID}`;
  },
  isTest(state, getters) {
    return getters.getRoute?.name === "test";
  },
  isLessonPage(state, getters) {
    return getters.getRoute?.name === "lesson_page";
  },
  isExercise(state, getters) {
    return getters.getRoute?.name === "exercise";
  },
  isProfile(state, getters) {
    return getters.getRoute?.name === "profile";
  },
};

export default {
  actions,
  mutations,
  getters,
  state,
  namespaced: true,
};
