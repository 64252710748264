<template>
  <div class="anchor-images-container">
    <h3 class="exercise-description">{{ description }}</h3>

    <div
        class="anchor-image-list"
    >
          <img
              @click="select(anchor.id)"
              v-for="(anchor) in anchors"
              :key="anchor.id"
              :alt="anchor.image"
              :src="anchor.image_url"
              :class="{selected: anchorID === anchor.id}"
          />
    </div>

    <c-input class="anchor-image-input" v-model="currentInput"/>
    <c-next-button/>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CInput from "@/components/Exercise/Components/Input";
import CNextButton from "@/components/Exercise/Components/NextButton";

export default {
  name: "c-anchor-images-template",
  components: {CNextButton, CInput},
  data: () => ({
    typed: "",
    anchorID: null,
  }),
  methods: {
    ...mapMutations({
      setCurrentInput: "exercise/SET_CURRENT_INPUT",
    }),
    ...mapActions({
      incrementErrors: "exercise/incrementErrors",
    }),
    select(anchorID) {
      this.anchorID = anchorID
      this.setCurrentInput({word: this.currentInput, anchor: this.anchorID});
    },
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      getCurrentInput: "exercise/getCurrentInput",
      progressStep: "exercise/getProgressStep",
      progressWord: "exercise/getProgressWord",
      isTest: "router/isTest",
      description: "template/getDescription",
      template: "template/getCurrentTemplate",
      wordData: "exercise/getWordData",
      anchors: "exercise/getAnchors",
    }),
    currentInput: {
      get() {
        return this.getCurrentInput?.word;
      },
      set(value) {
        this.setCurrentInput({word: value, anchor: this.anchorID});
      }
    },
  },
  watch: {
    progressStep() {
      this.typed = "";
      this.anchorID = null
    },
    progressWord() {
      this.typed = "";
      this.anchorID = null
    }
  }
};
</script>

<style scoped lang="scss">
.anchor-images-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.description {
  font-size: 30px;
  color: #016d47;
}

.anchor-image-list {
  //display: flex;
  display: block;
  flex-direction: row;
  justify-content: center;
  flex-basis: 250px;

  img {
    width: 230px;
    height: 230px;
    margin: 20px 40px;
    cursor: pointer;
    border-radius: 30px;
    transition: transform .3s;

    &:hover {
      box-shadow: 7px 9px 15px 1px #747474;
      transform: scale(1.1);
    }

    &.selected {
      outline: 3px green solid;
      box-shadow: 7px 9px 15px 1px #747474;
      transform: scale(1.1);
    }
  }
}

.anchor-image-input {
  max-width: 500px;
  margin: 0 auto;
}
</style>