<template>
  <div class="input-hint__container">
    <img
        ref="axe"
        alt="axe"
        class="bijl input-hint"
        src="../../../assets/bijl.png"
    />
    <img
        ref="twoTimes"
        alt="verdubbelaar"
        class="verdubbelaar input-hint"
        src="../../../assets/img/exercises/verdubbelaar_dark.png"
    />
  </div>
</template>

<script>
import { mapGetters} from "vuex";

export default {
  name: 'CInputHints',
  data: () => ({}),
  methods: {
    animateAxe() {
      this.$refs.axe.animate([
        {opacity: '1'},
        {transform: 'rotate(0) translateY(-50%)', opacity: '1'},
        {transform: 'rotate(-90deg) translateY(-50%)', opacity: '1'},
        {transform: 'rotate(0) translateY(-50%)', opacity: '1'},
        {opacity: '0'},
      ], {
        duration: 1300,
        iterations: 1,
      })
    },
    animateTwoTimes() {
      this.$refs.twoTimes.animate([
        {transform: 'rotateY(0) translateY(-50%)', opacity: '1'},
        {transform: 'rotateY(-180deg) translateY(-70%)', opacity: '1'},
        {transform: 'rotateY(0) translateY(-50%)', opacity: '1'},
        {opacity: '0'},
      ], {
        easing: "ease-in-out",
        duration: 1300,
        iterations: 1,
      })
    }
  },
  computed: {
    ...mapGetters({
      currentInput: "exercise/getCurrentInput",
      expectedInput: "exercise/getExpectedInput",
      exerciseData: "exercise/getExerciseData",
      errors: "exercise/getErrors",
      lastAnswer: "exercise/getLastAnswer",
    }),
    word() {
      return this.currentInput?.word ?? ""
    },
    nextExpectedStructure() {
      return this.wordStructure?.[this.word.length]
    },
    wordStructure() {
      return this.exerciseData?.word.structure.split(",");
    },
    previousLetterStructure() {
      return this.wordStructure?.[this.word.length - 1]
    },
    twoLetterBackStructure() {
      return this.wordStructure?.[this.word.length - 2]
    },
    threeLetterBackStructure() {
      return this.wordStructure?.[this.word.length - 3]
    },
    lastAnswerDoubleVowel() {
      return this.lastAnswer[this.lastAnswer.length - 1] === this.lastAnswer[this.lastAnswer.length - 2]
    },
    lastAnswerNotDoubleConsonant() {
      return this.lastAnswer[this.lastAnswer.length - 1] !== this.lastAnswer[this.lastAnswer.length - 2]
    },
    // axe() {
    //   return this.twoLetterBackStructure === "L1" && this.previousLetterStructure !== "L1"
    //   // return this.previousLetterStructure === "L1" && this.nextExpectedStructure !== "L1"
    // },
    // twoTimes() {
    //   const M1 = this.threeLetterBackStructure === "K2" && this.twoLetterBackStructure === "M1" && this.previousLetterStructure === "M1"
    //   const M2 = this.threeLetterBackStructure === "K2" && this.twoLetterBackStructure === "M2" && this.previousLetterStructure === "M2"
    //   return M1 || M2
    //   // && this.nextExpectedStructure === "M1"
    // }
  },
  watch: {
    errors() {
      if (this.previousLetterStructure === "L1" && this.nextExpectedStructure !== "L1" && this.lastAnswerDoubleVowel) {
        this.animateAxe()
      }

      if (this.twoLetterBackStructure === "K2" && this.previousLetterStructure === "M1" && this.lastAnswerNotDoubleConsonant) {
        this.animateTwoTimes()
      }
    },
    // axe(newValue, oldValue) {
    //   if (newValue === true && oldValue === false && this.twoLetterBackStructure === "L1") {
    //     this.$refs.axe.animate([
    //       {transform: 'rotate(0) translateX(0)', opacity: '1', filter: 'unset'},
    //       {transform: 'rotate(-90deg) translateX(-20px)', opacity: '1', filter: 'unset'},
    //       {transform: 'rotate(0) translateX(0)', opacity: '1', filter: 'unset'},
    //     ], {
    //       duration: 800,
    //       iterations: 1,
    //     })
    //   }
    // },
    // twoTimes(newValue, oldValue) {
    //   if (newValue === true && oldValue === false && this.twoLetterBackStructure === "M1" && this.previousLetterStructure === "M1") {
    //     this.$refs.twoTimes.animate([
    //       {transform: 'rotateY(0) translateY(0)', opacity: '1', filter: 'unset'},
    //       {transform: 'rotateY(-180deg) translateY(-10px)', opacity: '1', filter: 'unset'},
    //       {transform: 'rotateY(0) translateY(0)', opacity: '1', filter: 'unset'},
    //     ], {
    //       easing: "ease-in-out",
    //       duration: 800,
    //       iterations: 1,
    //     })
    //   }
    // },
  }
}
</script>

<style scoped lang="scss">
.input-hint {
  margin: 3px;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-50%);
}

.verdubbelaar {
  backface-visibility: visible;
}
</style>
