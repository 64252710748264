<template>
  <div class="cut-words-container">
    <h3 class="cut-words-description">{{ description }}</h3>
      <div class="cut-words-word" >
        <transition name="fade" mode="out-in">
        <transition-group class="cut-word-letter-transition-group" :name="transition" tag="div" :key="word">
          <p
              class="cut-word-letter"
              :style="'max-width: ' + (200 / letters.length) + '%' "
              :class="{hyphen: letter.letter === '-'}"
              v-for="(letter) in letters"
              :key="transitionKey(letter)"
              @click="selected(letter.originalIndex)"
          >
            {{ letter.letter }}
          </p>
        </transition-group>
        </transition>
      </div>

    <div class="cut-words-buttons">
      <c-clear-button/>

      <c-next-button @click="transition = null"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CNextButton from "@/components/Exercise/Components/NextButton";
import CClearButton from "@/components/Exercise/Components/ClearButton";

export default {
  name: "c-sentence-template",
  components: {CClearButton, CNextButton},
  data: () => ({
    wrongWord: false,
    transition: 'cut-word-transition',
  }),
  methods: {
    ...mapMutations({
      setCurrentInput: "exercise/SET_CURRENT_INPUT"
    }),
    ...mapActions({}),
    selected(index) {
      let positions = this.currentInput.position;
      if (positions.includes(index)) {
        positions = positions.filter(e => e !== index)
      } else {
        positions.push(index)
      }

      this.currentInput = positions
    },
    transitionKey(letter) {
      let key = `${this.word}_${letter.originalIndex}`;
      return letter.letter !== '-' ? key : key + '_hyphen'
    },
  },
  computed: {
    ...mapGetters({
      description: "template/getDescription",
      word: "exercise/getWord",
      getCurrentInput: "exercise/getCurrentInput",
      progressWord: "exercise/getProgressWord",
      progressStep: "exercise/getProgressStep",
    }),
    letters() {
      const letters = (this.word ?? "").split("").map((letter, index) => {
        return {
          letter,
          originalIndex: index
        }
      });
      let hyphens = 1;
      this.currentInput.position.forEach((position) => {
        letters.splice(position + hyphens, 0, {
          letter: "-",
          originalIndex: position
        });
        hyphens++;
      })
      return letters;
    },
    currentInput: {
      get() {
        return this.getCurrentInput && this.getCurrentInput !== "" ? this.getCurrentInput : {position: []}
      },
      set(value) {
        this.setCurrentInput({
          position: value.sort(function (a, b) {
            return a - b;
          })
        })
      },
    },
  },
  watch: {
    word() {
      this.$nextTick(() => {
        this.transition = 'cut-word-transition';
      })
    },
    currentInput(value) {
      if (value === null) {
        this.input = "";
      }
    },
    progressWord() {
      this.typed = "";
      this.setCurrentInput(null);
    },
    progressStep() {
      this.typed = "";
      this.setCurrentInput(null);
    }
  }
};
</script>

<style scoped lang="scss">
.cut-words-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 auto;
  text-align: center;
  align-items: center;
}

.cut-words-input-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  > div {
    margin: 40px auto;
  }
}

.cut-words-description {
  font-size: 30px;
  color: #016d47;
  margin: 20px auto;
}

.cut-words-word {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2.2em;
  font-weight: bold;
  background: url("../../../assets/boomstam.png") no-repeat 50%;
  background-size: contain;
  height: 24%;
  width: 100%;
  max-width: 67%;
  cursor: url("../../../assets/zaag.png") 10 5, auto !important;
  overflow: hidden;
  position: relative;
}

.cut-words-buttons {
  display: flex;
  width: 750px;
}

.cut-word-letter-transition-group {
  position: relative;
  display: flex;
  justify-content: center;

  &.next-word {
    * {
      transition: none !important;
    }
  }
}

.cut-word-letter {
  color: white;
  position: relative;
  line-height: 100%;
  font-size: 1.5em;
  padding: 0 5px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  &:hover {
    &:after {
      background: green;
      content: "";
      position: absolute;
      top: 0;
      right: -2px;
      bottom: 0;
      width: 3px;
    }
  }
}

.cut-word-transition-move,
.cut-word-transition-enter-active,
.cut-word-transition-leave-active,
.cut-word-letter{
  transition: transform .40s ease, opacity .40s ease, max-width .40s ease, padding .40s ease;
}

.cut-word-transition-enter-from,
.cut-word-transition-leave-to {
  opacity: 0;
  max-width: 0 !important;
  padding: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>