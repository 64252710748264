import Step from "@/models/Step";
import router from "@/router";
import Vue from "vue";

const state = {
  difficulty: [],
  step: {},
  currentSliderIndex: 0,
  sliderIndicesByStep: {},
  slideQueued: false,
};

const mutations = {
  SET_STEP(state, payload) {
    const stepValue = payload && !payload.isEmpty() ? new Step(payload) : {};
    Vue.set(state, "step", stepValue);
  },
  SET_DIFFICULTY(state, payload) {
    state.difficulty = payload;
  },
  SET_SLIDER_INDEX_BY_STEP(state, { stepID, sliderIndex }) {
    Vue.set(state.sliderIndicesByStep, stepID, sliderIndex);
  },
  QUEUE_SLIDE(state, payload) {
    state.slideQueued = payload;
  },
};

const actions = {
  fetchStep({ commit }, step = null) {
    commit("SET_STEP", null);

    return Vue.prototype.$api.endpoints.step
      .get(step ?? router.currentRoute.params.stap_id)
      .then((data) => {
        commit("SET_STEP", data);
      });
  },
  setDifficulty({ commit }, { difficulty, id }) {
    let currentDifficulty =
      JSON.parse(window.localStorage.getItem("current-difficulty")) ?? {};
    currentDifficulty[id] = difficulty;
    window.localStorage.setItem(
      "current-difficulty",
      JSON.stringify(currentDifficulty)
    );
    commit("SET_DIFFICULTY", currentDifficulty);
  },
  queueSlide({ commit }, payload) {
    commit("QUEUE_SLIDE", payload);
  },
  slide({ commit, state, getters }) {
    if (typeof state.slideQueued === "number") {
      let newIndex = state.slideQueued;
      commit("QUEUE_SLIDE", null);
      // if (newIndex >= getters.getAllStudentExercisesByDifficulty.length) {
      //   newIndex = 0;
      // }
      // if (newIndex < 0) {
      //   newIndex = getters.getAllStudentExercisesByDifficulty.length - 1;
      // }

      commit("SET_SLIDER_INDEX_BY_STEP", {
        stepID: getters.getStep.id,
        sliderIndex: newIndex,
      });
    }
  },
};

const getters = {
  getStep(state) {
    if (state.step instanceof Step) {
      return state.step;
    }

    return null;
  },
  getProgress(state, getters, rootState, rootGetters) {
    const studentExercises = getters.getStep.exercises;
    if (!studentExercises) return 0;

    const difficulty = getters.getDifficulty();

    let done = studentExercises.filter((e) => {
      return e?.status[difficulty] === 2;
    });

    return (
      (done.length / rootGetters["lessonPage/getStep"].exercises.length) * 100
    );
  },
  getWords(state, getters) {
    return getters.getStep.words ?? [];
  },
  getWordsByDifficulty(state, getters) {
    return getters.getStep.words[getters.getDifficulty()] ?? [];
  },
  getDifficulty:
    (state) =>
    (id = null) => {
      if (!id) id = router.currentRoute.params.stap_id;
      if (!id) {
        const regex = new RegExp("\\/fase\\/\\d+\\/stap\\/(\\d+)\\/\\d+");
        id = regex.exec(location.pathname)?.[1] ?? id;
      }
      const currentDifficulty =
        window.localStorage.getItem("current-difficulty");
      let parsed = 1;
      if (currentDifficulty) {
        parsed = JSON.parse(currentDifficulty)[id];
      }

      return state.difficulty[id] || parsed;
    },
  highestCompleted(state, getters) {
    return getters.getStep.highest_difficulty_completed ?? 0;
  },
  getSliderIndex: (state, getters) => {
    if (getters.getStep) {
      return state.sliderIndicesByStep[getters.getStep?.id] ?? 0;
    } else {
      return 0;
    }
  },
  getAnchorImages: (state, getters) => {
    return getters.getStep?.anchors;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
