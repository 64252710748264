<template>
  <div class="letter-spawner" ref="letter-spawner">
    <v-letter
      v-for="(number, index) in Array.range(this.config.spawnerCount, 0)"
      :key="index"
      :index="index"
      :config="config"
      @status="changeStatus"
    />

<!--    <div class="debug" v-if="showDebug">-->
<!--      <p>onScreen: {{ onScreen }} letters</p>-->
<!--      <p v-for="(value, key) in debug()" :key="key">{{ key }}: {{ value }}</p>-->
<!--    </div>-->
  </div>
</template>

<script>
import VLetter from "@/components/EndCave/Letter";

export default {
  name: "v-letter-spawner",
  components: { VLetter },
  props: ["index"],
  data: () => ({
    showDebug: true,
    letterStatuses: [],
    config: {
      spawnerCount: 50,
      spawnChance: 80, // in percentage
      duration: {
        transition: { current: null, min: 3, max: 7 }, // in seconds
        animation: { current: null, min: 3, max: 7 }, // in seconds
        start: { current: null, min: 0.5, max: 7 }, // in seconds
      },
      size: { current: null, min: 50, max: 100 }, // in pixels
      colour: {
        R: { base: 255, current: null, min: 0, max: 0 },
        G: { base: 153, current: null, min: 0, max: 50 },
        B: { base: 51, current: null, min: -50, max: 50 },
      },
      x: null,
    },
  }),
  computed: {
    onScreen() {
      return this.letterStatuses.filter((f) => f === 1).length;
    },
  },
  methods: {
    changeStatus(arg) {
      this.$set(this.letterStatuses, arg.index, arg.status);
    },
    debug() {
      return {
        spawnerCount: this.config.spawnerCount,
        spawnChance: this.config.spawnChance + "%",
        size: this.config.size.min + "px - " + this.config.size.max + "px",
        start:
          this.config.duration.start.min +
          "px - " +
          this.config.duration.start.max +
          "px",
        animation:
          this.config.duration.animation.min +
          "s - " +
          this.config.duration.animation.max +
          "s",
        transition:
          this.config.duration.transition.min +
          "s - " +
          this.config.duration.transition.max +
          "s",
        baseRGB:
          this.config.colour.R.base +
          " " +
          this.config.colour.G.base +
          " " +
          this.config.colour.B.base,
        variationR: this.config.colour.R.min + " - " + this.config.colour.R.max,
        variationG: this.config.colour.G.min + " - " + this.config.colour.G.max,
        variationB: this.config.colour.B.min + " - " + this.config.colour.B.max,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.debug p {
  color: white;
  font-size: 30px;
}
</style>
