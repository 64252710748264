<template>
  <div class="kiko">
    <img alt="leftwing" src="../../assets/kiko-leftwing.png" class="kiko-leftwing"/>
    <img alt="body" src="../../assets/kiko-body.png" class="kiko-body"/>
    <img alt="rightwing" src="../../assets/kiko-rightwing.png" class="kiko-rightwing"/>
  </div>
</template>

<script>
export default {
  name: "KikoFlying"
}
</script>

<style scoped>
.kiko-body {
  z-index: 3;
  width: 100%;
}

.kiko-leftwing {
  z-index: 2;
  position: absolute;
  top: 63%;
  width: 63%;
  animation: leftwing 1.5s;
  animation-iteration-count: infinite;
  transform-origin: top right;
}

.kiko-rightwing {
  z-index: 4;
  right: 0;
  width: 46%;
  position: absolute;
  animation: rightwing 1.5s;
  animation-iteration-count: infinite;
  transform-origin: bottom left;
}

@keyframes rightwing {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  0% {
    transform: rotate(0deg);
  }
}

@keyframes leftwing {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  0% {
    transform: rotate(0deg);
  }
}
</style>