<template>
  <div class="progress-row">
<!--    <div class="progress-column"/>-->
<!--    <div class="progress-bar">-->
<!--      <v-progress-linear-->
<!--          :value="progress"-->
<!--          height="100%"-->
<!--          color="#54B045"-->
<!--          background-color="#5E3824"-->
<!--          v-html="Math.ceil(progress) + '%'"-->
<!--      />-->
<!--    </div>-->
    <div
        class="progress-column difficulty-stars_container flex-center"
        :class="{active: isModalVisible}"
    >
      <img
          class="difficulty-stars"
          alt="difficulty"
          v-if="difficulty($route.params.stap_id)"
          @click="difficultyModalVisible = !difficultyModalVisible"
          :src="Helper.getPng('difficulty-' + difficulty($route.params.stap_id) + '.png','exercises/')"
      />
    </div>

    <c-difficulty-modal v-if="isModalVisible" @close="difficultyModalVisible = false"/>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import CDifficultyModal from "@/components/Lespagina/DifficultyModal";
import {Helper} from "@/services/Helper";

export default {
  name: 'c-lesson-header',
  components: {CDifficultyModal},
  data: () => ({
    difficultyModalVisible: false,
  }),
  methods: {
    ...mapActions({
      setDifficulty: "lessonPage/setDifficulty",
    }),
  },
  mounted() {
    if (this.currentDifficultyCompleted) {
      this.difficultyModalVisible = true
    }
  },
  computed: {
    Helper() {
      return Helper
    },
    ...mapGetters({
      step: "lessonPage/getStep",
      profile: "user/getProfile",
      linkToWorld: "router/linkToWorld",
      progress: "lessonPage/getProgress",
      difficulty: "lessonPage/getDifficulty",
      highestCompleted: "lessonPage/highestCompleted",
    }),
    isModalVisible() {
      return !this.difficulty(this.$route.params.stap_id) || (this.difficultyModalVisible)
    },
    currentDifficultyCompleted() {
      return this.highestCompleted !== 3 && this.highestCompleted >= this.difficulty()
    }
  },
}
</script>
<style scoped lang="scss">
.v-progress-linear {
  border: 3px solid #3f1d05;
  background-color: rgb(94, 56, 36);
  border-radius: 40px;
  z-index: 95;
  box-shadow: 5px 5px 2px -3px rgb(0 0 0 / 25%);
  user-select: none;
}

.theme--light.v-progress-linear {
  color: white !important;
  font-weight: bold !important;
}

.progress-row {
  height: 5%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.progress-column {
  width: 15%;
  text-align: right;
  height: 35px;

}

.difficulty-stars_container {
  width: fit-content;
  background: white;
  border-radius: 30px;
  position: relative;
  height: 100%;
  transition: scale .2s ease;
  cursor: pointer;
  padding: 0 1%;

  &:hover, &.active {
    scale: 1.05;
  }

  img {
    top: 0;
    height: 80%;
  }
}

.progress-bar {
  width: 50%;
  margin: 0 auto;
}

.difficulty-stars {
  cursor: pointer;
}

.v-progress-linear__content {

}
</style>