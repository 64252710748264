const state = {
    visibility: {
        reward: false
    },
};

const mutations = {
    TOGGLE_OVERLAY_VISIBILITY(state, payload) {
        state.visibility[payload] = !state.visibility[payload];
    },
    SET_OVERLAY_VISIBILITY(state, {id, value}) {
        state.visibility[id] = value;
    },
};

const actions = {};

const getters = {
    isVisible: (state) => (payload) =>  {
        return !!state.visibility[payload]
    },
    isAnyVisible(state) {
        return Object.values(state.visibility).find((value) => (value))
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
