<template>
  <div class="test-input-container">
    <h3 class="test-description">Typ het woord</h3>

    <c-input v-model="currentInput" class="test-input"/>

    <c-next-button/>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import CNextButton from "@/components/Exercise/Components/NextButton";
import CInput from "@/components/Exercise/Components/Input";

export default {
  name: "c-typing-template",
  components: {CInput, CNextButton},
  methods: {
    ...mapMutations({
      setCurrentInput: "exercise/SET_CURRENT_INPUT",
    }),
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      getCurrentInput: "exercise/getCurrentInput",
      word: "exercise/getWord",
      progressStep: "exercise/getProgressStep",
      isTest: "router/isTest",
      isTemplate: "template/isTemplate",
      template: "template/getCurrentTemplate",
      description: "template/getDescription",
    }),
    currentInput: {
      set(value) {
        this.setCurrentInput({word: value})
      },
      get() {
        return this.getCurrentInput?.word
      },
    }
  },
};
</script>

<style scoped lang="scss">
.test-input-container {
  height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.test-description {
  font-size: 30px;
  color: #016d47;
}

.test-input {
  height: 20%;
}
</style>