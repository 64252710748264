<template>
  <RepetitionModal
      v-show="shown"
      @close="$emit('close')"
  >
    <template v-slot:body>
      <p>Hieronder staan de herhalingsoefeningen die je kunt doen.</p>

      <p v-show="repeatables.length === 0">Er zijn op dit moment helaas nog geen herhalingsstappen die je kunt
                                          doen.</p>

      <v-simple-table>
        <template v-slot:default>
          <tbody>
          <tr
              v-for="item in repeatables"
              :key="item.id"
              class="herhaling-table"
          >
            <td><img :src="iconSource(item)" class="repetition-icon" alt="repetition-icon"/></td>
            <td>{{ item.phase_id }}</td>
            <td>{{ item.label }}</td>
            <td>
              <v-btn
                  @click="$router.push({ name: 'lesson_page', params: { stap_id: item.id, id: 1 } })"
                  class="yellow"
              >Ga naar de herhaalstap
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </RepetitionModal>
</template>
<script>
import RepetitionModal from '@/components/RepetitionModal'
import {mapGetters} from "vuex";

export default {
  name: 'TreehouseRepetitionModal',
  components: {RepetitionModal},
  props: ['shown'],
  computed: {
    ...mapGetters({
      repeatables: "exercise/getRepeatables",
      iconSource: "world/getExerciseIconSource",
    }),
  },
}
</script>
<style scoped lang="scss">
@keyframes broom {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(3deg);
  }
  0% {
    transform: rotate(0deg);
  }
}

th {
  color: #016D47 !important;
}

th, td {
  font-size: 16px !important;
}

tr:nth-child(odd) {
  background: #F8F4E0;
}

tr {
  height: 70px;
}

.herhaling-table td:nth-child(1),
.herhaling-table td:nth-child(2) {
  width: 90px;
  color: #026C49;
  font-weight: bold;
  font-size: 18px !important;
}

.herhaling-table td:nth-child(3) {
  font-size: 18px !important;
}

td:last-child {
  text-align: right;
}

.repetition-icon {
  width: 50px;
  image-rendering: auto;
}

</style>