<template>
  <transition name="progress-modal-fade">
    <div class="progress-modal-backdrop">
      <div class="progress-modal"
        role="dialog"
        aria-labelledby="progress-modalTitle"
        aria-describedby="progress-modalDescription"
      >
        <img src="../assets/close-modal.png" @click="close" aria-label="Close modal" class="btn-progress-close" />
        <header
          class="progress-modal-header"
          id="progress-modalTitle"
        >
          <slot name="header">
            <h1>Geoefende woorden</h1>
          </slot>
        </header>

        <section
          class="progress-modal-body"
          id="progress-modalDescription"
        >
          <slot name="body">
          </slot>
        </section>

        <footer class="progress-modal-footer">
          <slot name="footer">
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'VoortgangModal',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style scoped>
  .progress-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .progress-modal {
    position: initial;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    z-index: 99;
    margin-bottom: 20px;
    background: #F8F4E0;
    border-radius: 25px;
    border: 2px solid green;
    width: 1240px;
    height: 620px;
  }

  h1 {
    margin: -40px 0 -20px 10px;
    padding: 15px 5px 6px 5px;
    width: 65%;
    transform: rotate(-2deg);
    text-align: center;
    background: #FFD900;
    background: url('../assets/titelbalk.png') no-repeat;
    background-size: cover;
    color: #016D47;
    z-index: 9999;
    image-rendering: auto;
  }

  .progress-modal-header,
  .progress-modal-footer {
    display: flex;
    z-index: 999;
  }

  .progress-modal-header {
    position: absolute;
    width: 600px;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .progress-modal-body {
    z-index: 999;
    width: 1040px;
    height: auto;
    position: relative;
    padding: 0px;
    margin: 70px auto;
    overflow: auto;
  }

  .progress-modal-footer {
    width: 600px;
    margin: 0 auto;
    flex-direction: column;
  }

  .btn-progress-close {
    margin-left: 1175px;
    margin-top: 2px;
    width: 62px;
    border: 62px;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
    z-index: 999;
    position: absolute;
    image-rendering: auto;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border-radius: 2px;
  }

  .progress-modal-fade-enter,
  .progress-modal-fade-leave-to {
    opacity: 0;
  }

  .progress-modal-fade-enter-active,
  .progress-modal-fade-leave-active {
    transition: opacity .5s ease;
  }

  /*@media screen and (max-width: 1024px) {*/
  /*  .progress-modal-backdrop {*/
  /*    padding-top: 50px;*/
  /*  }*/
  /*  */
  /*  .progress-modal {*/
  /*    width: 1000px;*/
  /*  }*/

  /*  .progress-modal-body {*/
  /*    width: 950px;*/
  /*  }*/

  /*  .progress-modal-body img {*/
  /*    width: 100%;*/
  /*  }*/

  /*  .btn-progress-close {*/
  /*    margin-left: 950px;*/
  /*    margin-top: -25px;*/
  /*  }*/
  /*}*/
</style>