import Vue from "vue";

const state = {};

const mutations = {};

const actions = {
  log(obj, type) {
    return Vue.prototype.$api.post("/api/v1/activity", { type });
  },
  logTreehouse({ dispatch }) {
    return dispatch("log", "enter_treehouse");
  },
};

const getters = {};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
