<template>
  <v-btn
      class="green next-button"
      tile
      @click="click"
      :disabled="disabled"
  >
    <font-awesome-icon icon="arrow-right"/>
  </v-btn>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "CNextButton",
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    post: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    ...mapActions({
      postData: "exercise/postData"
    }),
    click() {
      if (this.post) this.postData()
      this.$emit('click')
    }
  },
};
</script>

<style lang="scss" scoped>
.jungle-container {

  .v-btn__content ::v-deep {
    height: 80% !important;
  }

  svg ::v-deep {
    height: 100%;
    width: 100%;
  }
}
</style>

<style lang="scss">
.next-button {
  margin: 0 auto;

  .v-btn {
    height: 7% !important;
    width: 7% !important;
  }

  .v-btn__content {
    height: 60% !important;
    width: 100%;
    position: absolute !important;
  }

  svg ::v-deep {
    height: 100%;
    width: 100%;
  }
}
</style>

