<template>
  <div class="overlay__container" ref="container">
    <transition name="fade">
      <div
          class="overlay__modal"
          :key="isVisible('reward')"
      >
        <bubble
            class="align-center"
            v-if="isVisible('reward')"
        >
          <template #header>
            <h2>Je hebt een nieuwe badge ontvangen!</h2>
          </template>
          <template #body>
            <img :src="getNewBadge" alt="badge" ref="badge"/>
          </template>
          <template #footer>
            <c-button @click="SET_VISIBILITY({id: 'reward', value: false})">Ga door</c-button>
          </template>
        </bubble>
      </div>
    </transition>
  </div>
</template>

<script>
import Bubble from "@/components/Base/Bubble";
import CButton from "@/components/Base/Button";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "RewardOverlay",
  components: {CButton, Bubble},
  data: () => ({
    badgeId: null
  }),
  methods: {
    ...mapMutations({
      TOGGLE_VISIBILITY: "overlay/TOGGLE_OVERLAY_VISIBILITY",
      SET_VISIBILITY: "overlay/SET_OVERLAY_VISIBILITY",
    }),
    ...mapActions({
      bind: "pusher/bind",
      playEffect: "audio/playEffect",
    }),
    spinBadge() {
      this.$refs.badge?.animate([
        {transform: 'rotateY(0) translateY(0)',},
        {transform: 'rotateY(-180deg) translateY(-30%)',},
        {transform: 'rotateY(0) translateY(0)'},
      ], {
        easing: "ease-in-out",
        duration: 1300,
        iterations: 1,
      })
    }
  },
  computed: {
    ...mapGetters({
      isVisible: "overlay/isVisible",
      badges: "user/getBadges",
      channel: "pusher/channel"
    }),
    getNewBadge() {
      if (!this.badgeId) return null
      return this.badges.find(f => f.id === this.badgeId)?.image_url
    }
  },
  watch: {
    channel(newValue) {
      if (newValue) {
        this.bind({
          type: "reward_earned",
          callback: ({activity}) => {
            if (activity?.item_id) {
              this.badgeId = activity.item_id
              this.SET_VISIBILITY({id: 'reward', value: true})
              setTimeout(() => {
                this.playEffect('unlock-reward')
                this.spinBadge()
              }, 500)
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>