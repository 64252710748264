<template>
  <div class="full-world-slider">
    <transition-group :name="transition" mode="out-in">
      <CWorldSlide
          @next="nextWorld()"
          v-for="world in Array.range(4, 1)"
          :key="world"
          :world-number="world"
          v-show="world === activeWorld"
          :steps="getSteps(world)"
      />
    </transition-group>

    <!--    <div-->
    <!--        v-for="world in Array.range(4, 1)"-->
    <!--        :key="world"-->

    <!--    >-->
    <!--      <img src="../../assets/first-world-bg.png">-->
    <!--      &lt;!&ndash;        <div class="locked-world" v-if="isDisabled(world)">&ndash;&gt;-->
    <!--      &lt;!&ndash;          <img src="../assets/lock.png" alt="locked_world"/>&ndash;&gt;-->
    <!--      &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <transition name="fade">&ndash;&gt;-->
    <!--      &lt;!&ndash;          <div class="completed-world" v-if="isCompleted(world) && !isActive(world)">&ndash;&gt;-->
    <!--      &lt;!&ndash;            <div class="completed-world__diamond-background">&ndash;&gt;-->
    <!--      &lt;!&ndash;              <img :src="getDiamond(world)" alt="completed-world"/>&ndash;&gt;-->
    <!--      &lt;!&ndash;            </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;          </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;        </transition>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <c-world :number="world"></c-world>&ndash;&gt;-->
    <!--    </div>-->
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CWorld from "@/components/World/World";
import CWorldSlide from "@/components/World/WorldSlide";

export default {
  name: "CWorldSlider",
  components: {CWorldSlide, CWorld},
  data: () => ({
    touchStartX: 0,
    touchEndX: 0,
    minDistance: 100,
    transition: null
  }),
  mounted() {
    this.setWorld(this.lastCollected)
    document.addEventListener('touchstart', this.touchStart)
    document.addEventListener('touchend', this.touchEnd)
  },
  destroyed() {
    document.removeEventListener('touchstart', this.touchStart)
    document.removeEventListener('touchend', this.touchEnd)
  },
  watch: {
    activeWorld() {
      this.$router.replace(`/wereld/${this.activeWorld}`)
    }
  },
  methods: {
    ...mapMutations({
      setWorld: "world/SET_WORLD",
    }),
    ...mapActions({
      nextWorldAction: "world/nextWorld",
      previousWorldAction: "world/previousWorld",
       nextWorld: "world/previousWorld",
    }),
    touchStart(e) {
      this.touchStartX = e.changedTouches[0].screenX
    },
    touchEnd(e) {
      this.touchEndX = e.changedTouches[0].screenX
      this.checkDirection()
    },
    checkDirection() {
      if (this.touchEndX > this.touchStartX) {
        if (this.touchEndX > (this.touchStartX + this.minDistance)) {
          this.previousWorld()
        }

        return {direction: 'right', distance: this.touchEndX - this.touchStartX}
      }

      if (this.touchStartX > this.touchEndX) {
        if ((this.touchStartX - this.minDistance) > this.touchEndX) {
          this.nextWorld()
        }

        return {direction: 'left', distance: this.touchStartX - this.touchEndX}
      }

    },
    nextWorld() {
      this.transition = 'slide-right'
      // if (this.activeWorld === 1) this.transition = 'slide-right'
      // if (this.activeWorld === 2) this.transition = 'slide-down'
      // if (this.activeWorld === 3) this.transition = 'slide-right'
      this.nextWorldAction()
    },
    previousWorld() {
      this.transition = 'slide-left'
      // if (this.activeWorld === 2) this.transition = 'slide-left'
      // if (this.activeWorld === 3) this.transition = 'slide-up'
      // if (this.activeWorld === 4) this.transition = 'slide-left'
      this.previousWorldAction()
    },
    isDisabled(world) {
      return !this.isIntroductionCompleted || !this.isUnlocked(world)
    },
    getDiamond(world) {
      return require(`../../assets/img/end-cave/diamond-${world}-slotted.png`)
    },
    goToProfile(open) {
      if (open === false) {
        this.$router.push('/profiel')
      }
    },
  },
  computed: {
    ...mapGetters({
      profile: "user/getProfile",
      badges: "user/getBadges",
      student: "user/getStudent",
      isIntroductionCompleted: "user/isIntroductionCompleted",
      isActive: "world/isActive",
      activeWorld: "world/getActiveWorld",
      lastCollected: "world/lastCollected",
      isUnlocked: "world/isUnlocked",
      isCompleted: "world/isCompleted",
      isInitializing: "isInitializing",
      getSteps: "world/getSteps",
    }),
  },
}
</script>

<style scoped lang="scss">
.full-world-slider {
  height: calc(100vh - 80px);
  width: 100vw;
  position: relative;

  .world-wrapper {
    height: calc(100vh - 80px);
    width: 100vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  img {
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    height: calc(100vh - 80px);
  }
}
</style>