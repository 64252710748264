import { Model } from "@/models/Model";

export class Book extends Model {
  constructor({ author, file, id, title, url, review }, readOnly = true) {
    super();
    this._author = author;
    this._file = file;
    this._review = review;
    this._id = id;
    this._title = title;
    this._url = url;
    this._readOnly = readOnly;

    if (readOnly) Object.freeze(this);
  }

  get author() {
    return this._author;
  }

  get file() {
    return this._file;
  }

  get review() {
    return this._review;
  }

  get id() {
    return this._id;
  }

  get title() {
    return this._title;
  }

  get url() {
    return this._url;
  }

  get readOnly() {
    return this._readOnly;
  }
}
