<template>
  <div class="download-container">
    <h3>Klik op de gele download-knop en print je werkboek.<br><br> Veel succes met het schrijven van woorden, zinnen of een verhaal!</h3>

    <div class="compass-boy">
      <img
          alt="buddy"
          :src="
                    'https://cdn.spellit.nl/buddies/' +
                    profile.student.buddy_id +
                    '/lantern.png'
                  "
      />
    </div>

    <div class="kiko">
      <img
          src="../../../assets/kiko.png"
          alt="kiko-hint"
      />
    </div>

    <div class="button-container">
      <v-btn
          class="square-button"
          type="button"
          :href="workbook"
          @click="downloaded = true"
          target="_blank"
      >Download
      </v-btn>

      <v-btn
          class="green"
          :disabled="!downloaded"
          tile
          @click="postData()"
      >
        Ik ben klaar
      </v-btn>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "c-download",
  data: () => ({
    downloaded: false
  }),
  methods: {
    ...mapActions({
      postData: "exercise/postData"
    }),
  },
  computed: {
    ...mapGetters({
      profile: "user/getProfile",
      documents: "exercise/getDocuments",
    }),
    workbook() {
      return this.documents?.workbook
    }
  }
};
</script>

<style scoped lang="scss">
.download-container {
  height: 400px;
  width: 600px;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.button-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.compass-boy {
  left: -80px;
  bottom: -50px;
  position: absolute;
  width: 25vw;

  img {
    width: 100%;
  }
}

.kiko {
  width: 15vw;
  right: 0;
  bottom: 0;
  position: absolute;

  img {
    width: 100%;
  }
}
</style>