<template>
  <div
    @mousedown="startDrag($event, number)"
    @touchstart="startDrag($event, number)"
    @mousemove="drag($event)"
    @touchmove="drag($event)"
    @mouseup="endDrag($event)"
    @touchend="endDrag($event)"
    :ref="getRef()"
    :class="isFilled ? 'filled' : ''"
  >
    <div class="diamond-wrapper">
      <img :src="getSource()" class="diamond" alt="diamond" draggable="false" />
      <v-shine-spawner :number="number" />
    </div>
  </div>
</template>

<script>
import VShineSpawner from "@/components/EndCave/ShineSpawner";

export default {
  name: "v-diamond",
  components: { VShineSpawner },
  props: {
    number: {
      type: Number,
      required: true,
    },
    isFilled: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    diamondBeingDragged: null,
    currentCoordinates: {
      x: null, y: null
    }
  }),
  methods: {
    updatePosition(e) {
      this.setCoordinates(this.coordinates(e).clientX, this.coordinates(e).clientY)
      this.diamondBeingDragged.style.left = this.currentCoordinates.x + "px";
      this.diamondBeingDragged.style.top = this.currentCoordinates.y + "px";
    },
    setCoordinates(x, y) {
      this.currentCoordinates = {x, y};
    },
    coordinates(e) {
      return e.touches?.[0] ?? e;
    },
    startDrag(e) {
      e.preventDefault()
      if (this.isFilled) return;

      this.diamondBeingDragged = e.currentTarget;
      this.diamondBeingDragged?.classList.add("being-dragged");
      this.updatePosition(e)
    },
    drag(e) {
      e.preventDefault()
      if (this.diamondBeingDragged) {
        this.updatePosition(e)

        if (this.isInHole() !== -1) {
          this.diamondBeingDragged?.classList.add("jiggling");
        }

        if (this.isInHole() === -1) {
          this.diamondBeingDragged?.classList.remove("jiggling");
        }
      }
    },
    endDrag(e) {
      e.preventDefault()
      if (this.isFilled) return;

      if (this.isInHole() !== -1) {
        this.$emit("put-it-in", {
          targetIndex: this.isInHole(),
          diamondNumber: this.number,
        });
      }

      this.diamondBeingDragged?.classList.remove("being-dragged");
      this.diamondBeingDragged?.classList.remove("jiggling");
      this.diamondBeingDragged.style.top = "unset";
      this.diamondBeingDragged.style.left = "unset";
      this.diamondBeingDragged = null;
    },
    getSource() {
      if (this.isFilled) {
        return require("@/assets/img/end-cave/diamond-" +
          this.number +
          "-slotted.png");
      }

      return require("@/assets/img/end-cave/diamond-" +
        this.number +
        "-no-shine.png");
    },
    getRef() {
      return "diamond-" + this.number;
    },
    isInHole() {
      return this.boundingBoxes.findIndex((boundingBox) => {
        const xIsIn =
          boundingBox.x < this.currentCoordinates.x && boundingBox.x + boundingBox.width > this.currentCoordinates.x;
        const yIsIn =
          boundingBox.y < this.currentCoordinates.y && boundingBox.y + boundingBox.height > this.currentCoordinates.y;

        return xIsIn && yIsIn;
      });
    },
  },
  computed: {
    boundingBoxes() {
      return [
        document
          .getElementById("diamond-hole-top-left")
          .getBoundingClientRect(),
        document
          .getElementById("diamond-hole-top-right")
          .getBoundingClientRect(),
        document
          .getElementById("diamond-hole-bottom-left")
          .getBoundingClientRect(),
        document
          .getElementById("diamond-hole-bottom-right")
          .getBoundingClientRect(),
      ];
    },
  },
};
</script>

<!--suppress CssUnusedSymbol -->
<style lang="scss" scoped>
.diamond-wrapper {
  position: relative;
  user-select: none;
  height: 100%;
}

.filled {
  //margin-left: -10px;
  //margin-top: -7px;

  .diamond {
    width: 100%;
    position: absolute;
    cursor: default;
  }
}

.diamond {
  cursor: pointer;
  width: 10vw;
}

.diamond-drag-zone:not(.being-dragged) {
  .diamond-wrapper {
    aspect-ratio: 1/1;
  }

  .diamond {
    cursor: pointer;
    width: 100%;
  }
}

.being-dragged {
  position: fixed;
  transform: translate(-50%, -50%) scale(1.3);
  z-index: 100000;

  .diamond {
    width: 10vw;
  }
}

.jiggling {
  animation: jiggle 0.5s infinite;
  transform: translate(-50%, -50%) scale(1.3) rotate(-5deg);
}

@keyframes jiggle {
  0% {
    transform: translate(-50%, -50%) scale(1.3) rotate(-5deg);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.3) rotate(5deg);
  }
}
</style>
