export default class Notification {
  constructor({ type, message }) {
    this._type = type;
    this._message = message;
  }

  set id(id) {
    this._id = id;
  }

  get id() {
    return this._id;
  }

  get type() {
    return this._type;
  }

  get message() {
    return this._message;
  }

  get color() {
    return "success";
  }
}
