<template>
  <div class="diamond-hole" :id="getId()">
    <img
      v-if="!filledBy"
      :src="getSource()"
      class="diamond-in-hole"
      alt="diamond-in-hole"
      draggable="false"
    />
    <v-diamond
      v-if="filledBy"
      :number="filledBy"
      :is-filled="true"
      :is-in-hole="true"
    />
  </div>
</template>

<script>
import VDiamond from "@/components/EndCave/Diamond";

export default {
  name: "v-diamond-hole",
  components: { VDiamond },
  props: {
    number: {
      type: Number,
      required: true,
    },
    filledBy: {
      type: Number,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    elementIdMapping: {
      0: "top-left",
      1: "top-right",
      2: "bottom-left",
      3: "bottom-right",
    },
  }),
  methods: {
    getId() {
      return "diamond-hole-" + this.elementIdMapping[this.index];
    },
    getSource() {
      return require("@/assets/img/end-cave/diamond-slot.png");
    },
  },
};
</script>

<style lang="scss" scoped>
.diamond-hole {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 35%;
  position: absolute;
  z-index: 200;
  aspect-ratio: 8/7;
  $padding: 13%;

  &#diamond-hole-top-left {
    left: $padding;
    top: $padding;
  }

  &#diamond-hole-top-right {
    right: $padding;
    top: $padding;
  }

  &#diamond-hole-bottom-left {
    left: $padding;
    bottom: $padding;
  }

  &#diamond-hole-bottom-right {
    right: $padding;
    bottom: $padding;
  }

  .diamond-in-hole {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
