<template>
  <div class="jungle-content faded-overlay jungle-aspect-ratio">
    <v-window v-model="progressStep" touchless class="window-container" @change="nextStep" >
      <v-window-item :value="1">
        <c-exercise-intro />
      </v-window-item>

      <v-window-item :value="2">
        <c-exercise-content/>
      </v-window-item>

      <v-window-item :value="3">
        <c-exercise-outro/>
      </v-window-item>

      <v-window-item v-if="isTemplate('StapDictee')" :value="4">
        <c-step-test-outro/>
      </v-window-item>
    </v-window>

    <div class="dynamic-coin-counter-wrapper">
      <transition name="fade">
        <DynamicCoinCounter v-show="progressStep === 3"/>
      </transition>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CExerciseContent from "@/components/Exercise/ExerciseContent";
import CExerciseOutro from "@/components/Exercise/ExerciseOutro";
import DynamicCoinCounter from "@/components/Base/DynamicCoinCounter";
import CExerciseIntro from "@/components/Exercise/ExerciseIntro";
import CStepTestOutro from "@/components/Exercise/Outro/StepTest.vue";

export default {
  name: "exercise",
  components: {
    CStepTestOutro,
    CExerciseIntro,
    DynamicCoinCounter,
    CExerciseOutro,
    CExerciseContent,
  },
  mounted() {
    this.$el.addEventListener("keyup", (e) => this.continue(e));
  },
  beforeDestroy() {
    this.$el.removeEventListener("keyup", (e) => this.continue(e))
  },
  methods: {
    ...mapActions({
      hardReset: "exercise/fetchData",
      fetchData: "exercise/fetchData",
      fetchExercise: "exercise/fetchExercise",
      updateExercise: "exercise/updateExercise",
      continueExercise: "exercise/continueExercise",
      nextStep: "exercise/nextStep",
    }),
    ...mapMutations({
      setCurrentInput: "exercise/SET_CURRENT_INPUT",
      setHintVisibility: "exercise/SET_HINT_VISIBILITY",
      setStepProgress: "exercise/SET_STEP_PROGRESS",
    }),
    continue(e) {
      if (this.isTemplate("Verhaal") || this.isTemplate("Zinnen1")) {
        return false;
      }
      if (e.key === "Enter" && !e.shiftKey) {
        this.continueExercise();
      }
    },
  },
  computed: {
    ...mapGetters({
      difficulty: "user/getDifficulty",
      exercise: "exercise/getExercise",
      test: "exercise/getTest",
      studentExercise: "exercise/getCurrentStudentExercise",
      anchorImage: "exercise/getAnchorImage",
      anchorHint: "exercise/getAnchorHint",
      correctResults: "exercise/getCorrectResults",
      incorrectResults: "exercise/getIncorrectResults",
      progress: "exercise/getProgress",
      progressStep: "exercise/getProgressStep",
      isTest: "router/isTest",
      isTemplate: "template/isTemplate",
      getIntro: "template/getIntro",
      template: "template/getCurrentTemplate",
      currentInput: "exercise/getCurrentInput",
      isInitializing: "isInitializing",
    }),
  },
};
</script>

<style scoped lang="scss">
.exercise-container, .exercise-form, .window-container, .v-window-item {
  height: 100%;
  width: 100%;
  //
  //position: absolute;
  //top: 0;
  //left: 0;
  //right: 0;
  //bottom: -0;
  user-select: none;
}

.v-window-item {
  //position: absolute;
  //top: -50px;
  //left: 0;
  //right: 0;
  //bottom: -0;
  padding: 50px;
}

h3 {
  font-size: 30px !important;
  color: #016d47 !important;
}

::v-deep {
  .v-window {
    overflow: visible;
  }

  .v-window-item--active {
    height: 100%;
    width: 100%;
    overflow: visible;
  }

  .v-window-x-transition-leave-active {
    overflow: hidden;
  }
}

.dynamic-coin-counter-wrapper {
  position: absolute;
  top: 0;
  display: flex;
  height: 15%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss">
p {
  margin: 15px 0;
  line-height: 1.5em;
  color: #70706f;
  font-weight: 700;
}
</style>