<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" v-show="value">
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <div class="frame">
          <img src="../assets/Pop-up.png" />
        </div>
        <img src="../assets/close-modal.png" @click="close" aria-label="Close modal" class="btn-close" />
        <header
          class="modal-header"
          id="modalTitle"
        >
          <slot name="header">
          </slot>
        </header>

        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
          </slot>
        </section>

        <footer class="modal-footer">
          <slot name="footer">
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'Modal',
    props: {
      value: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      open() {
        this.$emit('input', true);
      },
      close() {
        this.$emit('input', false);
      },
    },
  };
</script>

<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .modal {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    /* width: 800px; */
    height: 533px;
  }

  .frame {
    position: absolute;
    z-index: 99;
  }

  .frame img {
    max-width: 800px;
  }

  .modal-header,
  .modal-footer {
    /* padding: 15px; */
    display: flex;
    z-index: 999;
  }

  .modal-header {
    position: relative;
    /* margin: 90px auto 0 auto; */
    /* padding: 30px 50px; */
    width: 600px;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-body {
    z-index: 999;
    width: 610px;
    height: 100%;
    position: relative;
    margin: 30px auto;
    padding: 0 20px;
    overflow: auto;
  }

  .modal-footer {
    width: 600px;
    margin: 0 auto;
    /* padding: 30px 50px; */
    flex-direction: column;
  }

  .btn-close {
    margin-left: 700px;
    margin-top: 60px;
    margin-bottom: -60px;
    width: 62px;
    border: 62px;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
    z-index: 999;
  }

  textarea, input {
    font-weight: bold !important;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border-radius: 2px;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>