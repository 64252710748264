<template>
  <div class="listening-container">
    <h3 class="exercise-description" @click="flipCard()">{{ description }}</h3>
    <h4 class="sentence" v-if="sentence && hasParameter('sentence')">
      {{ filteredSentence }}
    </h4>
    <div v-if="!staggered && !hasFadeOut" class="leaf">
      <transition mode="out-in" name="fade" :key="word || sentence">
        <h4 class="word">{{ word }}</h4>
      </transition>
    </div>

    <div class="leaf" v-if="staggered || hasFadeOut">
      <transition name="fade" mode="out-in">
        <div @click="toggleCard()" :key="flipped">
          <div
            v-if="!isTemplate('LeesTyp') && !flipped"
            :key="flipped"
            class="animated-word-container"
            ref="word-container"
          >
            <h2
              class="letter"
              :key="key"
              v-for="(letter, key) in animatedWordArray"
            >
              {{ letter }}
            </h2>
          </div>
          <div
            v-if="isTemplate('LeesTyp') && !flipped"
            :key="flipped"
            class="word-container"
          >
            <h2 class="letter">{{ word }}</h2>
          </div>
          <div :key="flipped" v-if="flipped" class="leaf-backside">
            <font-awesome-icon
              color="#00a972"
              class="side-icon"
              icon="sync-alt"
            />
          </div>
        </div>
      </transition>
    </div>

    <c-restricted-input v-if="isRestricted" v-model="currentInput" />

    <c-next-button :disabled="disabled" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CNextButton from "@/components/Exercise/Components/NextButton";
import CRestrictedInput from "@/components/Exercise/Components/RestrictedInput";

export default {
  name: "c-listening-content",
  components: { CRestrictedInput, CNextButton },
  data: () => ({
    flipped: 0,
    animatedWordArray: [],
    animationTimeouts: [],
    animationDone: false,
    flipTimout: false,
  }),
  mounted() {
    if (this.progressStep === 2) {
      this.startAnimation();
    }
  },
  methods: {
    ...mapMutations({
      setCurrentInput: "exercise/SET_CURRENT_INPUT",
    }),
    toggleCard() {
      if (this.flipped === 1) {
        this.flipped = 0;
        this.startAnimation();
      }
    },
    flipCard() {
      if (this.flipped === 0) {
        this.startAnimation();
      } else if (this.flipped === 1) {
        this.toggleFlip();
      }
    },
    resetCard() {
      this.flipped = 0;
      this.clearAnimationTimeouts();
      clearTimeout(this.flipTimeOut);
      if (this.wordContainer()) {
        this.wordContainer().style.maxWidth = 0;
      }
    },
    startAnimation() {
      this.resetCard();
      if (!this.word) return;
      if (this.progressStep !== 2) return;
      if (this.isTemplate("LeesTyp")) {
        this.toggleFlip();
        return;
      }
      this.animationDone = false;
      this.flipped = 0;

      this.animatedWordArray = [];

      // const structure =
      //     this.wordData.structure.split(",");
      //
      // const word = this.word.split("").reduce((acc, curr, index) => {
      //
      // }, [])

      const word = this.wordData.read_letters.split(",");

      // cycle through word characters and create timeout for every letter
      // with seconds equal to the index + 1
      word.map((letter, index) => {
        this.animationTimeouts.push(
          setTimeout(() => {
            this.animatedWord += letter;
            this.animatedWordArray.push(letter);

            this.$nextTick(() => {
              const container = this.$refs["word-container"];
              if (container) {
                let children = container.children;
                let width = 0;
                for (let i = 0; i < children.length; i++) {
                  width += children[i].offsetWidth;
                }
                container.style.maxWidth = width + "px";
              }
            });

            // only toggleFlip on last index
            if (index === word.length - 1) {
              this.toggleFlip();
              this.animationDone = true;
            }

            // interval might be inconsistent
            // set timeout equal to index seconds
          }, (index + 1) * 1000)
        );
      });
    },
    clearAnimationTimeouts() {
      this.animationTimeouts.forEach((timeout) => clearTimeout(timeout));
      this.animationTimeouts = [];
    },
    wordContainer() {
      return this.$refs["word-container"];
    },
    clearFlipTimeOut() {
      clearTimeout(this.flipTimeOut);
    },
    toggleFlip(setTo = null) {
      this.flipTimeOut = setTimeout(() => {
        this.flipped = setTo ?? 1 - this.flipped;
      }, this.wordViewDuration * 1000);
    },
  },
  computed: {
    ...mapGetters({
      word: "exercise/getWord",
      sentence: "exercise/getSentence",
      wordData: "exercise/getWordData",
      isLastWord: "exercise/isLastWord",
      progressStep: "exercise/getProgressStep",
      progressWord: "exercise/getProgressWord",
      audio: "audio/getAudioPlayer",
      studentExercise: "exercise/getCurrentStudentExercise",
      description: "template/getDescription",
      hasParameter: "template/hasParameter",
      templateParameters: "template/getCurrentTemplateParameters",
      isPlayingSentence: "audio/isPlayingSentence",
      isRestricted: "template/isRestricted",
      isTemplate: "template/isTemplate",
      getCurrentInput: "exercise/getCurrentInput",
      filteredSentence: "exercise/getFilteredSentence",
    }),
    staggered() {
      return this.hasParameter("staggered");
    },
    hasFadeOut() {
      return this.hasParameter("fadeout");
    },
    wordViewDuration() {
      return this.exercise?.word_view_duration ?? 2;
    },
    disabled() {
      if (this.isRestricted) {
        return this.currentInput !== this.word;
      }

      return (
        !this.animationDone &&
        (this.staggered || this.hasFadeOut) &&
        !this.flipped
      );
    },
    wordToShow() {
      if (this.isTemplate("LeesTyp")) {
        return this.word;
      }

      return this.animatedWordArray;
    },
    currentInput: {
      get() {
        return this.getCurrentInput?.word;
      },
      set(value) {
        this.setCurrentInput({ word: value });
      },
    },
  },
  watch: {
    progressStep() {
      this.startAnimation();
    },
    progressWord() {
      this.startAnimation();
    },
  },
};
</script>

<style scoped lang="scss">
.listening-container {
  height: 100%;
  padding: 0 10px 0 10px;
  text-align: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.animated-word-container {
  display: inline-flex;
  max-width: 0;
  overflow: hidden;
  transition: max-width 0.5s;
}

.letter {
  animation: fade-in 1s ease;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flipped-text {
  margin: 15px 0;
  font-family: "Quicksand", serif !important;
  line-height: 1.5em;
  font-weight: bold;
  font-size: 15px;
  color: white;
}
</style>
