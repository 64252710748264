<template>
  <div class="world_slide">
    <div class="world_slide_wrapper">
      <img :src="worldBackground()" class="world_slide_background">
      <!--    <div-->
      <!--        class="step-wrapper faded-overlay"-->
      <!--        v-if="(!isInitializing || unloading) && active && isUnlocked(number)"-->
      <!--    >-->
      <div
          v-for="(step, index) in steps"
          :id="'opdracht_' + step.position"
          :key="`position_${index}`"
          ref="position"
          :data-disabled="step.status === -1"
          :style="getStepPosition(worldNumber, step)"
          class="world_step"
      >
        <img
            @click="stepClick(step)"
            :src="iconSource(step)"
            :alt="step.status"
            :key="step.position"
            :class="{'world_step--current': isLastStep(index)}"
        />
        <!--        -->
        <!--        <span-->
        <!--            @click="setAnchorStep(step)"-->
        <!--            :class="{active: activeAnchorStep && activeAnchorStep.id === step.id, disabled: !hasAnchorSteps(step)}"-->
        <!--            class="anchor"-->
        <!--            v-if="shouldBeLoaded(step.position) && iconSource(step) && step.status !== -1"-->
        <!--        >-->
        <!--                  <img-->
        <!--                      src="../../assets/img/world/step-anchor.png"-->
        <!--                      alt="anchor"-->
        <!--                  />-->
        <!--                  </span>-->
        <!--      </div>-->
      </div>

      <div class="next-world-sign" :style="getNextWorldSignPosition(worldNumber)" @click="$emit('next')" v-if="worldNumber < 4">
        <img src="@/assets/go-back-arrow.png" alt="go-back"/>
      </div>

      <transition name="fade">
        <HelpOverlayButton v-if="!isInitializing && activeWorld === 1"/>
      </transition>

      <CWorldLegend/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CWorldLegend from "@/components/World/Legend";
import HelpOverlayButton from "@/components/Base/HelpOverlayButton";

export default {
  name: "CWorldSlide",
  components: {HelpOverlayButton, CWorldLegend},
  props: {
    worldNumber: {
      type: Number,
      required: true
    },
    steps: {
      type: Array,
      required: true
    }
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({
      setWorld: "world/SET_WORLD"
    }),
    worldBackground() {
      const map = {
        1: 'first',
        2: 'second',
        3: "third",
        4: "fourth"
      };

      return require(`../../assets/${map[this.worldNumber]}-world-bg.png`)
    },
    stepClick(step) {
      if (step.status === -1) return

      if (this.stepIsTest(step)) {
        return this.$router.push({
          name: "test",
          params: {stap_id: step.id, test_id: step.test_id},
        });
      }

      this.$router.push({
        name: "lesson_page",
        params: {id: 1, stap_id: step.id},
      });
    },
    isLastStep(index) {
      return this.currentStepIndex === index && !this.isUnlocked(this.number, true) && !this.isVisible
    },
    getNextWorldSignPosition(number) {
      return `left: ${this.getSignPositions(number)?.x}%; top: ${this.getSignPositions(number)?.y}%;`;
    },
  },
  computed: {
    ...mapGetters({
      activeWorld: "world/getActiveWorld",
      isActive: "world/isActive",
      isInitializing: "isInitializing",
      getSteps: "world/getSteps",
      iconSource: "world/getExerciseIconSource",
      stepIsTest: "world/stepIsTest",
      getStepPosition: "world/getStepPosition",
      getSignPositions: "world/getSignPositions",
    }),
  },
  watch: {
    activeWorld(newValue) {
      if (newValue === this.worldNumber) {

      }
    }
  }
}
</script>

<style scoped lang="scss">
.world_slide {
  position: absolute;
  height: calc(100vh - 80px);
  width: 100vw;
}

.world_slide_wrapper {
  aspect-ratio: 180 / 121;
  max-width: 100vw;
  max-height: calc(100vh - 80px);
  position: relative;
  margin: auto;
  overflow: hidden;
}

.world_slide_background {
  width: 100%;
  height: 100%;
}

.next-world-sign {
  position: absolute;
}

.next-world-sign img {
  transform: rotate(180deg);
}
</style>