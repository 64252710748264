<template>
  <v-btn
      class="green clear-button"
      tile
      @click="setCurrentInput(null)"
      :disabled="disabled"
  >
    <font-awesome-icon class="side-icon" icon="undo"/>
  </v-btn>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "CClearButton",
  props: ['disabled'],
  methods: {
    ...mapMutations({
      setCurrentInput: "exercise/SET_CURRENT_INPUT"
    }),
  },
};
</script>

<style lang="scss">
.clear-button {
  margin: 0 auto;
}
</style>

