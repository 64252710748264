import Step from "@/models/Step";
import Vue from "vue";

const state = {
  world: null,
  steps: [],
  exerciseIcons: {
    "-1": "exercise_closed-1.png",
    0: "exercise_open.png",
    1: "exercise_paused.png",
    3: "exercise_paused.png",
    4: "exercise-passed-{{difficulty}}.png",
    5: "exercise_passed.png",
    6: "exercise_paused.png",
    7: "exercise_test-6.png",
    repetition: "repetition-border.png",
  },
  worldPositions: {
    signs: {
      1: { x: 88, y: 49 },
      2: { x: 7, y: 78 },
      3: { x: 87, y: 75 },
      4: { x: 44, y: 79 },
    },
    1: [
      { x: 16.53, y: 15.7 },
      { x: 27.85, y: 19.11 },
      { x: 34.86, y: 30.99 },
      { x: 29.17, y: 47.11 },
      { x: 22.57, y: 64.46 },
      { x: 31.53, y: 82.23 },
      { x: 49.31, y: 82.75 },
      { x: 61.94, y: 64.88 },
      { x: 66.74, y: 45.56 },
      { x: 77.06, y: 40.69 },
    ],
    2: [
      { x: 7.64, y: 42.36 },
      { x: 21.65, y: 34.41 },
      { x: 27.45, y: 21.95 },
      { x: 36.75, y: 11.21 },
      { x: 44.32, y: 21.06 },
      { x: 59.03, y: 50.07 },
      { x: 71.53, y: 55.27 },
      { x: 81.25, y: 69.21 },
      { x: 78.47, y: 86.93 },
      { x: 65.63, y: 85.16 },
      { x: 52.08, y: 87.23 },
      { x: 40.63, y: 74.9 },
      { x: 33.33, y: 59.85 },
      { x: 20.14, y: 62.43 },
      { x: 9.03, y: 72.21 },
    ],
    3: [
      { x: 85.42, y: 18.08 },
      { x: 78.47, y: 19.11 },
      { x: 71.53, y: 26.34 },
      { x: 67.36, y: 34.61 },
      { x: 59.03, y: 41.32 },
      { x: 51.39, y: 39.22 },
      { x: 43.75, y: 30.99 },
      { x: 35.42, y: 27.86 },
      { x: 26.39, y: 30.44 },
      { x: 19.14, y: 42.29 },
      { x: 20.14, y: 55.2 },
      { x: 22.7, y: 66.05 },
      { x: 27.78, y: 75.86 },
      { x: 36.11, y: 80.51 },
      { x: 44.44, y: 81.55 },
      { x: 54.17, y: 78.45 },
      { x: 61.81, y: 69.15 },
      { x: 68.75, y: 59.85 },
      { x: 77.08, y: 57.27 },
      { x: 85.07, y: 64.5 },
    ],
    4: [
      { x: 5.9, y: 77.48 },
      { x: 6.6, y: 65.08 },
      { x: 5.9, y: 53.2 },
      { x: 8.33, y: 41.84 },
      { x: 11.11, y: 33.02 },
      { x: 17.06, y: 30.41 },
      { x: 22.92, y: 25.76 },
      { x: 22.92, y: 14.95 },
      { x: 21.53, y: 5.17 },
      { x: 29, y: 10.9 },
      { x: 36.11, y: 15.43 },
      { x: 43.4, y: 20.08 },
      { x: 57.64, y: 17.46 },
      { x: 65.63, y: 10.78 },
      { x: 81.25, y: 9.23 },
      { x: 89.51, y: 9.56 },
      { x: 94.4, y: 18.6 },
      { x: 90.58, y: 28.93 },
      { x: 85.03, y: 35.64 },
      { x: 77.39, y: 48.55 },
      { x: 74.31, y: 62.5 },
      { x: 77.08, y: 74.38 },
      { x: 71.53, y: 87 },
      { x: 60.42, y: 90 },
      { x: 50.0, y: 87 },
    ],
  },
};

const mutations = {
  SET_WORLD(state, payload) {
    state.world = payload ? parseInt(payload) : null;
  },
  SET_STEPS(state, payload) {
    state.steps = payload;
  },
};

const actions = {
  fetchSteps({ commit }) {
    commit("SET_STEPS", null);

    return Vue.prototype.$api.endpoints.step.get().then((data) => {
      commit("SET_STEPS", data);
      return data;
    });
  },
  nextWorld({ commit, state }) {
    commit("SET_WORLD", Math.min(state.world + 1, 4));
  },
  previousWorld({ commit, state }) {
    commit("SET_WORLD", Math.max(state.world - 1, 1));
  },
};

const getters = {
  getSteps2(state) {
    return state.steps;
  },
  getActiveWorld(state, getters, rootState, rootGetters) {
    return (
      state.world ??
      rootGetters["lessonPage/getStep"]?.phase_id ??
      getters.lastCollected
    );
  },
  getSteps:
    (state) =>
    (world = null) => {
      return state.steps && state.steps[world - 1]
        ? state.steps[world - 1].map((e) => new Step(e))
        : [];
    },
  isActive: (state) => (world) => state.world === world,
  /**
   * @param state
   * @param getters
   * @param rootState
   * @return {function(Step): *}
   */
  getExerciseIconSource: (state, getters, rootState) => (step) => {
    let status;
    if (rootState.helpOverlay.isVisible) {
      if (rootState.helpOverlay.step < 2) {
        if (step.position === 1) {
          status = 7;
        } else if (step.position > 1 && step.position < 6) {
          status = -1;
        }
      } else {
        if (step.position >= 1 && step.position <= 4) {
          status = 5;
        } else if (step.position === 5) {
          status = 4;
        } else if (step.position === 6) {
          status = 7;
        } else {
          status = -1;
        }
      }
    } else {
      if (step.status === 0 && step.is_repeatable) {
        status = "repetition";
      } else {
        status =
          step.can_start_test &&
          step.test_required &&
          !step.test_complete &&
          step.status !== -1
            ? 7
            : step.status;
      }
    }

    if (status === 4 && !step.highest_difficulty_completed) {
      status = 5;
    }
    let source = null;

    if (Number.isInteger(status) || status === "repetition") {
      source = state.exerciseIcons[status];
      source = source.replace(
        "{{difficulty}}",
        step.highest_difficulty_completed
      );
    }

    return Vue.prototype.$helper.getPng(source, "exercises/");
  },
  isCompleted: (state, getters, rootState, rootGetters) => (world_id) => {
    if (rootGetters["user/getStudent"]) {
      return (
        rootGetters["user/getStudent"][`end_reward_${world_id}_collected`] ??
        null
      );
    }

    return false;
  },
  isUnlocked:
    (state, getters, rootState, rootGetters) =>
    (world = null, onlyShowCompleted = false) => {
      const operators = {
        ">=": function (a, b) {
          return a >= b;
        },
        ">": function (a, b) {
          return a > b;
        },
      };
      const phaseId = rootGetters["user/getStudent"]?.phase_id;
      // noinspection JSIncompatibleTypesComparison
      if (phaseId === 4) return true;
      const worldVar = world ?? getters.getActiveWorld;

      const operator = onlyShowCompleted ? ">" : ">=";

      return operators[operator](phaseId, worldVar);
    },
  getPositions: (state, getters) => {
    return state.worldPositions[getters.getActiveWorld] ?? [];
  },
  getSignPositions:
    (state, getters) =>
    (worldNumber = null) => {
      return (
        state.worldPositions["signs"][worldNumber ?? getters.getActiveWorld] ??
        []
      );
    },
  lastCollected: (state, getters, rootState, rootGetters) => {
    return rootGetters["user/getStudent"]?.phase_id;
  },
  stepIsTest: () => (step) => {
    if (
      step.test_required === true &&
      step.can_start_test === true &&
      step.test_complete !== true &&
      step.status !== -1
    ) {
      return true;
    } else if (
      (step.test_complete === true || step.test_required === false) &&
      step.status !== -1
    ) {
      return false;
    }
  },
  getStepPosition: (state) => (world, step) => {
    const position = state.worldPositions[world][step.position - 1];

    return `left: ${position?.x}%; top: ${position?.y}%;`;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
