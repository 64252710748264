<template>
  <img alt="liana" :src="require('../assets/logboek-liaan-verticaal.png')" class="liaan-verticaal"/>
</template>

<script>
export default {
  name: 'c-liana'
}
</script>

<style scoped lang="scss">
.liaan-verticaal {
  background: url('../assets/logboek-liaan-verticaal.png') no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 10px;
  z-index: 0;
  height: 100%;
}
</style>