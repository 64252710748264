<template>
  <div class="unrestricted-input">
    <v-text-field
        spellcheck="false"
        ref="typing-input"
        class="centered typing-input"
        rounded
        white
        hide-details="true"
        v-model="input"
        height="100%"
        @input="handleInput"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CInput",
  props: ['value'],
  data: () => ({
    input: this?.value,
  }),
  methods: {
    handleInput() {
      this.$emit("input", this.input)
    },
  },
  mounted() {
    document.querySelectorAll('input').forEach((input) => {
      input.setAttribute('spellcheck', 'false');
    })
  },
  computed: {
    ...mapGetters({
      progressWord: "exercise/getProgressWord",
      progressStep: "exercise/getProgressStep"
    })
  },
  watch: {
    value(value) {
      this.input = value;
    },
    progressStep(newValue) {
      this.input = "";
      this.$emit("input", "")
      if (newValue) {
        this.$refs["typing-input"].focus({
          preventScroll: true
        });
      }
    },
    progressWord() {
      this.input = "";
      this.$emit("input", "")
    }
  }
};
</script>

<style scoped lang="scss">
.v-input {
  border: 2px solid #3bad2c;
  box-shadow: 5px 5px 2px -3px rgba(0, 0, 0, 0.25);
  margin: 0;
  background: white;
  flex-basis: 100%;
  height: 100%;
}
//
//input {
//  //max-height: 47px !important;
//}
//
//
.wrong-style {
  animation: shake 0.35s ease-in-out 0s 1;
}

.typing-input {
  ::v-deep input {
    text-align: center;
  }
}
</style>