import { Model } from "@/models/Model";

export class Reward extends Model {
  constructor({
    activity_type,
    activity_type_label,
    condition,
    cost,
    description,
    id,
    image,
    image_url,
    image_url_large,
    is_active,
    is_buyable,
    name,
    student_has_badge,
  }) {
    super();
    this._activity_type = activity_type;
    this._activity_type_label = activity_type_label;
    this._condition = condition;
    this._cost = cost;
    this._description = description;
    this._id = id;
    this._image = image;
    this._image_url = image_url;
    this._image_url_large = image_url_large;
    this._is_active = is_active;
    this._is_buyable = is_buyable;
    this._name = name;
    this._student_has_badge = student_has_badge;
  }

  get activity_type() {
    return this._activity_type;
  }

  get activity_type_label() {
    return this._activity_type_label;
  }

  get condition() {
    return this._condition;
  }

  get cost() {
    return this._cost;
  }

  get description() {
    return this._description;
  }

  get id() {
    return this._id;
  }

  get image() {
    return this._image;
  }

  get image_url() {
    return this._image_url;
  }

  get image_url_large() {
    return this._image_url_large;
  }

  get is_active() {
    return this._is_active;
  }

  get is_buyable() {
    return this._is_buyable;
  }

  get name() {
    return this._name;
  }

  get student_has_badge() {
    return this._student_has_badge;
  }
}
