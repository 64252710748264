<template>
  <div class="back-btn">
    <span class="go-back">
      <v-btn
          @click="goBack($event)"
          depressed
          fab
      >
        <img src="../assets/go-back-arrow.png" alt="go-back"/>
      </v-btn>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    expectEvent: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    goBack(event) {
      if (this.expectEvent) {
        return this.$emit('click', event);
      }
      return this.$router.go(-1);
    }
  }
}
</script>

<style scoped lang="scss">
.back-btn {
  position: absolute;
  margin-left: 20px;
  z-index: 97;
}

img:hover {
  animation: bounce .5s infinite; //other browsers
  -webkit-animation: bounce .5s infinite; //webkit browsers
  -moz-animation: bounce .5s infinite; //firefox
  -o-animation: bounce .5s infinite; //opera
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-5px);
  }
}

@-moz-keyframes bounce {
  0%,
  100% {
    -moz-transform: translateY(0);
  }
  50% {
    -moz-transform: translateY(-5px);
  }
}

@-o-keyframes bounce {
  0%,
  100% {
    -o-transform: translateY(0);
  }
  50% {
    -o-transform: translateY(-5px);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.theme--light.v-btn.v-btn--has-bg {
  background: transparent !important;
}
</style>