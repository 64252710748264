import CTestIntro from "@/components/Exercise/Intro/Test";
import CExerciseIntro from "@/components/Exercise/Intro/Exercise";
import CTwoWordStoryIntro from "@/components/Exercise/Intro/TwoWordStory";

import CListening from "@/components/Exercise/Content/Listening";
import CTest from "@/components/Exercise/Content/Test";
import CTyping from "@/components/Exercise/Content/Typing";
import CSentence from "@/components/Exercise/Content/Sentence";
import CAssignColor from "@/components/Exercise/Content/AssignColor";
import CHiddenTyping from "@/components/Exercise/Content/HiddenTyping";
import CBlockTyping from "@/components/Exercise/Content/BlockTyping";
import CCutWords from "@/components/Exercise/Content/CutWords";
import CAnchorImages from "@/components/Exercise/Content/AnchorImages";
import CStory from "@/components/Exercise/Content/Story";
import CStoryList from "@/components/Exercise/Outro/StoryList";
import CDownload from "@/components/Exercise/Content/Download";
import CTwoWordStory from "@/components/Exercise/Content/TwoWordStory";

import CWordList from "@/components/Exercise/Outro/WordList";
import CResultList from "@/components/Exercise/Outro/ResultList";
import CTestOutro from "@/components/Exercise/Outro/Test";

// TODO move templates parameters to array, instead of loose keys

const state = {
  parameters: {
    LeesLetters: {
      intro: CExerciseIntro,
      content: CListening,
      outro: CWordList,
      description: "Lees de klanken hardop en dán het hele woord.",
      parameters: ["staggered"],
      autoplay: [],
    },

    KijkLuister: {
      intro: CExerciseIntro,
      content: CListening,
      outro: CWordList,
      description: "Luister en lees de woorden.",
      autoplay: ["sentence", "word"],
    },

    KijkLees: {
      intro: CExerciseIntro,
      content: CListening,
      outro: CWordList,
      description: "Lees de woorden hardop.",
      autoplay: [],
    },

    LeesTyp: {
      intro: CExerciseIntro,
      content: CListening,
      outro: CWordList,
      description: "Typ en praat mee.",
      autoplay: ['word'],
      restricted: true,
      parameters: ["sentence", "fadeout"],
    },

    KiesKlank: {
      intro: CExerciseIntro,
      content: CTyping,
      outro: CResultList,
      description: "Welke klank hoort op de puntjes?",
      autoplay: ["word"],
      restricted: true,
    },
    Klankgroepen: {
      intro: CExerciseIntro,
      content: CCutWords,
      outro: CResultList,
      description: "Zaag de klankgroepen en praat hardop mee.",
      autoplay: ["word"],
    },
    SorteerLeer: {
      intro: CExerciseIntro,
      content: CAnchorImages,
      outro: CResultList,
      description: "Klik op het juiste plaatje. Typ dan het woord.",
      autoplay: ["word"],
    },

    // Assign Color

    Blokken: {
      intro: CExerciseIntro,
      content: CAssignColor,
      outro: CResultList,
      description: "Blok en typ elke klank. Praat hardop mee.",
      autoplay: ["sentence", "word"],
      parameters: ["restrictedInput", "sentence"],
    },
    SpeurKleur1: {
      intro: CExerciseIntro,
      content: CAssignColor,
      outro: CResultList,
      description: "Kleur de klanken en praat mee.",
      autoplay: ["word"],
      parameters: ["sentence"],
    },
    SpeurKleur2: {
      intro: CExerciseIntro,
      content: CAssignColor,
      outro: CResultList,
      description: "Kleur de laatste klank van elke klankgroep.",
      autoplay: ["word"],
    },

    LetterBlokken: {
      intro: CExerciseIntro,
      content: CBlockTyping,
      outro: CResultList,
      description: "Eén blok voor elke klank. Typ en praat mee.",
      autoplay: ["sentence", "word"],
      parameters: ["sentence"],
    },
    TypKlankgroepen1: {
      intro: CExerciseIntro,
      content: CBlockTyping,
      outro: CResultList,
      description: "Typ en praat mee in klankgroepen.",
      autoplay: ["word"],
      parameters: ["sentence"],
    },
    TypKlankgroepen2: {
      intro: CExerciseIntro,
      content: CBlockTyping,
      outro: CResultList,
      description: "Typ en praat mee in klankgroepen.",
      autoplay: ["word"],
      parameters: ["sentence"],
    },
    LuisterTyp: {
      intro: CExerciseIntro,
      content: CSentence,
      outro: CResultList,
      description: "",
      restricted: true,
      autoplay: ["word"],
      parameters: ["sentence"],
    },
    TypHints: {
      intro: CExerciseIntro,
      content: CTyping,
      outro: CResultList,
      description: "Typ en praat mee in klankgroepen.",
      leaf: true,
      autoplay: ["word"],
      show: false,
      restricted: true,
      parameters: ["sentence"],
      inputHints: true,
    },
    Zinnen1: {
      intro: CExerciseIntro,
      content: CStory,
      outro: CStoryList,
      description: "Typ een mooie zin met het woord. Spieken mag.",
      autoplay: ["word"],
      hidden: true,
      currentInputKey: "sentence",
    },
    Zinnen2: {
      intro: CExerciseIntro,
      content: CStory,
      outro: CStoryList,
      description: "Typ een mooie zin met het woord.",
      autoplay: ["word"],
      currentInputKey: "sentence",
    },
    Verhaal: {
      intro: CTwoWordStoryIntro,
      content: CTwoWordStory,
      outro: CStoryList,
      description: "Typ een mooi, grappig of interessant verhaal.",
      autoplay: [],
      currentInputKey: "story",
      speakers: false,
    },

    // TESTS

    Test: {
      intro: CTestIntro,
      content: CTest,
      outro: CTestOutro,
      autoplay: ["sentence", "word"],
      replaceWithPlaying: true,
      hint: false,
    },
    OefenDictee: {
      intro: CExerciseIntro,
      content: CHiddenTyping,
      outro: CResultList,
      description: "",
      autoplay: ["sentence", "word"],
      parameters: ["sentence"],
    },
    StapDictee: {
      intro: CExerciseIntro,
      content: CSentence,
      outro: CResultList,
      description: "",
      autoplay: ["sentence", "word"],
      parameters: ["sentence"],
    },

    Werkboek: {
      intro: CExerciseIntro,
      content: CDownload,
      outro: CWordList,
      description:
        "Klik op download en print de opdrachten.\n" +
        "Opdrachten af? Klik dan op ‘Ik ben klaar’",
      hint: false,
      speakers: false,
    },
  },
};

const actions = {};

const mutations = {};

// TODO Move parameters to own object
// TODO combine the has/is getters

const getters = {
  getCurrentTemplate(state, getters, rootState, rootGetters) {
    if (rootGetters["router/isTest"]) return "Test";
    return rootGetters["exercise/getExercise"]?.exercise_type?.template;
  },
  getCurrentTemplateParameters(state, getters) {
    return state.parameters[getters.getCurrentTemplate];
  },
  getIntro(state, getters) {
    return getters.getCurrentTemplateParameters?.intro;
  },
  getContent(state, getters) {
    return getters.getCurrentTemplateParameters?.content;
  },
  getName(state, getters) {
    return getters.getCurrentTemplateParameters;
  },
  getOutro(state, getters) {
    return getters.getCurrentTemplateParameters?.outro;
  },
  getDescription(state, getters) {
    return getters.getCurrentTemplateParameters?.description;
  },
  isTemplate: (state, getters) => (template) => {
    return getters.getCurrentTemplate === template;
  },
  hasHidden: (state, getters) => {
    return getters.getCurrentTemplateParameters?.hidden;
  },
  hasLeaf: (state, getters) => {
    return getters.getCurrentTemplateParameters?.leaf;
  },
  isRestricted: (state, getters) => {
    return getters.getCurrentTemplateParameters?.restricted;
  },
  shouldShow: (state, getters) => {
    const templateParameters = getters.getCurrentTemplateParameters;
    return typeof templateParameters?.show !== "undefined"
      ? templateParameters?.show
      : true;
  },
  hasRestricted: (state, getters) => {
    return getters.getCurrentTemplateParameters?.restricted;
  },
  hasHint: (state, getters) => {
    const templateParameters = getters.getCurrentTemplateParameters;
    return typeof templateParameters?.hint !== "undefined"
      ? templateParameters?.hint
      : true;
  },
  hasSpeakers: (state, getters) => {
    const templateParameters = getters.getCurrentTemplateParameters;
    return typeof templateParameters?.speakers !== "undefined"
      ? templateParameters?.speakers
      : true;
  },
  hasInputHints: (state, getters) => {
    const templateParameters = getters.getCurrentTemplateParameters;
    return templateParameters?.inputHints ?? null;
  },
  currentInputKey: (state, getters) => {
    const templateParameters = getters.getCurrentTemplateParameters;
    return templateParameters?.currentInputKey ?? null;
  },
  hasParameter: (state, getters) => (name) => {
    const parameters =
      state.parameters[getters.getCurrentTemplate].parameters ?? null;
    return parameters && parameters.includes(name);
  },
};

export default {
  actions,
  mutations,
  getters,
  state,
  namespaced: true,
};
