import { Model } from "@/models/Model";

export default class Word extends Model {
  constructor({
    id,
    word,
    phase_id,
    position,
    word_type_id,
    structure,
    sound_groups,
    anchor_id,
    sentence,
    sound,
    is_klankzuiver,
    niet_klankzuiver_deel,
    is_regelwoord,
    is_composite,
    homophones,
    consonant_cluster,
    frequency,
    sticky_letters,
    plural_form,
    false_letters,
    prefix,
    suffix,
    borrowed_sounds,
    is_english_loanword,
    view_duration,
    audio_plural,
    audio_plural_is_custom,
    audio_word,
    audio_word_is_custom,
    audio_structure,
    audio_structure_is_custom,
    audio_sound_groups,
    audio_sound_groups_is_custom,
    audio_sentence,
    audio_sentence_is_custom,
    level,
    dle,
    difficulty,
    is_required,
    is_active,
    created_at,
    updated_at,
    structure_audio_parts,
    wordType,
    read_letters,
  }) {
    super();
    this._id = id;
    this._word = word;
    this._phase_id = phase_id;
    this._position = position;
    this._word_type_id = word_type_id;
    this._structure = structure;
    this._sound_groups = sound_groups;
    this._anchor_id = anchor_id;
    this._sentence = sentence;
    this._sound = sound;
    this._is_klankzuiver = is_klankzuiver;
    this._niet_klankzuiver_deel = niet_klankzuiver_deel;
    this._is_regelwoord = is_regelwoord;
    this._is_composite = is_composite;
    this._homophones = homophones;
    this._consonant_cluster = consonant_cluster;
    this._frequency = frequency;
    this._sticky_letters = sticky_letters;
    this._plural_form = plural_form;
    this._false_letters = false_letters;
    this._prefix = prefix;
    this._suffix = suffix;
    this._borrowed_sounds = borrowed_sounds;
    this._is_english_loanword = is_english_loanword;
    this._view_duration = view_duration;
    this._audio_word = audio_word;
    this._audio_word_is_custom = audio_word_is_custom;
    this._audio_structure = audio_structure;
    this._audio_structure_is_custom = audio_structure_is_custom;
    this._audio_sound_groups = audio_sound_groups;
    this._audio_sound_groups_is_custom = audio_sound_groups_is_custom;
    this._audio_plural = audio_plural;
    this._audio_plural_is_custom = audio_plural_is_custom;
    this._audio_sentence = audio_sentence;
    this._audio_sentence_is_custom = audio_sentence_is_custom;
    this._level = level;
    this._dle = dle;
    this._difficulty = difficulty;
    this._is_required = is_required;
    this._is_active = is_active;
    this._created_at = created_at;
    this._updated_at = updated_at;
    this._structure_audio_parts = structure_audio_parts;
    this._wordType = wordType;
    this._read_letters = read_letters;
  }

  get id() {
    return this._id;
  }

  get word() {
    return this._word;
  }

  get phase_id() {
    return this._phase_id;
  }

  get position() {
    return this._position;
  }

  get word_type_id() {
    return this._word_type_id;
  }

  get structure() {
    return this._structure;
  }

  get sound_groups() {
    return this._sound_groups;
  }

  get anchor_id() {
    return this._anchor_id;
  }

  get sentence() {
    return this._sentence;
  }

  get story() {
    return this._story;
  }

  get sound() {
    return this._sound;
  }

  get is_klankzuiver() {
    return this._is_klankzuiver;
  }

  get niet_klankzuiver_deel() {
    return this._niet_klankzuiver_deel;
  }

  get is_regelwoord() {
    return this._is_regelwoord;
  }

  get is_composite() {
    return this._is_composite;
  }

  get homophones() {
    return this._homophones;
  }

  get consonant_cluster() {
    return this._consonant_cluster;
  }

  get frequency() {
    return this._frequency;
  }

  get sticky_letters() {
    return this._sticky_letters;
  }

  get plural_form() {
    return this._plural_form;
  }

  get false_letters() {
    return this._false_letters;
  }

  get prefix() {
    return this._prefix;
  }

  get suffix() {
    return this._suffix;
  }

  get borrowed_sounds() {
    return this._borrowed_sounds;
  }

  get is_english_loanword() {
    return this._is_english_loanword;
  }

  get view_duration() {
    return this._view_duration;
  }

  get audio_word() {
    return this._audio_word;
  }

  get audio_word_is_custom() {
    return this._audio_word_is_custom;
  }

  get audio_structure() {
    return this._audio_structure;
  }

  get audio_structure_is_custom() {
    return this._audio_structure_is_custom;
  }

  get audio_sound_groups() {
    return this._audio_sound_groups;
  }

  get audio_sound_groups_is_custom() {
    return this._audio_sound_groups_is_custom;
  }

  get audio_plural() {
    return this._audio_plural;
  }

  get audio_plural_is_custom() {
    return this._audio_plural_is_custom;
  }

  get audio_sentence() {
    return this._audio_sentence;
  }

  get audio_sentence_is_custom() {
    return this._audio_sentence_is_custom;
  }

  get level() {
    return this._level;
  }

  get dle() {
    return this._dle;
  }

  get difficulty() {
    return this._difficulty;
  }

  get is_required() {
    return this._is_required;
  }

  get is_active() {
    return this._is_active;
  }

  get created_at() {
    return this._created_at;
  }

  get updated_at() {
    return this._updated_at;
  }

  get structure_audio_parts() {
    return this._structure_audio_parts;
  }

  get wordType() {
    return this._wordType;
  }

  get read_letters() {
    return this._read_letters;
  }
}

class WordType extends Model {
  constructor({ id, name, created_at, updated_at }) {
    super();
    this._id = id;
    this._name = name;
    this._created_at = created_at;
    this._updated_at = updated_at;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get created_at() {
    return this._created_at;
  }

  get updated_at() {
    return this._updated_at;
  }
}
