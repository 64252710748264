<template>
  <div class="jungle-content">
      <c-speech-bubble v-if="!test.is_complete" class="test-description-container">
        <p class="test-description" v-html=introText></p>
        <v-btn class="green test-button" @click="nextStep" tile>Start!</v-btn>
      </c-speech-bubble>


    <c-speech-bubble v-if="test.is_complete" class="test-description-container">
      <p>Deze toets is al afgerond.</p>
      <v-btn
          class="green test-button"
          @click="$router.push(linkToWorld)"
          tile
      >
        Ga naar oefeningen!
      </v-btn>
    </c-speech-bubble>

    <div class="test-intro-buddy-container">
      <img
          :src="createBuddyImage('map')"
          class="treehouse-buddy"
          alt="buddy"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CSpeechBubble from "@/components/Base/SpeechBubble";

export default {
  name: 'c-test-intro',
  components: {CSpeechBubble},
  methods: {
    ...mapActions({
      nextStep: "exercise/nextStep",
    })
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      test: "exercise/getTest",
      profile: "user/getProfile",
      screenName: "user/getScreenName",
      createBuddyImage: "user/createBuddyImage",
      linkToWorld: "router/linkToWorld",
    }),
    description() {
      return this.exercise?.description ?? this.test?.label;
    },
    introText() {
      if(this.test.current_step === 1) {
        if (this.test.phase_id === 1) {
          return `Hoi ${this.screenName}.<br><br>
                Jouw avontuur door de Spell-it jungle begint nu.<br><br>
                Succes met de stappentoets!`
        }

        return `Hoi ${this.screenName},<br><br>
              Jouw avontuur door dit level begint hier!<br>
              Na de toets weet je welke stappen je kan gaan doen.<br><br>
              Heel veel succes!`
      }

      return `Hoi ${this.screenName},<br><br>
              Je begint met nieuwe stappen in jouw jungle-avontuur.<br>
              Na de toets weet je welke stappen je kan gaan doen.<br><br>
              Heel veel succes!`
    },
  }
}
</script>

<style scoped lang="scss">
.test-description-container {
  position: relative;
  width: 50%;
  height: 85%;
  margin: auto;
}

.test-intro-buddy-container {
  bottom: 0;
  right: 0;
  width: 35%;
  height: 60%;
  position: absolute;
  pointer-events: none;

  img {
    height: 100%;
  }
}
</style>