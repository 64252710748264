<template>
  <div class="exercise-intro">
    <h3 class="exercise-description">{{ description }}</h3>

    <div class="exercise-word-list">
      <p>Kies 2 woorden uit de lijst hieronder!</p>
      <div class="intro-selectable-words">
        <p
            v-for="(word, id) in getWords"
            :key="id"
            @click="pickStoryWord(word)"
            :class="{selected: getTwoWordStoryWords.includes(word)}"
        >
          {{ word }}
        </p>
      </div>
    </div>

    <v-btn class="green exercise-button" @click="nextStep" :disabled="getTwoWordStoryWords.length < 2" tile>Begin!</v-btn>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'CTwoWordStory',
  data: () => ({
    chosenWords: []
  }),
  methods: {
    ...mapActions({
      nextStep: "exercise/nextStep",
      pickStoryWord: "exercise/pickStoryWord",
      resetStoryWords: "exercise/resetStoryWords",
    }),
  },
  computed: {
    ...mapGetters({
      exercise: "exercise/getExercise",
      test: "exercise/getTest",
      isTest: "router/isTest",
      profile: "user/getProfile",
      getIntro: "template/getIntro",
      getWords: "exercise/getWords",
      getTwoWordStoryWords: "exercise/getTwoWordStoryWords",
      step: "exercise/getProgressStep",
    }),
    description() {
      if (this.isTest) {
        return this.test?.label
      }

      return this.exercise?.description;
    }
  },
  watch: {
    step(e) {
      if (e === 1 || e === 3) {
        this.resetStoryWords()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.exercise-intro {
  gap: 5%;
  height: 100%;
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
}

.exercise-word-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
}

.exercise-description {
  top: 20%;
  padding: 0 200px;
  text-align: center;
}

h3 {
  font-size: 30px;
  color: #016d47;
}


</style>