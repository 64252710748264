<template>
    <div>
      <div v-if="true" style="display: none;">
        <!-- Als je dit verwijderd, dan werkt de backspace niet meer. Waarom weet ik niet, kost te veel tijd om uit te zoeken. -->
        {{ typed }}
      </div>
        <div v-if="false">
            [value: {{ value }}]
            [key: {{ debugValue }}]
            [typed: {{ typed }}]
            {{ input }}
            [index: {{ debugIndex }}]
            [{{ typed[0] }}]
            [{{ typed[1] }}]
            [{{ typed[2] }}]
            [{{ typed[3] }}]
            [{{ typed[4] }}]
            [{{ typed[5] }}]
            [{{ typed[6] }}]
            [{{ typed[7] }}]
            [{{ typed[8] }}]
        </div>
        <div class="block-container">

            <div
                class="block-wrapper"
                v-for="(letter, index) in value"
                :key="index"
            >
                <input
                    maxlength="1"
                    autocomplete="off"
                    type="text"
                    :data-length="letter.length"
                    :data-index="index"
                    :tabindex="index"
                    :ref="`input-${index}`"
                    v-if="letter !== ','"
                    class="block-input"
                    v-model="input[index]"
                    @keydown.backspace="goBackspace($event)"
                    style="font-family: 'Quicksand', serif !important;"
                />
                <span
                    :ref="`input-${index}`"
                    :data-index="index"
                    class="block-dash"
                    v-if="letter === ','"
                >-</span
                >
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "CBlockInput",
    props: ['value'], //ki,wi,'
    data: () => ({
        soundGroupDash: "",
        typed: "",
        newWord: "",
        debugValue: "",
        debugIndex: null,
        input: [],
        lastInput: [],
    }),
    created() {
        this.setDash();
    },
    methods: {
        ...mapActions({
            wrongInput: 'exercise/wrongInput',
            postData: 'exercise/postData',
        }),
        focusInputByIndex(index) {
            const input = this.$refs[`input-${index}`];
            if (input?.length) {
                input[0].focus()
            }
        },
        /**
         * @param {KeyboardEvent} event
         * @return {boolean}
         */
        handleActivationInput(value, index) {
            //console.log('handleActivationInput');
            //let value = event.key;
            //console.log(value);
            this.debugValue = value;
            //let index = parseInt(event.target.dataset.index);
            this.debugIndex = index;
            let maxlength = 1; //event.target.dataset.length;
            let wordWithoutCommas = this.typed.replace(",", "");

            // if (!/[a-zA-Z\-'áàâäãéèëêíìïîóòöôõúùüûñçăşţÁÀÂÄÃÉÈËÊÍÌÏÎÓÒÖÔÕÚÙÜÛÑÇĂŞŢ]/.test(event.key) || event.key.length > 1 || value === 'Dead') {
            //     return false;
            // }
            //console.log('hier');
            if (wordWithoutCommas.length === this.value.length) {
                //console.log('I');
                //event.preventDefault();
                return false;
            }

            if (this.typed.charAt(index)) {
                //console.log('A');
                index = this.typed.length;
                this.focusInputByIndex(index);
            }

            if (this.value[index + 1] === ",") {
                //console.log('B');
                this.$refs[`input-${parseInt(index + 2)}`][0].focus();
            }

            if (this.value[index - 1] === "," && this.typed[index - 2] !== "," && this.typed[index - 1] !== ",") {
                //console.log('C');
                this.typed += ",";
            }

            if (value && value.length >= maxlength) {
                //console.log('D');
                if (typeof this.value[index] === "undefined" && wordWithoutCommas.length !== this.value.length - 1) {
                    //console.log('D');
                    //event.preventDefault();
                    return false;
                }

                const currentInput = this.$refs[`input-${parseInt(index)}`]?.[0];
                const nextInput = this.$refs[`input-${parseInt(this.typed.length + 1)}`]?.[0];
                /** @type {HTMLElement} */
                const nextButton = document.querySelector('.next-button');

                if (this.value.charAt(index).toLowerCase() !== value.toLowerCase()) {
                    //console.log('E');
                    //event.preventDefault();

                    this.wrongInput(currentInput)

                    this.postData({currentInput: {word: this.typed + value}});
                    this.focusInputByIndex(index);
                    return false;
                }

                if (currentInput) {
                    //console.log('F');
                    currentInput.value = value;
                    this.typed += value;

                    if (this.value[index + 1] === ",") {
                        this.typed += ",";
                    }
                }

                if (nextInput) {
                    //console.log('G');
                    nextInput.focus();
                } else {
                    //console.log('H');
                    nextButton.focus()
                }
            }
            return true;

            //event.preventDefault();
        },
        goBackspace(event) {
            event.preventDefault();
            let index = parseInt(event.target.dataset.index);
            this.typed = this.typed.slice(0, -1);

            if (
                this.typed.length === index &&
                this.typed.length + 1 !== this.value.length &&
                this.typed.charAt(this.typed.length - 1) === ","
            ) {
                this.typed = this.typed.slice(0, -1);
            }

            if (this.typed.length > 0) {
                if (this.value[index - 1] === ",") {
                    this.typed = this.typed.slice(0, -1);
                    this.$refs[`input-${parseInt(index - 2)}`]?.[0].focus();
                } else {
                    this.$refs[`input-${parseInt(this.typed.length)}`]?.[0].focus();
                }
            } else {
                this.$refs[`input-${0}`][0].focus();
            }

            for (let i = 0; i < this.value.length; i++) {
                //console.log('this.typed.length', this.typed.length);
                //console.log('this.typed.length', this.typed.length);
                if (this.typed.length > i) {
                    this.input[i] = this.typed[i];
                } else {
                    this.input[i] = "";
                }
            }
            this.lastInput = [...this.input];

            return false;
        },
        getAllIndexes(arr, val) {
            let indexes = [];
            let i = -1;
            while ((i = arr.indexOf(val, i + 1)) !== -1) {
                indexes.push(i);
            }
            return indexes;
        },
        setDash() {
            if (this.exerciseData !== null) {
                this.soundGroupDash = this.getAllIndexes(this.word, ",");
            }
        },
        selected(woord, option) {
            this.selectedIndex = [woord.indexOf(option)];
        },
        resetInput() {
            console.log('resetInput');
            this.typed = ""
            this.focusInputByIndex(0)
            this.setInput();
        },
        setInput() {
            this.input = [];
            for (let i in this.value) {
                this.input.push('');
            }
            this.lastInput = [...this.input];
        },
    },
    computed: {
        ...mapGetters({
            exerciseData: "exercise/getExerciseData",
            word: "exercise/getWord",
            progressStep: "exercise/getProgressStep",
            progressWord: "exercise/getProgressWord",
            currentInput: "exercise/getCurrentInput"
        }),
    },
    watch: {
        typed() {
            this.$emit("input", this.typed)
        },
        progressStep() {
            this.typed = "";
            this.$emit("input", this.typed)
            this.focusInputByIndex(0)
            setTimeout(() => this.focusInputByIndex(0), 100)
        },
        progressWord() {
            this.typed = "";
            this.$emit("input", this.typed)
            this.focusInputByIndex(0)
            setTimeout(() => this.focusInputByIndex(0), 100)
        },
        currentInput(newValue) {
            if (newValue === "") {
                this.resetInput()
            }
        },
        input: {
            handler: function (newVal, oldVal) {
                // console.log('newVal', newVal);
                // console.log('oldVal', oldVal);
                // console.log('input', this.input);
                // console.log('lastInput', this.lastInput);

                let changedIndex = null;
                this.input.forEach((i, index) => {
                    if (this.lastInput[index] !== i) {
                        changedIndex = index;
                    }
                });
                // console.log('changedIndex', changedIndex);
                if (changedIndex === null) return;
                setTimeout(() => {
                    let validInput = this.handleActivationInput(this.input[changedIndex], changedIndex);
                    // console.log('validInput', validInput);

                    if (validInput) {
                        this.lastInput = [...this.input];
                    } else {
                        this.input = [...this.lastInput];
                    }
                }, 0);
            },
            immediate: false,
            deep: true
        },
        value: {
            handler: function () {
                this.setInput();
                // this.input = [];
                // for (let i in newVal) {
                //     console.log(newVal[i]);
                //     this.input.push('');
                // }
                // this.lastInput = [...this.input];
            },
            immediate: true,
            deep: true
        },
    }
};
</script>

<style lang="scss">
.block-input {
  height: 50px;
  max-width: 50px;
  text-align: center;
  font-size: 30px;
  background: white;
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1) !important;
  margin: 0 0.1rem;
}

.dash {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
}

.block-dash {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  margin: -10px 5px;
  height: 60px;
}

.block-container {
  display: flex;
  justify-content: center;
}
</style>

